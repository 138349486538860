import React from 'react';

import Grid from "@material-ui/core/Grid";
import {Button, DialogActions} from "@material-ui/core";

import FormInput from "./FormInput";
import ExPanel from "./ExPanel";
import ErrorMessage from "./ErrorMessage";
import Progress from "./Progress";
import Alert from "./Alert";
import useApiRequest from "../hooks/useApiRequest";
import {userFormFieldList, userFormFields} from "../utils/constants";
import useApiRequestExecuted from "../hooks/useApiRequestExecuted";
import {useFormik} from "formik";
import {autoFill, fieldsInitialState} from "../utils/format";
import {getValidationSchema} from "../utils/validators";
import {isDisabled} from "../store/actions/aws";

const formFields = {
    "merchantId": {
        label: 'Merchant ID',
        required: true,
        autoFill: 'merchant_1',
        initValue: ''
    },
    ...userFormFields
};
const fieldList = [
    "merchantId",
    ...userFormFieldList
];
const requestType = 'create-user';

export default function CreateUserPanel({submitRequest}) {
    const [requestId, requestInProgress, requestError, newRequest] = useApiRequest();
    const userCreated = useApiRequestExecuted('createUserRequestId', requestId);

    const handleRequest = (values) => {
        submitRequest(requestType, values, newRequest());
    }

    const formik = useFormik({
        initialValues: fieldsInitialState(formFields),
        validationSchema: getValidationSchema(formFields),
        onSubmit: handleRequest
    });

    const handleOnChange = (e) => {
        let val = e.target.value;
        let name = e.target.name;
        e.target.value = autoFill(name, val, formFields);
        formik.handleChange(e)
    }

    const UserCreated = () => {
        if (userCreated) {
            return <Alert severity="success">User created</Alert>
        }
        return null;
    }

    return (
        <ExPanel title="Create New User" hidden={isDisabled(requestType)}>
            <Grid item xs={12}>
                <form>
                    {fieldList.map((name) => (
                        <FormInput
                            key={name}
                            name={name}
                            formFields={formFields}
                            error={formik.touched[name] && formik.errors[name]}
                            onBlur={formik.handleBlur}
                            getEditValue={name => formik.values[name]}
                            onChange={handleOnChange}
                        />
                    ))}
                </form>
                <ErrorMessage errorMessage={requestError}/>
                <Progress show={requestInProgress} requestId={requestId}/>
                <UserCreated/>
                <DialogActions>
                    <Button disabled={requestInProgress} variant="contained" color="primary" onClick={formik.handleSubmit}>Submit</Button>
                </DialogActions>
            </Grid>
        </ExPanel>
    )
}
