import {useState} from "react";
import {v4 as uuid} from "uuid";

export default function useLoanPayouts() {
    const [payouts, setPayouts] = useState([])
    const [undo, setUndo] = useState([])

    const removePayout = (payout) => payouts.filter((item) => item.id !== payout.id)
    const putUndo = () => {
        setUndo([...undo, JSON.stringify(payouts)])
    }
    const getUndo = () => {
        if (undo.length > 0) {
            const items = JSON.parse(undo.slice(-1))
            setUndo(undo.slice(0, -1))
            return items
        }
        return []
    }

    const handlePayoutRemove = (payout) => {
        putUndo()
        setPayouts(removePayout(payout))
    }

    const updateDate = (payout) => {
        if (payout.dueDate && payout.dueDate.toISOString) {
            payout.dueDate = payout.dueDate.toISOString().split('T')[0]
        }
    }

    const handlePayoutAdd = (payout) => {
        putUndo()
        if (!payout.id) {
            payout.id = uuid()
        }
        updateDate(payout)
        setPayouts([...payouts, payout])
    }

    const handlePayoutUpdate = (payout) => {
        putUndo()
        updateDate(payout)
        setPayouts([...removePayout(payout), payout])
    }

    const handlePayoutUndo = () => {
        setPayouts(getUndo())
    }

    const mergePayouts = (data) => {
        if (!data || !payouts || payouts.length === 0) {
            return data
        }
        const items = JSON.parse(JSON.stringify(payouts))
        items.forEach(item => {
            delete item['id']
        })
        data.payouts = items
        return data
    }

    return {payouts, mergePayouts, handlePayoutAdd, handlePayoutUpdate, handlePayoutRemove, handlePayoutUndo}
}