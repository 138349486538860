import log from 'loglevel';
import axios from "axios";
import {getIpcRenderer} from "../../utils/electron";
import {formatMobileNumberForApi, convertDateToISOString} from "../../utils/format";

const ipcRenderer = getIpcRenderer();

function isString(val) {
    return typeof val === 'string' || ((!!val && typeof val === 'object') && Object.prototype.toString.call(val) === '[object String]');
}

function isObject(val) {
    if (val === null) { return false;}
    return ( (typeof val === 'function') || (typeof val === 'object') );
}

function getErrorMessage(err) {
    if (err.response) {
        if (err.response.data) {
            if (isString(err.response.data)) {
                return err.response.data;
            }
            if (isString(err.response.data.message)) {
                return err.response.data.message;
            }
            if (isString(err.response.data.errorMessage)) {
                return err.response.data.errorMessage;
            }
            if(isObject(err.response.data.errorMessage) && isString(err.response.data.errorMessage.message)) {
                return err.response.data.errorMessage.message;
            }
        }
        return JSON.stringify(err.response, null, 2);
    }
    return err.message;
}

export async function getProfileName() {
    let profile = !!ipcRenderer ? await ipcRenderer.invoke('get-settings-key', 'profile') : window._wtenv_.REACT_APP_AWS_PROFILE
    if (!profile) {
        throw new Error('AWS profile name not defined.');
    }
    return profile
}

export async function getApiUrl(path) {
    const profile = await getProfileName();
    return `https://api.${profile}.us/api/${path}`;
}

export async function getPeach(url, key, responseType) {
    const response = await axios({
        url,
        method: 'GET',
        headers: {
            'X-API-KEY': key
        },
        responseType
    });
    return response.data
}

export async function deletePeach(url, key, responseType) {
    const response = await axios({
        url,
        method: 'DELETE',
        headers: {
            'X-API-KEY': key
        },
        responseType
    });
    return response.data
}

async function post(url, data, accessToken) {
    let payload;
    try {
        const response = await axios({
            url,
            method: 'POST',
            data,
            headers: {
                'Authorization': accessToken
            }
        });
        payload = response.data || {};
    } catch (err) {
        payload = {errorMessage: getErrorMessage(err)}
    }
    if (payload.errorMessage) {
        throw new Error(payload.errorMessage);
    }
    if (payload.message) {
        throw new Error(payload.message);
    }
    return payload;
}

export async function createSignupApi(data, accessToken) {
    const url = await getApiUrl(`signuplinks`);
    log.debug(`Creating signup, POST to [${url}]`);
    return await post(url, data, accessToken);
}

export async function createPaymentLinkApi(data, accessToken) {
    if (!data.merchantId) {
        throw new Error(`Merchant ID not specified to create payment link.`)
    }
    const url = await getApiUrl(`merchants/${data.merchantId}/paymentlinks`);
    convertDateToISOString(data, 'dob')
    convertDateToISOString(data, 'serviceCompletedOn')
    const payload = JSON.parse(JSON.stringify(data))
    delete payload.merchantId;
    return await post(url, payload, accessToken);
}

export async function createTransactionApi(data, accessToken) {
    if (!data.id) {
        throw new Error(`Merchant ID not specified to create transaction.`)
    }
    const url = await getApiUrl(`merchants/${data.id}/transactions`);
    log.debug(`Creating transaction, POST to [${url}]`);
    convertDateToISOString(data, 'dob')
    const payload = cleanData(data)
    delete payload.id;
    return await post(url, payload, accessToken);
}

function cleanData(data) {
    let cleanData = {}
    Object.entries(data).forEach(([key, value]) => {
        if(value) {
            cleanData[key] = value
        }
    })
    return cleanData;
}

export async function reviewMerchantApi({id, alloyEntityToken, outcome, reasons, reviewer}) {
    if (outcome !== 'Approved' && outcome !== 'Denied') {
        throw new Error("Invalid outcome specified.");
    }
    const url = await getApiUrl(`alloywebhook`);
    log.debug(`Reviewing merchant, POST to [${url}]`);
    const response = await axios({
        url,
        method: 'POST',
        data: {
            "type": "create:reviews:is_entity",
            "data": {
                "application_name": "merchant - business",
                "entity_token": alloyEntityToken,
                "outcome": outcome,
                "reasons": reasons,
                "reviewer": reviewer,
                "review_token": "TEST_REVIEW_TOKEN",
                "timestamp": Date.now()
            }
        },
    });
    let payload = response.data || {};
    if (payload.errorMessage) {
        throw new Error(`Merchant review failed: ${payload.errorMessage}`);
    }
    if (!payload.message || payload.message !== 'SUCCESS') {
        throw new Error(`Merchant review failed: ${JSON.stringify(payload)}`);
    }
    return {id, transactionsEnabled: outcome === 'Approved'}
}

export async function getTransactionsInfoApi({merchantId, cursorId, page, limit, statuses, years, months}, accessToken) {
    let url = await getApiUrl(`merchants/${merchantId}/transactions?limit=${limit}`);
    if (cursorId) {
        url = url + '&cursorId=' + cursorId
    }
    if (statuses && statuses.length > 0) {
        url = url + '&statuses=' + encodeURIComponent(statuses.join(','));
    }
    if (years && years.length > 0) {
        url = url + '&years=' + encodeURIComponent(years.join(','));
    }
    if (months && months.length > 0) {
        url = url + '&months=' + encodeURIComponent(months.join(','));
    }
    let payload;
    log.debug(`Getting merchant transactions [${url}]`);
    try {
        const response = await axios({
            url,
            method: 'GET',
            headers: {
                'Authorization': accessToken
            }
        });
        payload = response.data || {};
    } catch (err) {
        payload = {errorMessage: getErrorMessage(err)}
    }
    if (payload.errorMessage) {
        throw new Error(payload.errorMessage);
    }
    if (payload.message) {
        throw new Error(payload.message);
    }
    if (payload.cursor) {
        payload.cursor.page = page;
    }
    return payload;
}

export async function getSignupInfoApi({merchantId, createdBefore, limit=200}, accessToken) {
    let url = await getApiUrl(`signuplinks?limit=${limit}`);
    if (merchantId) {
        url = url + '&merchantId=' + merchantId
    }
    if (createdBefore) {
        url = url + '&createdBefore=' + createdBefore
    }
    let payload;
    log.debug(`Getting signup info [${url}]`);
    try {
        const response = await axios({
            url,
            method: 'GET',
            headers: {
                'Authorization': accessToken
            }
        });
        payload = response.data || {};
    } catch (err) {
        payload = {errorMessage: getErrorMessage(err)}
    }
    if (payload.errorMessage) {
        throw new Error(payload.errorMessage);
    }
    if (payload.message) {
        throw new Error(payload.message);
    }
    return payload;
}

export async function createPrequalLinkApi({merchantId, userId, customerId, mobileNumber, callbackURL, link}, accessToken) {
    const url = await getApiUrl(`merchants/${merchantId}/prequallinks`);
    log.debug(`Creating prequal link, POST to [${url}]`);
    let data = {}
    if (customerId) {
        data.customerId = customerId;
    }
    if (userId) {
        data.initiatorUserId = userId;
    }
    if (mobileNumber) {
        data.mobileNumber = formatMobileNumberForApi(mobileNumber);
    }
    if (link) {
        data.prequalLink = link;
    }
    if (callbackURL) {
        data.callbackURL = callbackURL;
    }
    return await post(url, data, accessToken);
}

export async function reviewLoanApplicationApi({taskId, outcome}) {
    const url = await getApiUrl('crmwebhook');
    const response = await axios({
        url,
        method: 'POST',
        data: {
            "entity": {
                "TASK_ID": taskId,
                "CUSTOMFIELDS": [
                    {
                        "FIELD_NAME": "Fraud_Result_Outcome__c",
                        "FIELD_VALUE": outcome,
                        "CUSTOM_FIELD_ID": "Fraud_Result_Outcome__c"
                    }
                ]
            }
        }
    });
    let payload = response.data || {};
    if (payload.errorMessage) {
        throw new Error(payload.errorMessage);
    }
    return payload;
}

export async function refundLoanApplicationApi({merchantId, loanApplicationId, refundAmount, payoutId}, accessToken) {
    const url = await getApiUrl(`merchants/${merchantId}/transactions/${loanApplicationId}/refund`);
    log.debug(`Creating refund, POST to [${url}]`);
    let data = {
        refundAmount
    }
    if (payoutId) {
        data.payoutId = payoutId
    }
    return await post(url, data, accessToken);
}
