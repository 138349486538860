import React from 'react';
import {useSelector} from "react-redux";
import { v4 as uuid } from 'uuid';
import {useForm} from "react-hook-form";

import Grid from "@material-ui/core/Grid";
import {Button, DialogActions} from "@material-ui/core";
import Link from "@material-ui/core/Link";

import ExPanel from "./ExPanel";
import ErrorMessage from "./ErrorMessage";
import Progress from "./Progress";
import {FormInputCtr} from "./FormInputCtr";
import useApiRequest from "../hooks/useApiRequest";
import {getFieldList} from "../utils/format";
import {getApiUrl} from "../store/actions/api";
import log from "loglevel";
import {phoneNumberPattern} from "../utils/validators";
import {isDisabled} from "../store/actions/aws";

const formFields = {
    "merchantId": {
        label: 'Merchant ID',
        initValue: '',
        order: 1,
        hide: () => false,
        rules: {
            required: 'Merchant ID required'
        }
    },
    "userId" : {
        label: 'User Id',
        initValue: '',
        hide: () => false,
        order: 2
    },
    "customerId": {
        label: 'Customer ID',
        initValue: '',
        order: 3,
        hide: (val) => !val,
    },
    "mobileNumber": {
        label: 'Mobile Number',
        initValue: '',
        type: 'phone',
        order: 4,
        hide: (val) => !val,
        rules: {
            pattern: phoneNumberPattern
        }
    },
    "callbackURL": {
        label: 'Callback URL',
        initValue: '',
        order: 5,
        hide: (val) => !val,
    },
    "link": {
        label: 'Prequal Link',
        initValue: '',
        order: 6,
        hide: (val) => !val,
    },
    "localHost": {
        label: 'Local Host',
        initValue: false,
        type: 'switch',
        order: 7,
        hide: (val) => val,
    },
    "decline": {
        label: 'Decline (for testing)',
        initValue: false,
        type: 'switch',
        order: 8,
        hide: (val) => val,
    },
    "callApi": {
        label: 'Use API',
        initValue: true,
        type: 'switch',
        order: 9,
        hide: () => false,
    }
}

const formFieldList = getFieldList(formFields)

const createLinkRequestType = 'create-prequal-link';
const createLinkRequestApiType = 'create-prequal-link-api';
const openLinkRequestType = 'open-prequal-link';

(async () => {
    formFields.callbackURL.initValue = await getApiUrl('testwebhook');
})().catch(e => {
    log.error(e);
});

export default function PrequalLinkPanel({submitRequest}) {
    const formsData = useSelector(state => state.console.formsData[createLinkRequestApiType]);

    const [requestId, requestInProgress, requestError, newRequest] = useApiRequest();
    const prequalLink = useSelector(state => state.console.prequalLink);

    const { handleSubmit, watch, control } = useForm({
        defaultValues: {
            ...formsData
        }
    });

    const watchCallApi = watch("callApi", true)

    const onSubmit = ({
      callApi,
      merchantId,
      userId,
      customerId,
      callbackURL,
      mobileNumber,
      link,
      localHost,
      decline
    }) => {
        if (callApi) {
            submitRequest(createLinkRequestApiType, {
                merchantId,
                userId,
                customerId,
                callbackURL,
                mobileNumber,
                link
            }, newRequest());
        } else {
            submitRequest(createLinkRequestType, {
                merchantId,
                userId,
                prequalId: uuid().substr(-12),
                localHost,
                decline
            }, newRequest());
        }
    }

    const handleOpen = (e) => {
        e.preventDefault();
        submitRequest(openLinkRequestType, {prequalLink}, newRequest());
    }

    const PrequalLink = () => {
        if (prequalLink && !requestInProgress) {
            return <Link variant="subtitle1" color="primary" href="#" onClick={handleOpen}>
                {prequalLink}
            </Link>
        }
        return null;
    }

    return (
        <ExPanel title="Create Prequal Link" tooltip="Create a merchant-specific link for prequalification consumer flow." hidden={isDisabled(createLinkRequestType) && isDisabled(createLinkRequestApiType)}>
            <Grid item xs={12}>
                <form>
                    {formFieldList.map((name) => (
                        <FormInputCtr
                            hide={formFields[name].hide(watchCallApi)}
                            key={name}
                            name={name}
                            formFields={formFields}
                            control={control}
                            disabled={requestInProgress}
                        />
                    ))}
                </form>
                <DialogActions>
                    <Button disabled={requestInProgress} variant="contained" color="primary" onClick={handleSubmit(onSubmit)}>Submit</Button>
                </DialogActions>
                <ErrorMessage errorMessage={requestError}/>
                <Progress show={requestInProgress} requestId={requestId}/>
                <PrequalLink/>
            </Grid>
        </ExPanel>
    )
}
