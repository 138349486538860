import React from "react";
import {useSelector} from "react-redux";

import {Grid, Button, DialogActions} from "@material-ui/core";

import ExPanel from "./ExPanel";
import FormInput from "./FormInput";
import ErrorMessage from "./ErrorMessage";
import Progress from "./Progress";
import JsonView from "./JsonView";
import useApiRequest from "../hooks/useApiRequest";
import useFields from "../hooks/useFields";
import {isDisabled} from "../store/actions/aws";

const formFields = {
    "signupId": {
        label: 'Signup ID',
        initValue: '',
    }
}

const fieldList = [
    "signupId",
];

export default function SignupInfoPanel({submitSignupInfoRequest, openSignup}) {
    const formsData = useSelector(state => state.console.formsData['signup-info']);
    const signupInfo = useSelector(state => state.console.signupInfo);
    const [requestId, requestInProgress, requestError, newRequest] = useApiRequest();
    const [fields, handleOnChange] = useFields(formFields, formsData);
    const dataPresent = !!signupInfo && signupInfo.signupId === fields.signupId;

    const handleSubmit = () => {
        submitSignupInfoRequest(fields, newRequest());
    }

    const handleOpenSignupLink = () => {
        if (signupInfo && signupInfo.signupId) {
            openSignup(signupInfo.signupId, true);
        }
    }

    const SignupInfo = () => {
        if (dataPresent) {
            return (
                <>
                    <Button variant="outlined" color="primary" style={{marginRight: "10px"}} size="small" onClick={handleOpenSignupLink}>
                        Open Signup Link
                    </Button>
                    <JsonView data={signupInfo} shouldExpandNode={(keyPath, data, level) => level < 1}/>
                </>
            )
        }
        return null;
    }

    return (
        <ExPanel title="Signup Info" hidden={isDisabled('signup-info')}>
            <Grid item xs={12}>
                <form>
                    {fieldList.map((name) => (
                        <FormInput
                            disabled={requestInProgress}
                            key={name}
                            name={name}
                            onChange={handleOnChange}
                            getEditValue={name => fields[name]}
                            formFields={formFields}
                        />
                    ))}
                </form>
                <DialogActions>
                    <Button disabled={requestInProgress} variant="contained" color="primary" onClick={handleSubmit}>Submit</Button>
                </DialogActions>
                <ErrorMessage errorMessage={requestError}/>
                <Progress show={requestInProgress} requestId={requestId}/>
                <SignupInfo/>
            </Grid>
        </ExPanel>
    )

}
