import log from "loglevel";
import {getIpcRenderer} from "../../utils/electron";
import {get} from "lodash-es"

import {
    ACHPaymentLambda,
    boardingFileLambda,
    cancelBorrowerCreditFile,
    cancelBorrowerProfile,
    cloudWatchDescribeQueries,
    cloudWatchInsightsQuery,
    cloudWatchLogGroups,
    cognitoSignIn,
    createAuditTrail,
    createLegacySignup,
    createPartner,
    createPrequalLinkManual,
    createUser,
    dataLakeQuery,
    decryptWithVaultLambda,
    deletePartner,
    deleteRefund,
    deleteUser,
    documentAccept,
    encryptWithVaultLambda,
    getAlloyData,
    getAuditTrail,
    getBorrowerCreditFileList,
    getBorrowerProfileList,
    getConsolePermissionLambda,
    getCRMTaskId,
    getDataLakeTableMetadata,
    getDocumentContent,
    getDocumentsInfo,
    getIndustryList,
    getLoanInfo,
    getMerchantAccessToken,
    getMerchantsInfo,
    getMetaData,
    getPartnerAccessToken,
    getPartnersInfo, getPermissionState,
    getPrequalsList,
    getRecentLoans,
    getRecentPartnerMerchants,
    getRefunds,
    getSignupInfo,
    getUsersInfo,
    holdLoanApplicationLambda,
    invokeDisbursement,
    loanCancellationLambda,
    loanApplicationQueryLambda,
    loanLockout,
    loanOriginationLambda,
    loanPayoutCancel,
    loanPayoutHold,
    loanPayoutQueryLambda,
    metaAuditTrail,
    prequalsExpire,
    putItemToDB,
    queryDynamoDB,
    refreshCognitoSession,
    refundLoanApplicationLambda,
    removeBorrowerCreditFile,
    removeBorrowerProfile,
    removeIndustry,
    reportGenerationLambda,
    restoreBorrowerCreditFile,
    restoreBorrowerProfile,
    reviewMerchantLambda,
    sessionSignIn,
    setConsolePermissionLambda,
    signupRepair,
    updateIndustry,
    updateMerchant,
    updatePartner,
    updateUser,
    usersLockout,
    getConfigWithVaultLambda,
    concurrentLoansLambda
} from "./aws";

import {
    createPaymentLinkApi,
    createPrequalLinkApi,
    createSignupApi,
    createTransactionApi,
    deletePeach,
    getApiUrl,
    getPeach,
    getProfileName,
    getSignupInfoApi,
    getTransactionsInfoApi,
    reviewLoanApplicationApi,
    reviewMerchantApi,
    refundLoanApplicationApi
} from "./api";

const ipcRenderer = getIpcRenderer();
log.setDefaultLevel('DEBUG')
let peachConfig;

const reviewMerchant = async (data) => {
    const response = await reviewMerchantApi(data);
    await createAuditTrail('REVIEW_MERCHANT', 'MERCHANT', data.id, {
        request: JSON.stringify(data),
        response: JSON.stringify(response)
    })
    return response;
}

const reviewLoan = async ({loanApplicationId, outcome}) => {
    const taskId = await getCRMTaskId(loanApplicationId);
    const response = await reviewLoanApplicationApi({taskId, outcome})
    await createAuditTrail('REVIEW_LOAN_APPLICATION', 'LOAN_APPLICATION', loanApplicationId, {
        taskId,
        outcome,
        response: JSON.stringify(response)
    })
    return response;
}

const refundLoanApplication = async (fields) => {
    if (!fields.loanApplicationId) {
        throw new Error('Loan application ID not specified to create refund.')
    }
    if (fields.external) {
        const data = {...fields}
        const loan = await getLoanInfo({
            loanApplicationId: fields.loanApplicationId,
            decrypt: false,
            dataTypes: ['LOAN']
        })
        if (!loan.loanApplication) {
            throw new Error(`Loan application ${fields.loanApplicationId} not found.`)
        }
        data.merchantId = loan.loanApplication.merchantInformation.id
        const accessToken = await getMerchantAccessToken(data.merchantId);
        return await refundLoanApplicationApi(data, accessToken);
    }
    return await refundLoanApplicationLambda(fields)
}

const getTransactionsInfo = async (data) => {
    if (!data.merchantId) {
        throw new Error('Merchant ID not specified to get transactions.')
    }
    const accessToken = await getMerchantAccessToken(data.merchantId);
    await createAuditTrail('GET_MERCHANT_TRANSACTIONS','MERCHANT', data.merchantId, {request: JSON.stringify(data)})
    return await getTransactionsInfoApi(data, accessToken);
}

const getSignupListInfo = async (data) => {
    if (!data.accountId) {
        throw new Error('Account ID not specified to get signup info.')
    }
    const accessToken = await getPartnerAccessToken(data.accountId);
    return await getSignupInfoApi(data, accessToken);
}

const createPaymentLink = async (data) => {
    const merchantId = data.merchantId;
    if (!merchantId) {
        throw new Error('Merchant ID not specified to create payment link.')
    }
    const accessToken = await getMerchantAccessToken(merchantId);
    const response = await createPaymentLinkApi(data, accessToken);
    await createAuditTrail('CREATE_PAYMENT_LINK', 'LOAN_APPLICATION', response.transactionId, {
        request: JSON.stringify(data),
        response: JSON.stringify(response)
    })
    return response;
}

const createPrequalLink = async (data) => {
    if (!data.merchantId) {
        throw new Error('Merchant ID not specified to create prequal link.')
    }
    const accessToken = await getMerchantAccessToken(data.merchantId);
    const response = await createPrequalLinkApi(data, accessToken);
    await createAuditTrail('CREATE_PREQUAL_LINK', 'MERCHANT', data.merchantId, {
        request: JSON.stringify(data),
        response: JSON.stringify(response)
    })
    return response;
}

const createTransaction = async (data) => {
    const merchantId = data.id;
    if (!merchantId) {
        throw new Error('Merchant ID not specified to create transaction.')
    }
    const accessToken = await getMerchantAccessToken(merchantId);
    const response = await createTransactionApi(data, accessToken);
    await createAuditTrail('CREATE_TRANSACTION', 'MERCHANT', merchantId, {
        request: JSON.stringify(data),
        response: JSON.stringify(response)
    })
    if (response && response.initToken) {
        const profile = await getProfileName();
        const protocol = (profile === 'wisetack' || profile === 'wisetack_sec') ? 'https' : 'http';
        response.paymentLink = `${protocol}://${profile}.us/#/${response.initToken}`;
        response.merchantId = merchantId;
    }
    return response;
}

const decryptReportFile = async ({reportToken}) => {
    const content = await decryptWithVaultLambda(reportToken)
    return {
        reportToken,
        content
    };
}

const getPeachConfig = async () => {
    if (!peachConfig) {
        peachConfig = await getConfigWithVaultLambda(['peach_base_url','peach_x_api_key'])
    }
    return peachConfig
}

const peachGetBorrower = async ({personId}) => {
    if (!personId) {
        throw new Error("Parameter 'personId' not specified.")
    }
    const config = await getPeachConfig()
    const url = `${config.get('peach_base_url')}/people/${personId}`
    const key = config.get('peach_x_api_key')
    return await getPeach(url, key)
}

const peachGetUser = async ({userId}) => {
    if (!userId) {
        throw new Error("Parameter 'userId' not specified.")
    }
    const config = await getPeachConfig()
    const url = `${config.get('peach_base_url')}/users/${userId}`
    const key = config.get('peach_x_api_key')
    return await getPeach(url, key)
}

const peachLoanApi = async ({personId, loanId}) => {
    if (!personId) {
        throw new Error("Parameter 'personId' not specified.")
    }
    if (!loanId) {
        throw new Error("Parameter 'loanId' not specified.")
    }
    const config = await getPeachConfig()
    const url = `${config.get('peach_base_url')}/people/${personId}/loans/${loanId}`
    const key = config.get('peach_x_api_key')
    return {url, key}
}

const peachGetLoan = async (params) => {
    const {url, key} = await peachLoanApi(params)
    return await getPeach(url, key)
}

const peachGetAutopayPlan = async (params) => {
    const {url, key} = await peachLoanApi(params)
    return await getPeach(url + '/autopay', key)
}

const peachGetContact = async ({personId, contactId}) => {
    if (!personId) {
        throw new Error("Parameter 'personId' not specified.")
    }
    if (!contactId) {
        throw new Error("Parameter 'contactId' not specified.")
    }
    const config = await getPeachConfig()
    const url = `${config.get('peach_base_url')}/people/${personId}/contacts/${contactId}`
    const key = config.get('peach_x_api_key')
    return await getPeach(url, key)
}

const paymentInstrumentApi = async ({personId, paymentInstrumentId}) => {
    if (!personId) {
        throw new Error("Parameter 'personId' not specified.")
    }
    if (!paymentInstrumentId) {
        throw new Error("Parameter 'paymentInstrumentId' not specified.")
    }
    const config = await getPeachConfig()
    const url = `${config.get('peach_base_url')}/people/${personId}/payment-instruments/${paymentInstrumentId}`
    const key = config.get('peach_x_api_key')
    return {url, key}
}

const peachGetPaymentInstrument = async (params) => {
    const {url, key} = await paymentInstrumentApi(params)
    return await getPeach(url, key)
}

const peachDeletePaymentInstrument = async (params) => {
    const {url, key} = await paymentInstrumentApi(params)
    return await deletePeach(url, key)
}

const peachDocumentApi = async ({personId, documentDescriptorId}) => {
    if (!personId) {
        throw new Error("Parameter 'personId' not specified.")
    }
    if (!documentDescriptorId) {
        throw new Error("Parameter 'documentDescriptorId' not specified.")
    }
    const config = await getPeachConfig()
    const url = `${config.get('peach_base_url')}/people/${personId}/documents/${documentDescriptorId}`
    const key = config.get('peach_x_api_key')
    return {url, key}
}

const peachGetDocumentDescriptor = async (params) => {
    const {url, key} = await peachDocumentApi(params)
    return await getPeach(url, key)
}

const peachGetDocumentContent = async (params) => {
    const {url, key} = await peachDocumentApi(params)
    const content = await getPeach(url + '/content', key, 'arraybuffer')
    await openContent(params.documentDescriptorId, content, 'binary')
    return content
}

const createSignup = async (data) => {
    const accessToken = await getPartnerAccessToken(data.accountId);
    const requestData = {
        ...data
    }
    delete requestData['accountId'];
    if (requestData.industry) {
        requestData.business = {
            ...requestData.business,
            industry: requestData.industry
        }
    }
    delete requestData['industry'];
    if (requestData.externalId) {
        requestData.business = {
            ...requestData.business,
            externalId: requestData.externalId
        }
    }
    delete requestData['externalId']
    const response = await createSignupApi(requestData, accessToken);
    await createAuditTrail('CREATE_SIGNUP', 'MERCHANT', response.merchantId, {
        request: JSON.stringify(requestData),
        response: JSON.stringify(response)
    })
    return response;
}

const updateAndGetIndustry = async (data) => {
    await updateIndustry(data)
    return await getIndustryList(data)
}

const removeAndGetIndustry = async (data) => {
    await removeIndustry(data)
    return await getIndustryList({limit: 100})
}

const replaceField = (item, name) => {
    const lName = name.toLowerCase();
    if (item[lName]) {
        const val = item[lName]
        delete item[lName]
        item[name] = val
    }
}

const convertRecord = (item, name) => {
    delete item["aws_rep_updatetime"]
    replaceField(item, 'hashKey')
    replaceField(item, 'rangeKey')
    replaceField(item, 'mirrorHashKey')
    replaceField(item, 'mirrorRangeKey')
    replaceField(item, 'gsi1HashKey')
    replaceField(item, 'gsi1RangeKey')
    replaceField(item, 'gsi2HashKey')
    replaceField(item, 'gsi2RangeKey')
    replaceField(item, 'gsi3HashKey')
    replaceField(item, 'gsi3RangeKey')
    replaceField(item, 'gsi4HashKey')
    replaceField(item, 'gsi4RangeKey')
    replaceField(item, 'gsi5HashKey')
    replaceField(item, 'gsi5RangeKey')
    replaceField(item, 'gsi6HashKey')
    replaceField(item, 'gsi6RangeKey')
    replaceField(item, 'gsi7HashKey')
    replaceField(item, 'gsi7RangeKey')
    replaceField(item, 'gsi8HashKey')
    replaceField(item, 'gsi8RangeKey')
    replaceField(item, 'gsi9HashKey')
    replaceField(item, 'gsi9RangeKey')
    replaceField(item, 'gsi10HashKey')
    replaceField(item, 'gsi10RangeKey')
    if (name && item[name.toLowerCase()]) {
        const data = JSON.stringify(item[name.toLowerCase()])
        delete item[name.toLowerCase()]
        item[name] = data
    }
    return item
}

const importAccounts = async (data) => {
    if (!data || data.length === 0) {
        throw new Error("No data to import accounts.")
    }
    const keyEncrypted = await encryptWithVaultLambda('test_secret')
    for (const element of data) {
        let item = JSON.parse(JSON.stringify(element));
        if (item.account) {
            if (item.account.keyEncrypted) {
                item.account.keyEncrypted = keyEncrypted
            }
            if (item.account.callbackUrl) {
                item.account.callbackUrl = await getApiUrl('testwebhook')
            }
            await putItemToDB(convertRecord(item, 'account'))
        }
    }
}

const encryptToken = async (obj, fieldName) => {
    if (!obj || !obj[fieldName]) {
        return;
    }
    if (fieldName === 'emailEncrypted') {
        await encryptWithVaultLambda('test+encrypted@wisetack.com', obj[fieldName])
    } else if (fieldName === 'phoneNumberEncrypted' || fieldName === 'numberEncrypted') {
        await encryptWithVaultLambda('+15555555555', obj[fieldName])
    } else if (fieldName === 'ssn4Encrypted') {
        await encryptWithVaultLambda('4444', obj[fieldName])
    } else if (fieldName === 'ssnEncrypted') {
        await encryptWithVaultLambda('444444444', obj[fieldName])
    } else if (fieldName === 'federalEINEncrypted') {
        await encryptWithVaultLambda('999999999', obj[fieldName])
    } else if (fieldName === 'bankAccountEncrypted') {
        await encryptWithVaultLambda('1111222233330000', obj[fieldName])
    } else if (fieldName === 'dobEncrypted') {
        await encryptWithVaultLambda('1980-01-01', obj[fieldName])
    } else {
        await encryptWithVaultLambda(fieldName, obj[fieldName])
    }
}

const importMerchants = async (data) => {
    if (!data || data.length === 0) {
        throw new Error("No data to import merchants.")
    }
    for (const element of data) {
        let item = JSON.parse(JSON.stringify(element));
        if (item.merchant) {
            await encryptToken(item.merchant, 'emailEncrypted')
            await encryptToken(item.merchant, 'phoneNumberEncrypted')
            await encryptToken(item.merchant, 'businessAddressEncrypted')
            await encryptToken(item.merchant, 'federalEINEncrypted')
            await encryptToken(item.merchant, 'bankAccountEncrypted')
            await encryptToken(item.merchant, 'bankAccountNameEncrypted')
            if (item.merchant.owners && item.merchant.owners.length > 0) {
                for (const owner of item.merchant.owners) {
                    await encryptToken(owner, 'firstNameEncrypted')
                    await encryptToken(owner, 'lastNameEncrypted')
                    await encryptToken(owner, 'mobileNumberEncrypted')
                    await encryptToken(owner, 'emailEncrypted')
                    await encryptToken(owner, 'homeAddressEncrypted')
                    await encryptToken(owner, 'dobEncrypted')
                    await encryptToken(owner, 'ssn4Encrypted')
                    await encryptToken(owner, 'ssnEncrypted')
                }
            }
            await putItemToDB(convertRecord(item, 'merchant'))
        }
    }
}

const importSignups = async (data) => {
    if (!data || data.length === 0) {
        throw new Error("No data to import signups.")
    }
    for (const element of data) {
        let item = JSON.parse(JSON.stringify(element));
        if (item.signup) {
            if (item.signup.callbackUrl) {
                item.signup.callbackUrl = await getApiUrl('testwebhook')
            }
            if (item.signup.initiator) {
                await encryptToken(item.signup.initiator, 'firstNameEncrypted')
                await encryptToken(item.signup.initiator, 'lastNameEncrypted')
                await encryptToken(item.signup.initiator, 'mobileNumberEncrypted')
                await encryptToken(item.signup.initiator, 'emailEncrypted')
            }
            await putItemToDB(convertRecord(item, 'signup'))
        }
    }
}

const encryptPi = async (data) => {
    if (!data) {
        return
    }
    if (data.addressList) {
        for (const address of data.addressList) {
            await encryptToken(address, 'streetAddress1Encrypted')
        }
    }
    if (data.emailList) {
        for (const address of data.emailList) {
            await encryptToken(address, 'addressEncrypted')
        }
    }
    if (data.phoneList) {
        for (const phone of data.phoneList) {
            await encryptToken(phone, 'numberEncrypted')
        }
    }
    await encryptToken(data, 'firstNameEncrypted')
    await encryptToken(data, 'lastNameEncrypted')
    await encryptToken(data, 'dobEncrypted')
    await encryptToken(data, 'ssn4Encrypted')
    await encryptToken(data, 'ssnEncrypted')
}

const importLoanOffers = async (data) => {
    if (!data || data.length === 0) {
        throw new Error("No data to import loan offers.")
    }
    for (const element of data) {
        let item = JSON.parse(JSON.stringify(element));
        if (item.loanoffer) {
            await putItemToDB(convertRecord(item, 'loanOffer'))
        }
    }
}

const importLoans = async (data) => {
    if (!data || data.length === 0) {
        throw new Error("No data to import loans.")
    }
    for (const element of data) {
        let item = JSON.parse(JSON.stringify(element));
        if (item.loanapplication) {
            if (item.loanapplication.callbackUrl) {
                item.loanapplication.callbackUrl = await getApiUrl('testwebhook')
            }
            await encryptToken(item.loanapplication, 'mobileNumberEncrypted')
            await encryptToken(item.loanapplication, 'ssnEncrypted')
            await encryptPi(item.loanapplication.borrowerPersonalInformationProvidedByMerchant)
            await encryptPi(item.loanapplication.borrowerPersonalInformationProvidedByBorrower)
            await encryptPi(item.loanapplication.borrowerPersonalInformationProvidedByBureau)
            await putItemToDB(convertRecord(item, 'loanApplication'))
        }
    }
}

const importPrequals = async (data) => {
    if (!data || data.length === 0) {
        throw new Error("No data to import prequals.")
    }
    for (const element of data) {
        let item = JSON.parse(JSON.stringify(element));
        if (item.prequalapplication) {
            if (item.prequalapplication.callbackUrl) {
                item.prequalapplication.callbackUrl = await getApiUrl('testwebhook')
            }
            await encryptToken(item.prequalapplication, 'mobileNumberEncrypted')
            await encryptToken(item.prequalapplication, 'ssnEncrypted')
            await encryptPi(item.prequalapplication.borrowerPersonalInformationProvidedByMerchant)
            await encryptPi(item.prequalapplication.borrowerPersonalInformationProvidedByBorrower)
            await encryptPi(item.prequalapplication.borrowerPersonalInformationProvidedByBureau)
            await putItemToDB(convertRecord(item, 'prequalApplication'))
        }
    }
}

const openContent = async (id, content, encoding) => {
    const pdfBuffer = Buffer.from(content, encoding)
    if (ipcRenderer) {
        await ipcRenderer.invoke('open-pdf-window', {id, pdfBuffer})
    } else {
        window.open(URL.createObjectURL(new Blob([pdfBuffer], {
            type: "application/pdf"
        })),'_blank')
    }
}

const openDocument = async (data) => {
    const response = await getDocumentContent(data);
    if (data.open && response.documents && response.documents.length > 0) {
        const document = response.documents[0];
        const id = document.id || document.entityId;
        await openContent(id, document.content, 'base64')
    } else if (response.errorMessage) {
        throw new Error(response.errorMessage)
    } else {
        throw new Error("Document not found in lambda response.")
    }
    return response
}

const openPaymentLink = async ({paymentLink, shortId, initToken}) => {
    if (shortId && !initToken && !paymentLink) {
        const loanInfo = await getLoanInfo({loanApplicationId: shortId, dataTypes: ['LOAN'], decrypt: false});
        if (!loanInfo || !loanInfo.loanApplication || !loanInfo.loanApplication.initToken) {
            throw new Error(`Loan application [${shortId}] not found.`);
        }
        initToken = loanInfo.loanApplication.initToken;
    }
    if (initToken && !paymentLink) {
        const profile = await getProfileName();
        const protocol = (profile === 'wisetack' || profile === 'wisetack_sec') ? 'https' : 'http';
        paymentLink = `${protocol}://${profile}.us/#/${initToken}`
    }
    if (!paymentLink) {
        throw new Error(`Payment Link undefined to open.`);
    }
    await createAuditTrail('OPEN_PAYMENT_LINK', 'LOAN_APPLICATION', null, {paymentLink})
    if (ipcRenderer) {
        await ipcRenderer.invoke('open-payment-link-window', paymentLink)
    } else {
        window.open(paymentLink, '_blank').focus();
    }
}

const openPrequalLink = async (data) => {
    let prequalLink = data.prequalLink;
    if (!prequalLink) {
        const link = data.link;
        if (link) {
            const profile = await getProfileName();
            const protocol = (profile === 'wisetack' || profile === 'wisetack_sec') ? 'https' : 'http';
            prequalLink = `${protocol}://${profile}.us/#${link}`
        }
    }
    if (!prequalLink) {
        throw new Error(`Prequal Link undefined to open.`);
    }
    await createAuditTrail('OPEN_PREQUAL_LINK', 'PREQUAL_APPLICATION', null, {prequalLink})
    if (ipcRenderer) {
        await ipcRenderer.invoke('open-signup-window', prequalLink)
    } else {
        window.open(prequalLink, '_blank').focus();
    }
}

const openSignupLink = async ({signupLink, createUrl}) => {
    if (createUrl) {
        const profile = await getProfileName();
        const protocol = (profile === 'wisetack' || profile === 'wisetack_sec') ? 'https' : 'http';
        signupLink = `${protocol}://signup.${profile}.us/#/${signupLink}`;
    }
    await createAuditTrail('OPEN_SIGNUP_LINK', 'SIGNUP', null, {signupLink})
    if (ipcRenderer) {
        await ipcRenderer.invoke('open-signup-window', signupLink)
    } else {
        window.open(signupLink, '_blank').focus();
    }
}

const openMerchantPortal = async () => {
    const profile = await getProfileName();
    const protocol = (profile === 'wisetack' || profile === 'wisetack_sec') ? 'https' : 'http';
    const url = `${protocol}://business.${profile}.us`;
    if (ipcRenderer) {
        await ipcRenderer.invoke('open-payment-link-window', url)
    } else {
        window.open(url, '_blank').focus();
    }
}

const openExternal = async ({url}) => {
    if (ipcRenderer) {
        await ipcRenderer.invoke('open-external', {url})
    } else {
        window.open(url, '_blank').focus();
    }
}

const requestMap = {
    'refunds-list' : {
        func: getRefunds,
        name: 'Get refunds for loan application',
        checkOnServer: true
    },
    'delete-refund': {
        func: deleteRefund,
        name: 'Delete refund record',
        checkOnServer: true
    },
    'cognito-sign-in': {
        func: cognitoSignIn,
        name: 'Sign in with AWS Cognito',
        default: true
    },
    'google-sign-in': {
        func: sessionSignIn,
        name: 'Sign in with Google',
        default: true
    },
    'refresh-cognito-session': {
        func: refreshCognitoSession,
        name: 'Refresh login session',
        default: true
    },
    'metadata': {
        func: getMetaData,
        name: 'Get console metadata',
        default: true
    },
    'vault-encrypt': {
        func: encryptWithVaultLambda,
        name: 'Encrypt with Vault',
        checkOnServer: true
    },
    'vault-decrypt': {
        func: decryptWithVaultLambda,
        name: 'Decrypt wit Vault',
        checkOnServer: true
    },
    'get-partner-access-token': {
        func: getPartnerAccessToken,
        name: 'Get partner access token',
        dependsOn: ['query-dynamodb']
    },
    'get-merchant-access-token': {
        func: getMerchantAccessToken,
        name: 'Get merchant access token',
        dependsOn: ['query-dynamodb']
    },
    'recent-partner-merchants': {
        func: getRecentPartnerMerchants,
        name: 'Get recent merchants list',
        checkOnServer: true
    },
    'recent-loans': {
        func: getRecentLoans,
        name: 'Get recent loans list',
        checkOnServer: true
    },
    'partners-info': {
        func: getPartnersInfo,
        name: 'Get partners list',
        checkOnServer: true
    },
    'create-partner': {
        func: createPartner,
        name: 'Create new partner',
        checkOnServer: true
    },
    'update-partner': {
        func: updatePartner,
        name: 'Update partner',
        checkOnServer: true
    },
    'delete-partner': {
        func: deletePartner,
        name: 'Delete partner',
        checkOnServer: true
    },
    'merchants-info': {
        func: getMerchantsInfo,
        name: 'Get merchants list',
        checkOnServer: true
    },
    'reload-merchant': {
        func: getMerchantsInfo,
        default: true
    },
    'update-merchant': {
        func: updateMerchant,
        name: 'Update merchant',
        checkOnServer: true
    },
    'audit-logs': {
        func: getAuditTrail,
        name: 'View audit logs',
        checkOnServer: true
    },
    'audit-trail': {
        func: getAuditTrail,
        default: true
    },
    'loan-info': {
        func: getLoanInfo,
        name: 'View loan application data',
        checkOnServer: true
    },
    'signup-info': {
        func: getSignupInfo,
        name: 'Get merchant signup data',
        checkOnServer: true
    },
    'create-signup': {
        func: createSignup,
        name: 'Create new merchant signup (API)',
        dependsOn: ['get-partner-access-token']
    },
    'signup-repair': {
        func: signupRepair,
        name: 'Repair signup',
        checkOnServer: true
    },
    'payment-link': {
        func: createPaymentLink,
        name: 'Create payment link (API)',
        dependsOn: ['get-merchant-access-token']
    },
    'create-transaction': {
        func: createTransaction,
        name: 'Create transaction (API)',
        dependsOn: ['get-merchant-access-token']
    },
    'loan-review': {
        func: reviewLoan,
        name: 'Review loan application (API)'
    },
    'review-merchant': {
        func: reviewMerchant,
        name: 'Review merchant signup (API)'
    },
    'transactions-info': {
        func: getTransactionsInfo,
        name: 'Get merchant transaction list (API)',
        dependsOn: ['get-merchant-access-token']
    },
    'signup-list-info': {
        func: getSignupListInfo,
        name: 'Get partner merchant signup list (API)',
        dependsOn: ['get-partner-access-token']
    },
    'get-alloy-data': {
        func: getAlloyData,
        name: 'Get entity Alloy data',
        checkOnServer: true
    },
    'create-prequal-link-api': {
        func: createPrequalLink,
        name: 'Create prequal link (API)'
    },
    'create-prequal-link': {
        func: createPrequalLinkManual,
        name: 'Create prequal link (manual)'
    },
    'prequals-list': {
        func: getPrequalsList,
        name: 'Get prequals list',
        checkOnServer: true
    },
    'prequals-expire': {
        func: prequalsExpire,
        name: 'Expire prequal',
        checkOnServer: true
    },
    'borrower-credit-file-list': {
        func: getBorrowerCreditFileList,
        name: 'Get borrower credit file list',
        checkOnServer: true
    },
    'borrower-credit-file-cancel': {
        func: cancelBorrowerCreditFile,
        name: 'Cancel borrower credit file',
        checkOnServer: true
    },
    'borrower-credit-file-restore': {
        func: restoreBorrowerCreditFile,
        name: 'Restore borrower credit file',
        checkOnServer: true
    },
    'borrower-credit-file-remove': {
        func: removeBorrowerCreditFile,
        name: 'Remove borrower credit file',
        checkOnServer: true
    },
    'invoke-disbursement': {
        func: invokeDisbursement,
        name: 'Invoke disbursement',
        checkOnServer: true
    },
    'borrower-profile-list': {
        func: getBorrowerProfileList,
        name: 'Get borrower profile list',
        checkOnServer: true
    },
    'borrower-profile-cancel': {
        func: cancelBorrowerProfile,
        name: 'Cancel borrower profile',
        checkOnServer: true
    },
    'borrower-profile-restore': {
        func: restoreBorrowerProfile,
        name: 'Restore borrower profile',
        checkOnServer: true
    },
    'borrower-profile-remove': {
        func: removeBorrowerProfile,
        name: 'Remove borrower profile',
        checkOnServer: true
    },
    'open-payment-link': {
        func: openPaymentLink,
        name: 'Open payment link'
    },
    'open-prequal-link': {
        func: openPrequalLink,
        name: 'Open prequal link'
    },
    'open-signup': {
        func: openSignupLink,
        name: 'Open signup link'
    },
    'open-merchant-portal': {
        func: openMerchantPortal,
        name: 'Open merchant portal'
    },
    'query-dynamodb': {
        func: queryDynamoDB,
        name: 'Query DynamoDB'
    },
    'query-dynamodb-record': {
        func: queryDynamoDB,
        default: true
    },
    'create-legacy-signup': {
        func: createLegacySignup,
        name: 'Create signup for legacy merchant',
        checkOnServer: true
    },
    'documents-info': {
        func: getDocumentsInfo,
        name: 'Get documents list',
        checkOnServer: true
    },
    'document-content': {
        func: openDocument,
        name: 'View document content',
        checkOnServer: true
    },
    'document-accept': {
        func: documentAccept,
        name: 'Accept document',
        checkOnServer: true
    },
    'users-info': {
        func: getUsersInfo,
        name: 'View merchant portal users',
        checkOnServer: true
    },
    'update-user': {
        func: updateUser,
        name: 'Update merchant portal user',
        checkOnServer: true
    },
    'create-user': {
        func: createUser,
        name: 'Create merchant portal user',
        checkOnServer: true
    },
    'delete-user': {
        func: deleteUser,
        name: 'Delete merchant portal user',
        checkOnServer: true
    },
    'users-lockout-info': {
        func: usersLockout,
        name: 'View users lockout',
        checkOnServer: true
    },
    'users-lockout-update': {
        func: usersLockout,
        name: 'Update users lockout',
        checkOnServer: true
    },
    'loan-lockout-update': {
        func: loanLockout,
        name: 'Update loan lockout',
        checkOnServer: true
    },
    'open-external': {
        func: openExternal,
        default: true
    },
    'audit-meta': {
        func: metaAuditTrail,
        default: true
    },
    'cloudwatch-insights-query': {
        func: cloudWatchInsightsQuery,
        name: 'View CloudWatch logs'
    },
    'cloudwatch-log-groups': {
        func: cloudWatchLogGroups,
        name: 'View CloudWatch groups'
    },
    'cloudwatch-recent-queries': {
        func: cloudWatchDescribeQueries,
        name: 'View CloudWatch recent queries'
    },
    'data-lake-query': {
        func: dataLakeQuery,
        name: 'Query data lake'
    },
    'table-metadata': {
        func: getDataLakeTableMetadata,
        name: 'View data lake metadata'
    },
    'industry-list': {
        func: getIndustryList,
        name: 'View industry list',
        checkOnServer: true
    },
    'industry-update': {
        func: updateAndGetIndustry,
        name: 'Update industry',
        checkOnServer: true
    },
    'industry-remove': {
        func: removeAndGetIndustry,
        name: 'Remove industry',
        checkOnServer: true
    },
    'import-accounts': {
        func: importAccounts,
        name: 'Import accounts from data lake to DynamoDB'
    },
    'import-merchants': {
        func: importMerchants,
        name: 'Import merchants from data lake to DynamoDB'
    },
    'import-signups': {
        func: importSignups,
        name: 'Import merchant signups from data lake to DynamoDB'
    },
    'import-loans': {
        func: importLoans,
        name: 'Import loan applications from data lake to DynamoDB'
    },
    'import-prequals': {
        func: importPrequals,
        name: 'Import prequals from data lake to DynamoDB'
    },
    'import-loan-offers': {
        func: importLoanOffers,
        name: 'Import loan offers from data lake to DynamoDB'
    },
    'review-merchant-lambda': {
        func: reviewMerchantLambda,
        name: 'Review merchant with lambda',
        checkOnServer: true
    },
    'report-files-list': {
        func: reportGenerationLambda,
        name: 'View reports',
        checkOnServer: true
    },
    'decrypt-report-file': {
        func: decryptReportFile,
        name: 'Decrypt report file',
        dependsOn: ['vault-decrypt']
    },
    'loans-list': {
        func: loanApplicationQueryLambda,
        name: 'Query loan application list by phone number',
        checkOnServer: true
    },
    'console-permission-list': {
        func: getConsolePermissionLambda,
        name: 'View console permission list',
        checkOnServer: true
    },
    'console-permission-set': {
        func: setConsolePermissionLambda,
        name: 'Update console permission',
        checkOnServer: true
    },
    'console-permission-effective': {
        func: getConsolePermissionLambda,
        name: 'Get console effective permission for subject',
        default: true
    },
    'vault-get-config': {
        func: getConfigWithVaultLambda,
        name: 'Get configuration parameters',
        checkOnServer: true
    },
    'concurrent-loans': {
        func: concurrentLoansLambda,
        name: 'Check concurrent loans',
        checkOnServer: true
    },
    'invoke-origination': {
        func: loanOriginationLambda,
        name: 'Invoke Origination lambda',
        checkOnServer: true
    },
    'payouts-list': {
        func: loanPayoutQueryLambda,
        name: 'Get payouts',
        checkOnServer: true
    },
    'payout-cancel': {
        func: loanPayoutCancel,
        name: 'Cancel payout',
        checkOnServer: true
    },
    'payout-hold': {
        func: loanPayoutHold,
        name: 'Hold payout',
        checkOnServer: true
    },
    'payments-list': {
        func: ACHPaymentLambda,
        name: 'Get payments',
        checkOnServer: true
    },
    'boarding-files-list': {
        func: boardingFileLambda,
        name: 'Get boarding files',
        checkOnServer: true
    },
    'loan-cancel': {
        func: loanCancellationLambda,
        name: 'Cancel loan',
        checkOnServer: true
    },
    'loan-hold': {
        func: holdLoanApplicationLambda,
        name: 'Hold loan',
        checkOnServer: true
    },
    'refund-create': {
        func: refundLoanApplication,
        name: 'Create refund',
        checkOnServer: true
    },
    'peach-get-borrower': {
        func: peachGetBorrower,
        name: 'Get Peach API borrower data'
    },
    'peach-get-user': {
        func: peachGetUser,
        name: 'Get Peach API user data'
    },
    'peach-get-loan': {
        func: peachGetLoan,
        name: 'Get Peach API loan data'
    },
    'peach-get-autopay-plan': {
        func: peachGetAutopayPlan,
        name: 'Get Peach API autopay data'
    },
    'peach-get-contact': {
        func: peachGetContact,
        name: 'Get Peach API contact data'
    },
    'peach-get-payment-instrument': {
        func: peachGetPaymentInstrument,
        name: 'Get Peach API payment instrument'
    },
    'peach-delete-payment-instrument': {
        func: peachDeletePaymentInstrument,
        name: 'Delete Peach API payment instrument'
    },
    'peach-get-document-descriptor': {
        func: peachGetDocumentDescriptor,
        name: 'Get Peach API document descriptor'
    },
    'peach-get-document-content': {
        func: peachGetDocumentContent,
        name: 'Get Peach API document content'
    }
}

export const invokeRequest = async (requestType, requestData) => {
    if (requestMap.hasOwnProperty(requestType)) {
        const func = get(requestMap, [requestType, 'func'])
        if (!func) {
            throw new Error(`Function not defined to invoke [${requestType}].`)
        }
        const permissionState = getPermissionState(requestType)
        log.debug(`-<R>- ${requestType} ${permissionState}`)
        if (permissionState === 'DISABLE' && !get(requestMap, [requestType, 'default'])) {
            throw new Error('You need additional privileges in order to perform this action')
        }
        return await func(requestData);
    } else {
        log.debug(`->R<- ${requestType}`)
        if (!ipcRenderer) {
            throw new Error(`ipcRenderer not defined to invoke [${requestType}].`)
        }
        return await ipcRenderer.invoke(requestType, requestData);
    }
}

const getActionName = (action) => {
    const name = get(requestMap, [action, 'name'])
    if (name) {
        return `${action} | ${name}`
    }
    return action
}

export const getActions = () => {
    return Object.keys(requestMap).filter(item => !get(requestMap, [item, 'default'])).sort().map((action) => {
        return {id: action, name: getActionName(action)}
    })
}
