import React from 'react';
import { connect } from "react-redux";

import AppLayout from "../components/AppLayout";
import { submitIpcRequest, decryptWithVault } from "../store/actions/consoleActions";
import DynamoDBQueryPanel from "../components/DynamoDBQueryPanel";
import RawDataPanel from "../components/RawDataPanel";

function DynamoDBPage(props) {
    return (
        <AppLayout title="DynamoDB">
            <DynamoDBQueryPanel
                submitRequest={props.submitIpcRequest}
            />
            <RawDataPanel
                submitRequest={props.submitIpcRequest}
                decryptWithVault={props.decryptWithVault}
            />
        </AppLayout>
    )
}

export default connect(null, {submitIpcRequest, decryptWithVault})(
    DynamoDBPage
);
