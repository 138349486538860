import React from "react";
import {useSelector} from "react-redux";
import DateFnsUtils from "@date-io/date-fns";

import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import Grid from "@material-ui/core/Grid";
import {Button, DialogActions} from "@material-ui/core";

import Alert from "./Alert";
import ExPanel from "./ExPanel";
import ErrorMessage from "./ErrorMessage";
import Progress from "./Progress";
import useApiRequest from "../hooks/useApiRequest";
import {useForm} from "react-hook-form";
import {getFieldList} from "../utils/format";
import {FormInputCtr} from "./FormInputCtr";
import {isDisabled} from "../store/actions/aws";

const formFields = {
    "originationDate": {
        label: 'Origination Date',
        type: 'date',
        initValue: new Date(),
        order: 1
    }
}

const fieldList = getFieldList(formFields)

const requestType = "invoke-origination"

export default function InvokeOriginationPanel({submitRequest}) {
    const originationInfo = useSelector(state => state.console.originationInfo);
    const formsData = useSelector(state => state.console.formsData[requestType]);
    const [requestId, requestInProgress, requestError, newRequest] = useApiRequest();

    const { handleSubmit, control, resetField } = useForm({
        defaultValues: {
            ...formsData
        }
    });

    const onSubmit = (fields) => {
        submitRequest(requestType, fields, newRequest());
    }

    const handleOnClean = () => {
        fieldList.forEach(name => resetField(name, {defaultValue: formFields[name].initValue}));
    }

    const OriginationInfo = () => {
        if(originationInfo && !requestInProgress) {
            return <Alert severity='info'>{originationInfo.message}</Alert>
        }
        return null;
    }

    return (
        <ExPanel title="Invoke Origination" tooltip="Invoke LoanOriginationLambda" hidden={isDisabled(requestType)}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid item xs={12}>
                    <form>
                        {fieldList.map((name) => (
                            <FormInputCtr
                                key={name}
                                name={name}
                                formFields={formFields}
                                control={control}
                                disabled={requestInProgress}
                            />
                        ))}
                    </form>
                    <DialogActions>
                        <Button disabled={requestInProgress} variant="contained" color="primary" onClick={handleOnClean}>Clean</Button>
                        <Button disabled={requestInProgress} variant="contained" color="primary" onClick={handleSubmit(onSubmit)}>Submit</Button>
                    </DialogActions>
                    <ErrorMessage errorMessage={requestError}/>
                    <Progress show={requestInProgress} requestId={requestId}/>
                    <OriginationInfo/>
                </Grid>
            </MuiPickersUtilsProvider>
        </ExPanel>
    )
}