import React from 'react';
import {useSelector} from "react-redux";

import {CopyToClipboard} from "react-copy-to-clipboard/lib/Component";
import Grid from "@material-ui/core/Grid";
import EditIcon from "@material-ui/icons/Edit";
import Chip from "@material-ui/core/Chip";
import Link from "@material-ui/core/Link";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import PeopleIcon from '@material-ui/icons/People';
import {DataGrid} from "@mui/x-data-grid";
import {Button, DialogActions} from "@material-ui/core";

import ExPanel from "./ExPanel";
import ErrorMessage from "./ErrorMessage";
import Progress from "./Progress";
import FormInput from "./FormInput";
import JsonView from "./JsonView";
import JsonSwitch from "./JsonSwitch";
import EditMerchantDialog from "./EditMerchantDialog";
import MerchantReviewDialog from "./MerchantReviewDialog";
import SignupDialog from "./SignupDialog";
import CreateTransactionDialog from "./CreateTransactionDialog";
import useApiRequest from "../hooks/useApiRequest";
import useFields from "../hooks/useFields";
import EditMerchantRepresentativesDialog from "./EditMerchantRepresentativesDialog";
import {isDisabled} from "../store/actions/aws";

const MerchantsInfo = ({columns, loading, jsonView, merchantsInfo}) => {
    if (!merchantsInfo || !merchantsInfo.merchants) {
        return null;
    }
    if (jsonView) {
        return <JsonView data={merchantsInfo} shouldExpandNode={(keyPath, data, level) => level < 3}/>
    }
    return <div style={{height: 400, width: '100%'}}>
        <DataGrid
            rows={merchantsInfo.merchants}
            hideFooterSelectedRowCount={true}
            autoPageSize={true}
            columns={columns}
            loading={loading}
            density='compact'
        />
    </div>
}

const formFields = {
    "accountId": {
        label: 'Partner',
        type: 'partner-select',
        initValue: ''
    },
    "name": {
        label: 'Merchant Name',
        initValue: ''
    },
    "federalEIN": {
        label: 'EIN',
        initValue: ''
    },
    "id": {
        label: 'Merchant ID',
        initValue: ''
    },
    "limit": {
        label: 'Limit',
        type: 'number',
        initValue: 20
    }
}

const fieldList = ["accountId", "name", "federalEIN", "id", "limit"];

export default function MerchantsInfoPanel({submitPartnersInfoRequest, submitSignupInfoRequest, openSignup, openPaymentLink, submitRequest}) {
    formFields.accountId.dataRequest = submitPartnersInfoRequest;
    const merchantsInfo = useSelector(state => state.console.merchantsInfo);
    const updatedMerchant = useSelector(state => state.console.updatedMerchant);

    const [requestId, requestInProgress, requestError, newRequest] = useApiRequest();
    const [fields, handleOnChange] = useFields(formFields);

    const [selectedRow, setSelectedRow] = React.useState('');
    const [jsonView, setJsonView] = React.useState(false);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [openOwners, setOpenOwners] = React.useState(false);
    const [openReview, setOpenReview] = React.useState(false);
    const [openSignupInfo, setOpenSignupInfo] = React.useState(false);
    const [openCreateTransaction, setOpenCreateTransaction] = React.useState(false);

    const dataPresent = !!merchantsInfo && !!merchantsInfo.merchants;

    React.useEffect(() => {
        if (!updatedMerchant || !selectedRow) {
            return
        }
        if (updatedMerchant.id === selectedRow.id) {
            setSelectedRow(updatedMerchant);
        }
    }, [updatedMerchant, selectedRow]);

    const handleInfoRequest = () => {
        submitRequest('merchants-info', fields, newRequest());
    }

    const handleEdit = (row) => {
        if (row.paymentAccountMask && !row.bankAccountEncrypted) {
            submitRequest('reload-merchant', {id: row.id}, newRequest());
        }
        setSelectedRow(row);
        setOpenDialog(true);
    }

    const handleOwnersEdit = (row) => {
        setSelectedRow(row);
        setOpenOwners(true);
    }

    const handleEditClose = () => {
        setOpenDialog(false);
    }

    const handleOwnersClose = () => {
        setOpenOwners(false);
    }

    const handleReviewClose = () => {
        setOpenReview(false);
    }

    const handleSignupClose = () => {
        setOpenSignupInfo(false);
    }

    const handleReview = (row) => {
        if (!row.alloyEntityToken) {
            submitRequest('reload-merchant', {id: row.id}, newRequest());
        }
        setSelectedRow(row);
        setOpenReview(true);
    }

    const handleOpenSignupInfo = (row) => {
        setSelectedRow(row);
        setOpenSignupInfo(true);
    }

    const handleOpenCreateTransaction = (row) => {
        setSelectedRow(row);
        setOpenCreateTransaction(true);
    }

    const handleCreateTransactionClose = () => {
        setOpenCreateTransaction(false);
    }

    const handleCreateSignup = (data, requestId) => {
        submitRequest('create-legacy-signup', data, requestId);
    }

    const idCellRenderer = ({row, value}) => {
        const editedRow = !!selectedRow && selectedRow.id === row.id
        const deleteIcon = editedRow ? <EditIcon/> : null;
        const onDelete = editedRow ? () => setSelectedRow(null) : null;
        return <CopyToClipboard text={row.id}><Chip
            label={value}
            size="small"
            color={row.blocked ? "secondary" : "default"}
            onClick={() => {
                handleEdit(row)
            }}
            deleteIcon={deleteIcon}
            onDelete={onDelete}
        /></CopyToClipboard>
    }

    const ownersCellRenderer = ({row}) => {
        return <CopyToClipboard text={row.id}><PeopleIcon
            color="action"
            cursor="pointer"
            onClick={() => handleOwnersEdit(row)}
        /></CopyToClipboard>
    }

    const signupCellRenderer = ({row, value}) => {
        return <>
            <InfoOutlinedIcon
                style={{marginRight: "5px", cursor: "pointer"}}
                color="action"
                onClick={(e) => handleOpenSignupInfo(row)}
            />
            <Link href="#" color="primary" onClick={(e) => {
                e.preventDefault();
                openSignup(value, true);
            }}>
                {value}
            </Link>
        </>
    }

    const enabledCellRenderer = ({row, value}) => {
        value = true;
        const label = value ? 'Enabled' : 'Disabled'
        let color = 'default'
        if (row.alloyEntityToken) {
            color = value ? "primary" : "secondary"
        }
        return <><Chip
            label={label}
            size="small"
            color={color}
            onClick={() => handleReview(row)}
        />{value && <AddCircleOutlineOutlinedIcon
            style={{marginLeft: "5px", cursor: "pointer"}}
            color="action"
            onClick={() => handleOpenCreateTransaction(row)}
        />}</>
    }

    const columns = [
        {field: 'id', headerName: 'Merchant ID', width: 310, renderCell: idCellRenderer, description: "Edit merchant data"},
        {field: 'owners', headerName: 'Representatives', width: 50, renderCell: ownersCellRenderer, disableColumnMenu: true, description: "Edit representatives", sortable: false},
        {field: 'signupId', headerName: 'Signup ID', width: 100, renderCell: signupCellRenderer},
        {field: 'transactionsEnabled', headerName: 'Transactions', width: 110, renderCell: enabledCellRenderer},
        {field: 'businessLegalName', headerName: 'Name', width: 250},
        {field: 'createdAt', headerName: 'Created', width: 150},
        {field: 'defaultVertical', headerName: 'Vertical', width: 100},
        {field: 'onboardingType', headerName: 'Onboarding Type', width: 150},
    ]

    return (
        <ExPanel title="Merchant Info" hidden={isDisabled('merchants-inf')}>
            <Grid item xs={12}>
                <form>
                    {fieldList.map((name) => (
                        <FormInput
                            key={name}
                            name={name}
                            onChange={handleOnChange}
                            getEditValue={name => fields[name]}
                            formFields={formFields}
                        />
                    ))}
                </form>
                <DialogActions>
                    <Button disabled={requestInProgress} variant="contained" color="primary" onClick={handleInfoRequest}>Submit</Button>
                </DialogActions>
                <ErrorMessage errorMessage={requestError}/>
                <Progress show={requestInProgress} requestId={requestId}/>
                <JsonSwitch hide={!dataPresent} jsonView={jsonView} setJsonView={setJsonView}/>
                <MerchantsInfo
                    columns={columns}
                    loading={requestInProgress}
                    merchantsInfo={merchantsInfo}
                    jsonView={jsonView}
                />
                {openDialog && <EditMerchantDialog
                    data={selectedRow}
                    onClose={handleEditClose}
                    submitRequest={submitRequest}
                    parentRequestId={requestId}
                />}
                {openOwners && <EditMerchantRepresentativesDialog
                    data={selectedRow}
                    onClose={handleOwnersClose}
                    submitRequest={submitRequest}
                    parentRequestId={requestId}
                />}
                {openReview && <MerchantReviewDialog
                    data={selectedRow}
                    onClose={handleReviewClose}
                    submitRequest={submitRequest}
                    parentRequestId={requestId}
                />}
                {openSignupInfo && <SignupDialog
                    data={selectedRow}
                    openSignup={openSignup}
                    onClose={handleSignupClose}
                    createLegacySignup={handleCreateSignup}
                    submitRequest={submitSignupInfoRequest}
                    parentRequestId={requestId}
                />}
                {openCreateTransaction && <CreateTransactionDialog
                    data={selectedRow}
                    onClose={handleCreateTransactionClose}
                    parentRequestId={requestId}
                    submitRequest={submitRequest}
                    openPaymentLink={openPaymentLink}
                />}
            </Grid>
        </ExPanel>
    )
}
