import {useSelector} from "react-redux";

export default function useSettings(formFields) {
    const settings = useSelector(state => state.console.settings) || {};
    const metaData = settings.metaData;
    if (formFields && metaData) {
        if (metaData.verticals) {
            if (formFields.vertical) {
                formFields.vertical.data = metaData.verticals
            }
            if (formFields.defaultVertical) {
                formFields.defaultVertical.data = metaData.verticals
            }
            if (formFields.supportedVerticals) {
                formFields.supportedVerticals.data = metaData.verticals
            }
        }
        if (metaData.pricingGrids) {
            if (formFields.pricingGrid) {
                formFields.pricingGrid.data = metaData.pricingGrids
            }
            if (formFields.defaultPricingGrid) {
                formFields.defaultPricingGrid.data = metaData.pricingGrids
            }
            if (formFields.supportedPricingGridSet) {
                formFields.supportedPricingGridSet.data = metaData.pricingGrids
            }
        }
        if (metaData.mdrGrids) {
            if (formFields.mdrGrid) {
                formFields.mdrGrid.data = metaData.mdrGrids
            }
            if (formFields.defaultMdrGrid) {
                formFields.defaultMdrGrid.data = metaData.mdrGrids
            }
        }
        if (metaData.payoutQueryModes) {
            if (formFields.mode && 'payoutQueryModes' === formFields.mode.dataType) {
                formFields.mode.data = metaData.payoutQueryModes
            }
        }
        if (metaData.payoutStatuses) {
            if (formFields.status && 'payoutStatuses' === formFields.status.dataType) {
                formFields.status.data = metaData.payoutStatuses
            }
        }
    }
    return settings
}
