import React from 'react';
import { connect } from "react-redux";

import AppLayout from "../components/AppLayout";
import EncryptPanel from "../components/EncryptPanel";
import DecryptPanel from "../components/DecryptPanel";
import { encryptWithVault, decryptWithVault, submitIpcRequest } from "../store/actions/consoleActions";

function VaultPage(props) {
    return (
        <AppLayout title="Vault">
            <EncryptPanel encryptWithVault={props.encryptWithVault}/>
            <DecryptPanel decryptWithVault={props.decryptWithVault} submitRequest={props.submitIpcRequest}/>
        </AppLayout>
    )
}

export default connect(null, { encryptWithVault, decryptWithVault, submitIpcRequest })(
    VaultPage
);
