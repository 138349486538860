import 'date-fns';
import React from 'react';
import {useSelector} from "react-redux";

import DateFnsUtils from '@date-io/date-fns';
import Grid from "@material-ui/core/Grid";
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import Chip from "@material-ui/core/Chip";
import {Avatar, Button, DialogActions} from "@material-ui/core";
import Link from "@material-ui/core/Link";

import ExPanel from "./ExPanel";
import FormInput from "./FormInput";
import ErrorMessage from "./ErrorMessage";
import Progress from "./Progress";
import {CopyToClipboard} from "react-copy-to-clipboard/lib/Component";
import {transactionFieldList, transactionFormFields} from "../utils/constants";
import useApiRequest from "../hooks/useApiRequest";
import useFields from "../hooks/useFields";
import LoanPayoutList from "./LoanPayoutList";
import useLoanPayouts from "../hooks/useLoanPayouts";
import {isDisabled} from "../store/actions/aws";

const formFields = {
    "merchantId": {
        label: 'Merchant ID',
        initValue: '',
        autoFill: 'merchant_1'
    },
    "invoiceNumber": {
        label: 'Invoice Number',
        initValue: '',
        autoFill: '123456789'
    },
    ...transactionFormFields
}

const fieldList = [
    "merchantId",
    ...transactionFieldList,
    "invoiceNumber"
];

export default function PaymentLinkPanel({submitRequest, openPaymentLink}) {
    const settings = useSelector(state => state.console.settings);
    const paymentLink = useSelector(state => state.console.paymentLink);
    const transactionId = useSelector(state => state.console.transactionId);
    if (settings && settings.profile) {
        formFields["callbackURL"].autoFill = `https://api.${settings.profile}.us/api/testwebhook`
    }
    const [requestId, requestInProgress, requestError, newRequest] = useApiRequest();
    const [fields, handleOnChange, handleOnClean, handleOnAutoFill] = useFields(formFields, null, fieldList);

    const {
        payouts,
        mergePayouts,
        handlePayoutUndo,
        handlePayoutAdd,
        handlePayoutRemove,
        handlePayoutUpdate
    } = useLoanPayouts()

    const getEditValue = (fieldName) => {
        return fields[fieldName];
    }

    const handlePaymentLinkSubmit = () => {
        submitRequest('payment-link', mergePayouts(fields), newRequest())
    }

    const handleOpenPaymentLink = (e) => {
        e.preventDefault();
        openPaymentLink({paymentLink})
    }

    const PaymentLink = () => {
        if (paymentLink && !requestInProgress) {
            return <Link style={{paddingLeft: "10px"}} variant="subtitle1" color="primary" href="#" onClick={handleOpenPaymentLink}>
                {paymentLink}
            </Link>
        }
        return null;
    }

    const TransactionId = () => {
        if (transactionId && !requestInProgress) {
            return <div>
                <CopyToClipboard text={transactionId}>
                    <Chip
                        size="small"
                        avatar={<Avatar>L</Avatar>}
                        label={transactionId}
                        color="default"
                    />
                </CopyToClipboard>
            </div>
        }
        return null;
    }

    return (
        <ExPanel title="Create Payment Link" hidden={isDisabled('payment-link')}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid item xs={12}>
                    <form>
                        {fieldList.map((name) => (
                            <FormInput
                                disabled={requestInProgress}
                                key={name}
                                name={name}
                                onChange={handleOnChange}
                                getEditValue={getEditValue}
                                formFields={formFields}
                            />
                        ))}
                    </form>
                    <LoanPayoutList
                        loanPayouts={payouts}
                        onRemove={handlePayoutRemove}
                        onUpdate={handlePayoutUpdate}
                        onUndo={handlePayoutUndo}
                        onAdd={handlePayoutAdd}
                    />
                    <TransactionId />
                    <PaymentLink />
                    <DialogActions>
                        <Button disabled={requestInProgress} variant="contained" color="primary" onClick={handleOnClean}>Clean</Button>
                        <Button disabled={requestInProgress} variant="contained" color="primary" onClick={handleOnAutoFill}>AutoFill</Button>
                        <Button disabled={requestInProgress} variant="contained" color="primary" onClick={handlePaymentLinkSubmit}>Submit</Button>
                    </DialogActions>
                    <ErrorMessage errorMessage={requestError}/>
                    <Progress show={requestInProgress} requestId={requestId}/>
                </Grid>
            </MuiPickersUtilsProvider>
        </ExPanel>
    )
}