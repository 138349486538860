import 'date-fns';
import React from 'react';
import {useSelector} from "react-redux";

import DateFnsUtils from '@date-io/date-fns';
import Grid from "@material-ui/core/Grid";
import {MuiPickersUtilsProvider} from '@material-ui/pickers';

import ExPanel from "./ExPanel";
import SubmitButton from "./SubmitButton";
import Alert from "./Alert";
import ErrorMessage from "./ErrorMessage";
import Progress from "./Progress";
import FormInput from "./FormInput";
import useApiRequest from "../hooks/useApiRequest";
import {isDisabled} from "../store/actions/aws";

const formFields = {
    "disbursementDate": {
        label: 'Disbursement Date',
        initValue: new Date(),
        type: 'date'
    }
}

export default function InvokeDisbursementPanel({submitRequest}) {
    const disbursementInfo = useSelector(state => state.console.disbursementInfo);
    const [dateTo, setDateTo] = React.useState(new Date());
    const [requestId, requestInProgress, requestError, newRequest] = useApiRequest();

    const handleInvokeDisbursement = () => {
        submitRequest('invoke-disbursement', dateTo, newRequest());
    }

    const DisbursementInfo = () => {
        if(disbursementInfo && !requestInProgress) {
            const severity = disbursementInfo.includes('processedLoans=0') ? 'info' : 'success';
            return <Alert severity={severity}>{disbursementInfo}</Alert>
        }
        return null;
    }

    return (
        <ExPanel title="Invoke Disbursement" hidden={isDisabled('invoke-disbursement')} tooltip="Invoke LoanDisbursementLambda">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid item xs={12}>
                    <form>
                        <FormInput
                            name="disbursementDate"
                            formFields={formFields}
                            getEditValue={() => dateTo}
                            onChange={(e) => setDateTo(e.target.value)}
                        />
                        <SubmitButton onClick={handleInvokeDisbursement} />
                    </form>
                    <ErrorMessage errorMessage={requestError}/>
                    <Progress show={requestInProgress} requestId={requestId}/>
                    <DisbursementInfo/>
                </Grid>
            </MuiPickersUtilsProvider>
        </ExPanel>
    )
}
