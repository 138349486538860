import React from "react";
import {useSelector} from "react-redux";
import DateFnsUtils from "@date-io/date-fns";

import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import {Button, DialogActions, Grid} from "@material-ui/core";

import ExPanel from "./ExPanel";
import {FormInputCtr} from "./FormInputCtr";
import ErrorMessage from "./ErrorMessage";
import Progress from "./Progress";
import {getFieldList} from "../utils/format";
import useApiRequest from "../hooks/useApiRequest";
import JsonView from "./JsonView";
import {DataGrid} from "@mui/x-data-grid";
import JsonSwitch from "./JsonSwitch";
import Link from "@material-ui/core/Link";
import {CopyToClipboard} from "react-copy-to-clipboard/lib/Component";
import {phoneNumberPattern} from "../utils/validators";
import {useForm} from "react-hook-form";
import {isDisabled} from "../store/actions/aws";

const formFields = {
    "applicationId": {
        label: 'Application ID',
        initValue: '',
        order: 1
    },
    "prequalId": {
        label: 'Prequal ID',
        initValue: '',
        order: 2
    },
    "phoneNumber": {
        label: 'Phone',
        initValue: '',
        type: 'phone',
        rules: {
            pattern: phoneNumberPattern
        },
        order: 3
    },
    "merchantId": {
        label: 'Merchant ID',
        initValue: '',
        order: 4
    },
    "status": {
        label: 'Status',
        type: 'select',
        data: [
            {id: "PENDING"},
            {id: "INITIATED"},
            {id: "PREQUALIFIED"},
            {id: "DECLINED"},
            {id: "EXPIRED"},
            {id: "CANCELED"}
        ],
        initValue: '',
        order: 5
    },
    "date": {
        label: 'Date of prequal creation',
        type: 'date',
        initValue: null,
        order: 6
    },
    "limit": {
        label: 'Limit',
        initValue: '100',
        order: 7
    }
}

const fieldList = getFieldList(formFields)

const requestType = 'prequals-list';
const expireRequestType = 'prequals-expire';

const Prequals = ({columns, loading, jsonView, prequals}) => {
    if (!prequals) {
        return null;
    }
    if (jsonView) {
        return (
            <JsonView data={prequals} shouldExpandNode={(keyPath, data, level) => level < 1}/>
        )
    }
    return <div style={{height: 400, width: '100%'}}>
        <DataGrid
            getRowId={(row) => row.id}
            rows={prequals}
            hideFooterSelectedRowCount={true}
            autoPageSize={true}
            columns={columns}
            loading={loading}
            density='compact'
        />
    </div>
}

export default function PrequalsPanel({submitRequest, openPrequalLink}) {
    const prequalInfo = useSelector(state => state.console.prequalInfo);
    const formsData = useSelector(state => state.console.formsData[requestType]);
    const [requestId, requestInProgress, requestError, newRequest] = useApiRequest();
    const [jsonView, setJsonView] = React.useState(false);

    const dataPresent = !!prequalInfo && prequalInfo.prequals;

    const { handleSubmit, control, resetField } = useForm({
        defaultValues: {
            ...formsData
        }
    });

    const onSubmit = (fields) => {
        submitRequest(requestType, fields, newRequest());
    }

    const handleOnClean = () => {
        fieldList.forEach(name => resetField(name, {defaultValue: formFields[name].initValue}));
    }

    const merchantNameCellRenderer = ({row}) => {
        if (row.merchantInformation) {
            return row.merchantInformation.businessLegalName;
        }
        return '';
    }

    const linkCellRenderer = ({row}) => {
        const toExpire = row.prequalStatus === 'INITIATED' || row.prequalStatus === 'PREQUALIFIED' || row.prequalStatus === 'DECLINED'
        if (row.merchantInformation) {
            const link = `/${row.merchantInformation.signupId}/${row.prequalId}/${row.checksum}`;
            return <>
                <Link href="#" color="primary" onClick={(e) => {
                    e.preventDefault();
                    openPrequalLink({link});
                }}>
                    {link}
                </Link>
                <Link style={{marginLeft: '10px'}} href="#" hidden={!toExpire} color="secondary" onClick={(e) => {
                    e.preventDefault();
                    submitRequest(expireRequestType, {idList: [row.id]}, newRequest());
                }}>
                    EXPIRE
                </Link>

            </>
        }
        return '';
    }

    const copyCellRenderer = ({value}) => {
        return <CopyToClipboard text={value}>
            <div>{value}</div>
        </CopyToClipboard>
    }

    const borrowerNameCellRenderer = ({row}) => {
        if (row.borrowerPersonalInformationProvidedByBorrower) {
            const pi = row.borrowerPersonalInformationProvidedByBorrower
            return `${pi.firstNameEncrypted || ''} ${pi.lastNameEncrypted || ''}`
        }
        return ''
    }

    const columns = [
        {field: 'prequalCreatedAt', headerName: 'Created', width: 180},
        {field: 'mobileNumberEncrypted', headerName: 'Phone', width: 160, renderCell: copyCellRenderer},
        {field: 'borrowerName', headerName: 'Borrower Name', width: 160, renderCell: borrowerNameCellRenderer},
        {field: 'prequalStatus', headerName: 'Prequal Status', width: 160},
        {field: 'status', headerName: 'App Status', width: 160},
        {field: 'merchantName', headerName: 'Merchant Name', width: 300, renderCell: merchantNameCellRenderer},
        {field: 'prequalId', headerName: 'Prequal ID', width: 200, renderCell: copyCellRenderer},
        {field: 'id', headerName: 'Application ID', width: 320, renderCell: copyCellRenderer},
        {field: 'link', headerName: 'Prequal Link', width: 300, renderCell: linkCellRenderer},
    ]

    return (
        <ExPanel title="Prequals" hidden={isDisabled(requestType)}>
            <Grid item xs={12}>
                <div>
                    Possible search parameters combinations:
                    <ul>
                        <li>Application ID</li>
                        <li>Prequal ID</li>
                        <li>Phone</li>
                        <li>Phone, Merchant ID</li>
                        <li>Phone, Merchant ID, Status</li>
                        <li>Phone, Merchant ID, Status, Date (after)</li>
                        <li>Status</li>
                        <li>Status, Date (before)</li>
                    </ul>
                </div>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <form>
                        {fieldList.map((name) => (
                            <FormInputCtr
                                key={name}
                                name={name}
                                formFields={formFields}
                                control={control}
                                disabled={requestInProgress}
                            />
                        ))}
                    </form>
                </MuiPickersUtilsProvider>
                <DialogActions>
                    <Button disabled={requestInProgress} variant="contained" color="primary" onClick={handleOnClean}>Clean</Button>
                    <Button disabled={requestInProgress} variant="contained" color="primary" onClick={handleSubmit(onSubmit)}>Submit</Button>
                </DialogActions>
                <ErrorMessage errorMessage={requestError}/>
                <Progress show={requestInProgress} requestId={requestId}/>
                <JsonSwitch hide={!dataPresent} jsonView={jsonView} setJsonView={setJsonView}/>
                {dataPresent &&
                    <Prequals
                        prequals={prequalInfo.prequals}
                        jsonView={jsonView}
                        loading={requestInProgress}
                        columns={columns}
                    />
                }
            </Grid>
        </ExPanel>
    )
}