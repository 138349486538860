import React from 'react';
import {useSelector} from "react-redux";

import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

import ExPanel from "./ExPanel";
import SubmitButton from "./SubmitButton";
import ErrorMessage from "./ErrorMessage";
import Progress from "./Progress";
import useApiRequest from "../hooks/useApiRequest";
import {isDisabled} from "../store/actions/aws";

export default function DecryptPanel({decryptWithVault, submitRequest}) {
    const vaultConfig = useSelector(state => state.console.vaultConfig);
    const decryptedValue = useSelector(state => state.console.decryptedValue);
    const decryptedToken = useSelector(state => state.console.tokenToDecrypt);
    const [requestId, requestInProgress, requestError, newRequest] = useApiRequest();
    const [tokenToDecrypt, setTokenToDecrypt] = React.useState('');
    const [config, setConfig] = React.useState(false);

    const handleDecrypt = () => {
        if (tokenToDecrypt) {
            if (config) {
                submitRequest('vault-get-config', [tokenToDecrypt], newRequest())
            } else {
                decryptWithVault(tokenToDecrypt, newRequest());
            }
        }
    }

    const DecryptedValue = () => {
        if (config && !!vaultConfig && !requestInProgress && !!tokenToDecrypt && !!vaultConfig.get(tokenToDecrypt)) {
            return (
                <TextField
                    label="Configuration value"
                    variant="outlined"
                    margin="normal"
                    size="small"
                    fullWidth
                    value={vaultConfig.get(tokenToDecrypt)}
                />
            )
        }
        if (decryptedValue && !requestInProgress && decryptedToken === tokenToDecrypt) {
            return (
                <TextField
                    label="Decrypted value"
                    variant="outlined"
                    margin="normal"
                    size="small"
                    fullWidth
                    value={decryptedValue}
                />
            )
        }
        return null;
    }

    return (
        <ExPanel title="Decrypt" hidden={isDisabled('vault-decrypt')}>
            <Grid item xs={12}>
                <form>
                    <TextField
                        label="Token to decrypt"
                        variant="outlined"
                        margin="normal"
                        size="small"
                        required
                        fullWidth
                        value={tokenToDecrypt}
                        onChange={e => {
                            setTokenToDecrypt(e.target.value);
                        }}
                    />
                    <FormControlLabel
                        control={
                            <Switch
                                checked={config}
                                onChange={() => setConfig(!config)}
                                name="Configuration"
                                color="primary"
                            />
                        }
                        label="Configuration"
                    />
                    <SubmitButton onClick={handleDecrypt} />
                </form>
                <ErrorMessage errorMessage={requestError}/>
                <Progress show={requestInProgress} requestId={requestId}/>
                <DecryptedValue />
            </Grid>
        </ExPanel>
    )
}
