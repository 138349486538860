import React from "react";
import {useSelector} from "react-redux";
import DateFnsUtils from "@date-io/date-fns";
import {CopyToClipboard} from "react-copy-to-clipboard/lib/Component";

import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import {Button, DialogActions} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import {DataGrid} from "@mui/x-data-grid";

import ExPanel from "./ExPanel";
import FormInput from "./FormInput";
import ErrorMessage from "./ErrorMessage";
import Progress from "./Progress";
import JsonSwitch from "./JsonSwitch";
import JsonView from "./JsonView";

import useFields from "../hooks/useFields";
import useApiRequest from "../hooks/useApiRequest";
import {formatAuditTrail, formatUT} from "../utils/format";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import AuditTrailDialog from "./AuditTrailDialog";
import {isDisabled} from "../store/actions/aws";

const formFields = {
    "from": {
        label: 'From',
        initValue: null,
        type: 'date-time'
    },
    "to": {
        label: 'To',
        initValue: null,
        type: 'date-time'
    },
    "eventType": {
        label: 'Event',
        type: 'select',
        data: [{id: ''}, {id: 'LOGIN'}],
        initValue: ''
    },
    "userName": {
        label: 'User Name',
        initValue: '',
    },
    "entityType": {
        label: 'Entity Type',
        type: 'select',
        data: [{id: ''}, {id: 'USER'}],
        initValue: ''
    },
    "entityId": {
        label: 'Entity ID',
        initValue: '',
    },
    "limit": {
        label: 'Limit',
        initValue: 100
    }
}

const fieldList = ["from", "to", "eventType", "userName", "entityType", "entityId", "limit"];
const logsRequestType = 'audit-logs';

const AuditTrails = ({columns, loading, jsonView, trails}) => {
    if (!trails) {
        return null;
    }
    if (jsonView) {
        return (
            <JsonView data={trails} shouldExpandNode={(keyPath, data, level) => level < 1}/>
        )
    }
    return <div style={{height: 400, width: '100%'}}>
        <DataGrid
            getRowId={(row) => row.eventDate}
            rows={trails}
            hideFooterSelectedRowCount={true}
            autoPageSize={true}
            columns={columns}
            loading={loading}
            density='compact'
        />
    </div>
}

export default function AuditLogsPanel({submitRequest}) {
    const auditLogs = useSelector(state => state.console.auditLogs);
    const auditMeta = useSelector(state => state.console.auditMeta);
    const formsData = useSelector(state => state.console.formsData[logsRequestType]);
    const [fields, handleOnChange, handleOnClean] = useFields(formFields, formsData);
    const [requestId, requestInProgress, requestError, newRequest] = useApiRequest();
    const [jsonView, setJsonView] = React.useState(false);

    const [selectedRow, setSelectedRow] = React.useState('');
    const [openAuditTrail, setOpenAuditTrail] = React.useState(false);

    const dataPresent = !!auditLogs && !!auditLogs.trailList && auditLogs.trailList.length > 0;

    React.useEffect(() => {
        if (!auditMeta) {
            return
        }
        if(auditMeta.eventTypes) {
            formFields.eventType.data = auditMeta.eventTypes.map(item => {return {"id": item}})
        }
        if(auditMeta.entityTypes) {
            formFields.entityType.data = auditMeta.entityTypes.map(item => {return {"id": item}})
        }
    }, [auditMeta]);

    const handleSubmit = () => {
        submitRequest(logsRequestType, fields, newRequest());
    }

    const handleOpenAuditTrail = (row) => {
        setSelectedRow(row);
        setOpenAuditTrail(true);
    }

    const handleCloseAuditTrail = () => {
        setOpenAuditTrail(false);
    }

    const copyCellRenderer = ({value}) => {
        return <CopyToClipboard text={value}>
            <div>{value}</div>
        </CopyToClipboard>
    }

    const eventDateCellRenderer = ({value}) => {
        return formatUT(value)
    }

    const dataCellRenderer = ({row, value}) => {
        formatAuditTrail(row)
        return  <InfoOutlinedIcon
            style={{marginRight: "5px", cursor: "pointer"}}
            color={!!value ? "primary" : "action"}
            onClick={(e) => handleOpenAuditTrail(row)}
        />
    }

    const columns = [
        {field: 'eventDate', headerName: 'Date', width: 180, renderCell: eventDateCellRenderer},
        {field: 'userName', headerName: 'User', width: 180, renderCell: copyCellRenderer},
        {field: 'eventType', headerName: 'Event', width: 180, renderCell: copyCellRenderer},
        {field: 'entityType', headerName: 'Entity Type', width: 180, renderCell: copyCellRenderer},
        {field: 'entityId', headerName: 'Entity ID', width: 180, renderCell: copyCellRenderer},
        {field: 'data', headerName: 'Data', width: 65, align: 'center', disableColumnMenu: true, renderCell: dataCellRenderer},
        {field: 'tz', headerName: 'Time Zone', width: 180},
        {field: 'osName', headerName: 'OS', width: 180},
        {field: 'applicationName', headerName: 'Application', width: 180},
    ]

    return (
        <ExPanel title="Audit Logs" hidden={isDisabled('audit-logs')}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid item xs={12}>
                    <form>
                        {fieldList.map((name) => (
                            <FormInput
                                disabled={requestInProgress}
                                key={name}
                                name={name}
                                onChange={handleOnChange}
                                getEditValue={name => fields[name]}
                                formFields={formFields}
                            />
                        ))}
                    </form>
                    <DialogActions>
                        <Button disabled={requestInProgress} variant="contained" color="primary" onClick={handleOnClean}>Clean</Button>
                        <Button disabled={requestInProgress} variant="contained" color="primary" onClick={handleSubmit}>Submit</Button>
                    </DialogActions>
                    <ErrorMessage errorMessage={requestError}/>
                    <Progress show={requestInProgress} requestId={requestId}/>
                    <JsonSwitch hide={!dataPresent} jsonView={jsonView} setJsonView={setJsonView}/>
                    { dataPresent &&
                        <AuditTrails
                            columns={columns}
                            trails={auditLogs.trailList}
                            jsonView={jsonView}
                            loading={requestInProgress}
                        />
                    }
                    { openAuditTrail &&
                        <AuditTrailDialog
                            data={selectedRow}
                            onClose={handleCloseAuditTrail}
                            submitRequest={submitRequest}
                            parentRequestId={requestId}
                        />
                    }
                </Grid>
            </MuiPickersUtilsProvider>
        </ExPanel>
    )
}