import React from "react";
import {useSelector} from "react-redux";
import {CopyToClipboard} from "react-copy-to-clipboard/lib/Component";

import Grid from "@material-ui/core/Grid";
import {Button, DialogActions} from "@material-ui/core";
import {DataGrid} from "@mui/x-data-grid";
import EditIcon from "@material-ui/icons/Edit";
import Chip from "@material-ui/core/Chip";

import ExPanel from "./ExPanel";
import ErrorMessage from "./ErrorMessage";
import Progress from "./Progress";
import JsonSwitch from "./JsonSwitch";
import JsonView from "./JsonView";
import EditDynamoDBRecordDialog from "./EditDynamoDBRecordDialog";

import {formatUT} from "../utils/format";
import useApiRequest from "../hooks/useApiRequest";
import useFields from "../hooks/useFields";
import FormInput from "./FormInput";

const formFields = {
    "hashKey":{
        label: 'Hash key value',
        initValue: ''
    },
    "limit": {
        label: 'Limit',
        type: 'number',
        initValue: 50
    }
};

const fieldList = [
    'hashKey',
    'limit'
];

const requestType = 'query-dynamodb'

const QueryResult = ({columns, dynamoDBQueryResult, jsonView, loading}) => {
    if(!dynamoDBQueryResult) {
        return null;
    }
    if (jsonView) {
        return <JsonView data={dynamoDBQueryResult} shouldExpandNode={(keyPath, data, level) => level < 2}/>
    }
    return <div style={{height: 450, width: '100%'}}>
        <DataGrid
            disableSelectionOnClick={true}
            rowHeight={46}
            pageSize={10}
            rowsPerPageOptions={[10]}
            getRowId={(row) => row.hashKey + row.rangeKey}
            rows={dynamoDBQueryResult}
            hideFooterSelectedRowCount={true}
            columns={columns}
            loading={loading}
            density='compact'
        />
    </div>
}

export default function RawDataPanel({submitRequest, decryptWithVault}) {
    const formsData = useSelector(state => state.console.formsData[requestType]);
    const dynamoDBQueryResult = useSelector(state => state.console.dynamoDBQueryResult);

    const [requestId, requestInProgress, requestError, newRequest] = useApiRequest();
    const [jsonView, setJsonView] = React.useState(false);
    const [selectedRow, setSelectedRow] = React.useState('');
    const [openDialog, setOpenDialog] = React.useState(false);

    const [fields, handleOnChange] = useFields(formFields, formsData, fieldList);

    const handleSubmitRequest = () => {
        submitRequest(requestType, {
            ...fields,
            indexName: "main",
            rangeKeyCondition: "begins_with",
            rangeKey: "raw_data#",
            scanIndexForward: false
        }, newRequest());
    }

    const handleEdit = (row) => {
        setSelectedRow(row);
        setOpenDialog(true);
    }

    const handleEditClose = () => {
        setOpenDialog(false);
    }

    const hashKeyCellRenderer = ({row, value}) => {
        const editedRow = !!selectedRow && selectedRow.hashKey === row.hashKey && selectedRow.rangeKey === row.rangeKey
        const deleteIcon = editedRow ? <EditIcon/> : null;
        const onDelete = editedRow ? () => setSelectedRow(null) : null;
        return <CopyToClipboard text={row.hashKey}><Chip
            label={value}
            size="small"
            color={row.blocked ? "secondary" : "default"}
            onClick={() => {
                handleEdit(row)
            }}
            deleteIcon={deleteIcon}
            onDelete={onDelete}
        /></CopyToClipboard>
    }

    const updatedCellRenderer = ({value}) => {
        if (!value) {
            return null;
        }
        return formatUT(value * 1000)
    }

    const columns = [
        {field: 'hashKey', headerName: 'hashKey', width: 320, renderCell: hashKeyCellRenderer},
        {field: 'service', headerName: 'service', width: 200},
        {field: 'rangeKey', headerName: 'rangeKey', width: 450},
        {field: 'aws:rep:updatetime', headerName: 'Updated', width: 300, renderCell: updatedCellRenderer},
    ]

    return (
        <ExPanel title="Raw Data">
            <Grid item xs={12}>
                <form>
                    {fieldList.map((name) => (
                        <FormInput
                            key={name}
                            name={name}
                            onChange={handleOnChange}
                            getEditValue={name => fields[name]}
                            formFields={formFields}
                        />
                    ))}
                </form>
                <DialogActions>
                    <Button disabled={requestInProgress} variant="contained" color="primary" onClick={handleSubmitRequest}>Submit</Button>
                </DialogActions>
                <ErrorMessage errorMessage={requestError}/>
                <Progress show={requestInProgress} requestId={requestId}/>
                <JsonSwitch hide={!dynamoDBQueryResult} jsonView={jsonView} setJsonView={setJsonView}/>
                <QueryResult
                    loading={requestInProgress}
                    jsonView={jsonView}
                    dynamoDBQueryResult={dynamoDBQueryResult}
                    columns={columns}
                />
                {openDialog && <EditDynamoDBRecordDialog
                    data={selectedRow}
                    toDecrypt="json"
                    decryptWithVault={decryptWithVault}
                    onClose={handleEditClose}
                    submitRequest={submitRequest}
                    parentRequestId={requestId}
                />}
            </Grid>
        </ExPanel>
    )

}