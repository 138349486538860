import React from "react";
import {useForm} from "react-hook-form";
import DateFnsUtils from "@date-io/date-fns";

import {
    Button,
    Dialog, DialogActions, DialogContent, DialogTitle,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography
} from "@material-ui/core";
import ControlPointIcon from "@material-ui/icons/ControlPoint";
import UndoIcon from "@material-ui/icons/Undo";
import CheckIcon from "@material-ui/icons/Check";
import EditIcon from "@material-ui/icons/Edit";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import {MuiPickersUtilsProvider} from "@material-ui/pickers";

import DraggableDialog from "./DraggableDialog";
import {FormInputCtr} from "./FormInputCtr";
import {formatUT, getFieldList, randomAmount} from "../utils/format";
import {amountPattern} from "../utils/validators";

const LoanPayout = ({payout, onRemove, onSave}) => {
    const [openDialog, setOpenDialog] = React.useState(false);

    const handleSave = (data) => {
        setOpenDialog(false)
        onSave(data)
    }

    return <>
        <TableRow>
            <TableCell>
                {payout.payoutAmount}
            </TableCell>
            <TableCell>
                {formatUT(payout.dueDate, 'yyyy-MM-dd')}
            </TableCell>
            <TableCell>
                {payout.description}
            </TableCell>
            <TableCell>
                {payout.notRefundable ? (<CheckIcon color={"action"} fontSize={"small"}/>) : ''}
            </TableCell>
            <TableCell>
                <Tooltip title={"Edit"}>
                    <EditIcon color={"action"} cursor={"pointer"} onClick={() => setOpenDialog(true)}/>
                </Tooltip>
                <Tooltip title={"Remove"}>
                    <HighlightOffIcon color={"action"} cursor={"pointer"} onClick={() => onRemove(payout)}/>
                </Tooltip>
            </TableCell>
        </TableRow>
        {openDialog && <EditPayoutDialog onClose={() => setOpenDialog(false)} onSave={handleSave} data={payout}/>}
    </>
}

const formFields = {
    "payoutAmount": {
        label: 'Amount',
        initValue: '',
        rules: {
            required: 'Amount required',
            pattern: amountPattern
        },
        autoFill: () => randomAmount(500, 15000),
        order: 1
    },
    "dueDate": {
        label: 'Date on which the payout should be made',
        type: 'date',
        initValue: null,
        order: 2
    },
    "description": {
        label: 'Description',
        initValue: '',
        autoFill: 'Test payout',
        order: 3
    },
    "notRefundable": {
        label: 'Not Refundable',
        type: 'switch',
        initValue: false,
        order: 4
    }
}
const formFieldList = getFieldList(formFields)

const EditPayoutDialog = ({onClose, onSave, data}) => {
    if(!data) {
        data = {
            payoutAmount: "",
            dueDate: null,
            description: "",
            notRefundable: false
        }
    }

    const { handleSubmit, control } = useForm({
        defaultValues: {
            ...data
        }
    });

    return <Dialog
        PaperComponent={DraggableDialog}
        aria-labelledby="draggable-dialog-title"
        open={true}
    >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
            Loan Payout
        </DialogTitle>
        <DialogContent dividers={true}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <form>
                    {formFieldList.map((name) => (
                        <FormInputCtr
                            key={name}
                            name={name}
                            formFields={formFields}
                            control={control}
                        />
                    ))}
                </form>
            </MuiPickersUtilsProvider>
        </DialogContent>
        <DialogActions>
            <Button variant="contained" color="primary" onClick={handleSubmit(onSave)}>Save</Button>
            <Button variant="contained" onClick={onClose}>Close</Button>
        </DialogActions>
    </Dialog>
}

export default function LoanPayoutList({loanPayouts, onRemove, onAdd, onUpdate, onUndo}) {

    const [openDialog, setOpenDialog] = React.useState(false);

    const handleAddPayout = (payout) => {
        onAdd(payout)
        setOpenDialog(false)
    }

    return <div style={{marginBottom: "20px"}}>
        <Typography variant="h6" gutterBottom component="div">
            Loan Payouts
        </Typography>
        <TableContainer component={Paper}>
            <Table size="small" aria-label="collapsible table">
                <TableHead>
                    <TableRow>
                        <TableCell>Amount</TableCell>
                        <TableCell>Service Completed On</TableCell>
                        <TableCell>Description</TableCell>
                        <TableCell>Not Refundable</TableCell>
                        <TableCell>
                            <Tooltip title={"Add"}>
                                <ControlPointIcon color={"primary"} cursor={"pointer"} onClick={() => setOpenDialog(true)}/>
                            </Tooltip>
                            <Tooltip title={"Undo"}>
                                <UndoIcon color={"primary"} cursor={"pointer"} onClick={onUndo}/>
                            </Tooltip>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    { loanPayouts &&
                        loanPayouts.map((payout) => {
                            return <LoanPayout key={payout.id} payout={payout} onSave={onUpdate} onRemove={onRemove}/>
                        })
                    }
                </TableBody>
            </Table>
        </TableContainer>
        {openDialog &&
            <EditPayoutDialog onClose={() => setOpenDialog(false)} onSave={handleAddPayout}/>
        }
    </div>
}
