import React, {useEffect, useState} from "react";
import {get} from "lodash-es"
import { Auth, Hub } from 'aws-amplify'
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import { useForm } from 'react-hook-form';


import {
    Button,
    Dialog,
    DialogActions, DialogContent,
    DialogTitle
} from "@material-ui/core";

import DraggableDialog from "./DraggableDialog";
import ErrorMessage from "./ErrorMessage";
import Progress from "./Progress";
import useApiRequest from "../hooks/useApiRequest";
import Alert from "./Alert";
import logo from '../assets/img/icon.png';
import useCredentials from "../hooks/useCredentials";
import {FormInputCtr} from "./FormInputCtr";

const formFields = {
    "email": {
        label: 'Email',
        initValue: '',
        rules: {
            required: 'Email required',
            pattern: {
                value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                message: 'Must use a valid email',
            },
        }
    },
    "password": {
        label: 'Password',
        type: 'password',
        initValue: '',
        rules: {
            required: 'Password required'
        }
    },
    "newPassword": {
        label: 'New password',
        type: 'password',
        initValue: '',
    },
    "verificationCode": {
        label: 'Verification code',
        initValue: '',
    },
    "rememberMe": {
        label: 'Remember me',
        type: 'switch',
        initValue: false,
    }
};

const fieldList = ["email", "password", "newPassword", "verificationCode"];

const signInRequestType = 'cognito-sign-in';
const googleSignInRequestType = 'google-sign-in';
const refreshRequestType = 'refresh-cognito-session';
const profileName = process.env.REACT_APP_AWS_PROFILE;

export default function LoginDialog({submitRequest, onClose}) {
    const history = useHistory();
    const dispatch = useDispatch();

    const securityTokenExpired = useSelector(state => state.console.securityTokenExpired);
    const cognitoSignIn = useSelector(state => state.console.cognitoSignIn);
    const cognitoSignInRequestId = useSelector(state => state.console.cognitoSignInRequestId)
    const formsData = useSelector(state => state.console.formsData[signInRequestType]);
    const [requestId, requestInProgress, requestError, newRequest] = useApiRequest();
    const {username, loginRequired} = useCredentials();
    const [cognitoUser, setCognitoUser] = useState(null);
    const [signedIn, setSignedIn] = useState(false);
    const [signIn, setSignIn] = useState(false);
    const [signOut, setSignOut] = useState(false);
    const [signInFailure, setSignInFailure] = useState(null);

    const email = !!cognitoUser && get(cognitoUser, 'signInUserSession.idToken.payload.email')
    const cognitoUserName = !!cognitoSignIn && get(cognitoSignIn,'idToken.payload.cognito:username')

    const credentials = !!requestId && requestId === cognitoSignInRequestId && !!cognitoSignIn && cognitoSignIn.credentials
    const inProgress = signIn || signOut || requestInProgress

    const { handleSubmit, control } = useForm({
        defaultValues: {
            ...formsData,
            email: username || '',
            password: ''
        }
    });

    const passwordResetRequired = cognitoSignIn && cognitoSignIn.passwordResetRequired
    const newPasswordRequired = cognitoSignIn && cognitoSignIn.newPasswordRequired
    const logoutEnabled = !!cognitoSignIn && cognitoSignIn.credentials
    const refreshToken = !!cognitoSignIn && !!cognitoSignIn.refreshToken && cognitoSignIn.refreshToken.token
    const refreshingSession = securityTokenExpired && refreshToken && requestInProgress

    formFields.verificationCode.type = passwordResetRequired ? 'text' : 'hidden'
    formFields.newPassword.type = newPasswordRequired || passwordResetRequired ? 'password' : 'hidden'

    const handleOnSignIn = (data) => {
        submitRequest(signInRequestType, data, newRequest());
    }

    const handleOnSignOut = async () => {
        setSignOut(true)
        if (cognitoUser) {
            await handleOnGoogleSignOut()
        }
        dispatch({type: "sign-out"})
        if (history.location.pathname !== '/') {
            history.push("/")
        }
    }

    const handleOnGoogleSignIn = async () => {
        setSignIn(true)
        if (cognitoUser) {
            await handleOnGoogleSignOut()
        }
        await Auth.federatedSignIn({provider: 'Google'})
    }

    const handleOnGoogleSAMLSignIn = async () => {
        setSignIn(true)
        if (cognitoUser) {
            await handleOnGoogleSignOut()
        }
        await Auth.federatedSignIn({provider: 'GoogleSAML'})
    }

    const handleOnGoogleSignOut = async () => {
        try {
            await Auth.signOut({global: true})
        } catch (err) {
            await Auth.signOut()
        }
    }

    useEffect(() => {
        if (window.location.search && window.location.search.startsWith("?code=")) { // redirect from identity provider
            setSignIn(true)
        }
        const unsubscribe = Hub.listen('auth', (data) => {
            const { payload } = data
            console.log('A new auth event has happened: ', data)
            if (payload.event === 'signIn') {
                console.log('a user has signed in!')
                setSignedIn(true)
                setCognitoUser(payload.data)
            }
            if (payload.event === 'signOut') {
                console.log('a user has signed out!')
                setCognitoUser(null)
                setSignedIn(false)
            }
            if (payload.event === 'signIn_failure' && payload.data.message) {
                setSignInFailure(decodeURIComponent(payload.data.message.replace(/\+/g, '%20')))
            }
        })
        Auth.currentAuthenticatedUser()
            .then(currentUser => {
                console.log("Authenticated user: ", currentUser)
                setCognitoUser(currentUser)
            })
            .catch(() => console.log("Not signed in"));
        return unsubscribe;
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (securityTokenExpired && refreshToken && username) {
            submitRequest(refreshRequestType, {refreshToken, email: username}, newRequest());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [securityTokenExpired, refreshToken]);

    useEffect(() => {
        if(credentials) {
            if (securityTokenExpired) {
                dispatch({type: 'security-token-expired', payload: false})
            } else {
                submitRequest(null, {requestTypeList: ['metadata', 'audit-meta', 'partners-info', 'recent-partner-merchants', 'recent-loans']})
            }
            onClose()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [credentials]);

    useEffect(() => {
        if((!username || signedIn) && email) {
            const session = cognitoUser.signInUserSession
            if (session) {
                submitRequest(googleSignInRequestType, {session, rememberMe: false}, newRequest());
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [username, signedIn, email])

    return <Dialog
        PaperComponent={DraggableDialog}
        aria-labelledby="draggable-dialog-title"
        open={true}
        fullWidth={true}
    >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
            Wisetack Console Login
        </DialogTitle>
        <DialogContent dividers={true}>
            <img style={{display: "block", height: "60px", marginLeft: "auto", marginRight: "auto"}} src={logo} alt="wisetack-logo"/>
            { !cognitoUser && !inProgress && fieldList.map((name) => (
                <FormInputCtr
                    key={name}
                    name={name}
                    formFields={formFields}
                    control={control}
                    disabled={requestInProgress}
                />
            ))}
            { loginRequired && !cognitoUser && !inProgress &&
                <div>Don't have an account? Please contact DevOps engineer.</div>
            }
        </DialogContent>
        <div style={{paddingTop: "10px", paddingLeft: "10px", paddingRight: "10px", paddingBottom: "5px"}}>
            {passwordResetRequired &&
                <Alert severity="warning" style={{marginBottom: "10px"}}>
                    Password reset required. Please enter new password and verification code we sent to your email.
                </Alert>
            }
            {newPasswordRequired &&
                <Alert severity="info" style={{marginBottom: "10px"}}>
                    Password change required. Please enter new password.
                </Alert>
            }
            {refreshingSession &&
                <Alert severity="success" style={{marginBottom: "10px"}}>
                    Refreshing session credentials.
                </Alert>
            }
            {cognitoUser &&
                <Alert severity="success" style={{marginBottom: "10px"}}>
                    Authenticated as {email} {!!cognitoUserName && cognitoUserName !== email ? `(${cognitoUserName})` : ''}
                </Alert>
            }
            <ErrorMessage errorMessage={requestError || signInFailure}/>
            <Progress show={inProgress} requestId={requestId}/>
        </div>
        <DialogActions>
            { logoutEnabled && <Button disabled={inProgress} variant="contained" color="secondary" onClick={handleOnSignOut}>Sign out</Button> }
            { !username && profileName !== 'wisetack' && <Button disabled={inProgress} variant="contained" color="primary" onClick={handleOnGoogleSignIn}>Sign In with Google OAuth</Button>}
            { !username && <Button disabled={inProgress} variant="contained" color="primary" onClick={handleOnGoogleSAMLSignIn}>Sign In with Google SAML</Button>}
            { !username && <Button disabled={inProgress} variant="contained" color="primary" onClick={handleSubmit(handleOnSignIn)}>Sign in</Button>}
            { !securityTokenExpired && logoutEnabled && <Button disabled={inProgress} variant="contained" onClick={onClose}>Close</Button> }
        </DialogActions>
    </Dialog>
}
