import {Button, Chip, Dialog, DialogActions, DialogTitle} from "@material-ui/core";
import DraggableDialog from "./DraggableDialog";
import React, {useEffect} from 'react';
import {CopyToClipboard} from "react-copy-to-clipboard/lib/Component";
import Avatar from "@material-ui/core/Avatar";
import ErrorMessage from "./ErrorMessage";
import Progress from "./Progress";
import useApiRequest from "../hooks/useApiRequest";
import {useSelector} from "react-redux";

const removeRefundRequestType = 'delete-refund';

export default function DeleteRefundDialog({data, onClose, submitRequest, parentRequestId}) {
    const [requestId, requestInProgress, requestError, newRequest] = useApiRequest(parentRequestId);
    const refundDeleteRequestId = useSelector(state => state.console.refundDeleteRequestId);

    useEffect(() => {
        const refundDeleted = !!refundDeleteRequestId && !!requestId && refundDeleteRequestId === requestId;
        if (refundDeleted) {
            onClose()
        }
    });

    const handleOnDelete = () => {

        const refundToDelete = {
            "refundId": data.id,
            "loanApplicationId": data.loanApplicationId
        }

        submitRequest(removeRefundRequestType, refundToDelete, newRequest());
    }

    return (
        <Dialog
            PaperComponent={DraggableDialog}
            aria-labelledby="draggable-dialog-title"
            open={true}
            fullWidth={true}
        >
            <DialogTitle style={{cursor: 'move'}} id="draggable-dialog-title">
                Refund <CopyToClipboard text={data.id}>
                <Chip
                    label={data.id}
                    size="small"
                    color={"default"}
                    onDelete={null}
                    deleteIcon={null}
                    avatar={<Avatar>R</Avatar>}
                    onClick={null}
                />
            </CopyToClipboard>
            </DialogTitle>

            <div style={{paddingTop: "10px", paddingLeft: "10px", paddingRight: "10px", paddingBottom: "5px"}}>
                <ErrorMessage errorMessage={requestError}/>
                <Progress show={requestInProgress} requestId={requestId}/>
            </div>

            <DialogActions>
                <Button disabled={requestInProgress} variant="contained" color="secondary"
                        onClick={handleOnDelete}>Delete</Button>
                <Button disabled={requestInProgress} variant="contained" onClick={onClose}>Close</Button>
            </DialogActions>
        </Dialog>
    )
}
