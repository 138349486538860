import React from "react";
import Editor from "react-simple-code-editor";
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-sql";
import "prism-themes/themes/prism-material-light.css";

import Grid from "@material-ui/core/Grid";
import {Button, Chip, DialogActions} from "@material-ui/core";

import ExPanel from "./ExPanel";
import ErrorMessage from "./ErrorMessage";
import Progress from "./Progress";
import useApiRequest from "../hooks/useApiRequest";
import {useSelector} from "react-redux";
import JsonView from "./JsonView";
import FormInput from "./FormInput";
import {isElectron} from "../utils/electron";
import {isDisabled} from "../store/actions/aws";

const requestType = 'data-lake-query';

const desktop = isElectron();

const templates = {
    'Accounts': {
        query: `SELECT * FROM wisetack_rt 
WHERE
    mirrorhashkey = 'ACCOUNT'
LIMIT 20`
    },
    'Loan Application': {
        query: `SELECT * FROM wisetack_rt
WHERE
    mirrorhashkey = 'LOAN_APPLICATION'
    AND gsi2hashkey = 'LOAN_APPLICATION#SETTLED'
    -- AND hashKey = 'abbed738-74dc-422c-877a-131383681a23'
ORDER BY gsi1rangekey DESC
LIMIT 1`
    },
    'Prequal Application': {
        query: `SELECT * FROM wisetack_rt
WHERE
    mirrorhashkey = 'PREQUAL_APPLICATION'
    AND gsi4hashkey = 'PREQUAL_APPLICATION#PREQUALIFIED'
    -- AND hashKey = '7b5268b6-7d6a-4416-9c93-bc79a080b017'
ORDER BY gsi2rangekey DESC
LIMIT 1`
    },
    'Loan Offers': {
        query: `SELECT * FROM wisetack_rt 
WHERE
    mirrorhashkey = 'LOAN_OFFER' AND hashkey = '1c36b0a3-79a4-4d46-a401-01d2a8c4eb6d'`
    },
    'Merchant': {
        query: `SELECT a.*
FROM wisetack_rt a
JOIN wisetack_rt b
ON a.gsi2hashkey = b.hashkey
WHERE
    a.mirrorhashkey = 'MERCHANT'
    AND b.mirrorhashkey = 'ACCOUNT'
    AND b.gsi2rangekey = 'hcp'
    -- AND a.hashKey = 'a0832594-3aaf-4ad2-bea5-6fa98c49f170'
ORDER BY a.gsi4rangekey DESC
LIMIT 1`
    },
    'Merchant Users': {
        query: `SELECT * FROM wisetack_rt
WHERE
    mirrorhashkey = 'MERCHANT_USER' AND  
    hashKey = 'a0832594-3aaf-4ad2-bea5-6fa98c49f170'`
    },
    'Signup': {
        query: `SELECT * FROM wisetack_rt
WHERE
    mirrorhashkey = 'SIGNUP' AND
    hashKey = 'wfc3xfu'`
    },
    'Pending Micro-deposits': {
        query: `SELECT 
    a.hashkey as "signup_id",
    a.gsi1hashkey as "signup_status",
    json_extract_scalar(a.signup, '$.merchantId') as "merchant_id",
    b.gsi1rangekey as "merchant_name",
    a.gsi2rangekey as "micro_deposits_date"
FROM wisetack_rt a
JOIN wisetack_rt b
ON (json_extract_scalar(a.signup, '$.merchantId') = b.hashkey)
WHERE
    b.mirrorhashkey = 'MERCHANT' AND
    a.gsi1hashkey in ('SIGNUP#APPLICATION_APPROVED','SIGNUP#APPLICATION_SUBMITTED') AND
    a.gsi2hashkey = 'SIGNUP#pending_manual_verification'
ORDER BY a.gsi2rangekey`
    },
    'Routing Number': {
        query: `SELECT * FROM wisetack_rt
WHERE
    mirrorhashkey = 'ROUTING_NUMBER' AND
    hashKey = '091000080'`
    },
    'Mirror hash keys': {
        query: `SELECT distinct mirrorhashkey FROM wisetack_rt ORDER BY mirrorhashkey`
    }
}

export default function DataLakeQueryPanel({submitRequest}) {
    const dataLakeResponse = useSelector(state => state.console.dataLakeResponse);
    const [requestId, requestInProgress, requestError, newRequest] = useApiRequest();
    const [template, setTemplate] = React.useState('Loan Application');
    const [query, setQuery] = React.useState(dataLakeResponse[template] ? dataLakeResponse[template].query : templates[template].query);
    const [production, setProduction] = React.useState(desktop);

    const handleRequest = () => {
        submitRequest(requestType, {query, template, production}, newRequest());
    }

    const handleOnTemplateClick = (name) => {
        setTemplate(name);
        setQuery(dataLakeResponse[name] ? dataLakeResponse[name].query : templates[name].query)
    }

    const QueryTemplates = () => {
        return <>
            {Object.keys(templates).map((name) => (
                <Chip
                    key={name}
                    label={name}
                    size="small"
                    color={name === template ? "primary" : "default"}
                    onClick={() => handleOnTemplateClick(name)}
                    style={{
                        marginLeft: '5px',
                        marginBottom: '5px'
                    }}
                />
            ))}
        </>
    }

    const ImportButton = () => {
        if (dataLakeResponse[template] && dataLakeResponse[template].result) {
            if (template === 'Accounts') {
                return <Button disabled={requestInProgress} variant="contained" color="primary" onClick={() => submitRequest('import-accounts', dataLakeResponse[template].result, newRequest())}>Import Accounts</Button>
            }
            if (template === 'Merchant') {
                return <Button disabled={requestInProgress} variant="contained" color="primary" onClick={() => submitRequest('import-merchants', dataLakeResponse[template].result, newRequest())}>Import Merchants</Button>
            }
            if (template === 'Signup') {
                return <Button disabled={requestInProgress} variant="contained" color="primary" onClick={() => submitRequest('import-signups', dataLakeResponse[template].result, newRequest())}>Import Signups</Button>
            }
            if (template === 'Loan Application') {
                return <Button disabled={requestInProgress} variant="contained" color="primary" onClick={() => submitRequest('import-loans', dataLakeResponse[template].result, newRequest())}>Import Loans</Button>
            }
            if (template === 'Prequal Application') {
                return <Button disabled={requestInProgress} variant="contained" color="primary" onClick={() => submitRequest('import-prequals', dataLakeResponse[template].result, newRequest())}>Import Prequals</Button>
            }
            if (template === 'Loan Offers') {
                return <Button disabled={requestInProgress} variant="contained" color="primary" onClick={() => submitRequest('import-loan-offers', dataLakeResponse[template].result, newRequest())}>Import Loan Offers</Button>
            }
        }
        return null;
    }

    const DataLakeResponse = () => {
        if (dataLakeResponse[template] && dataLakeResponse[template].result) {
            return <JsonView data={dataLakeResponse[template].result} shouldExpandNode={(keyPath, data, level) => level < 2}/>
        }
        return null;
    }

    return (
        <ExPanel title={production ? <span>Data Lake Query <span style={{color: "red"}}>(Production)</span></span> : "Data Lake Query"} hidden={isDisabled('data-lake-query')}>
            <Grid item xs={12}>
                <QueryTemplates/>
                <Editor
                    value={query}
                    onValueChange={(code) => setQuery(code)}
                    highlight={(code) => highlight(code, languages.sql, 'sql')}
                    padding={10}
                    style={{
                        fontFamily: '"Fira code", "Fira Mono", monospace',
                        fontSize: 14,
                        marginBottom: '20px',
                        marginTop: '20px'
                    }}
                />
                { desktop &&
                    <FormInput
                        name="production"
                        getEditValue={() => production}
                        onChange={() => setProduction(!production)}
                        formFields={{"production": {
                                label: "Production",
                                type: "switch"
                            }}}
                    />
                }
                <ErrorMessage errorMessage={requestError}/>
                <Progress show={requestInProgress} requestId={requestId}/>
                <DialogActions>
                    <Button disabled={requestInProgress} variant="contained" color="primary" onClick={handleRequest}>{production ? "Submit to production" : "Submit"}</Button>
                    { desktop && production && <ImportButton/> }
                </DialogActions>
                <DataLakeResponse/>
            </Grid>
        </ExPanel>
    )

}