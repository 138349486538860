import React from 'react';
import {useSelector} from "react-redux";
import DateFnsUtils from "@date-io/date-fns";

import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Link from "@material-ui/core/Link";

import FormInput from "./FormInput";
import ExPanel from "./ExPanel";
import SubmitButton from "./SubmitButton";
import ErrorMessage from "./ErrorMessage";
import Progress from "./Progress";
import JsonView from "./JsonView";
import Alert from "./Alert";
import JsonSwitch from "./JsonSwitch";
import useFields from "../hooks/useFields";
import useApiRequest from "../hooks/useApiRequest";
import {formatUT} from "../utils/format";
import {isDisabled} from "../store/actions/aws";

const formFields = {
    "id": {
        label: 'Document ID',
        initValue: ''
    },
    "entityId": {
        label: 'Loan Application or Merchant ID',
        initValue: ''
    },
    "limit": {
        label: 'Limit',
        type: 'number',
        initValue: 20
    },
    "acceptanceDate": {
        label: 'Acceptance Date',
        initValue: null,
        type: 'date-time'
    }
}
const fieldList = ["id", "entityId", "limit", "acceptanceDate"];

const infoRequestType = 'documents-info';
const contentRequestType = 'document-content';
const acceptRequestType = 'document-accept';

export default function DocumentsInfoPanel({submitRequest}) {
    const documentsInfo = useSelector(state => state.console.documentsInfo);
    const documentAcceptResponse = useSelector(state => state.console.documentAcceptResponse);
    const documentAcceptRequestId = useSelector(state => state.console.documentAcceptRequestId);
    const formsData = useSelector(state => state.console.formsData[infoRequestType]);

    const [requestId, requestInProgress, requestError, newRequest] = useApiRequest();
    const [contentRequestId, contentRequestInProgress, contentRequestError, newContentRequest] = useApiRequest();
    const [fields, handleOnChange] = useFields(formFields, formsData, fieldList);

    const documentAcceptMessage = !!documentAcceptRequestId && documentAcceptRequestId === contentRequestId &&
        !!documentAcceptResponse && documentAcceptResponse.message;

    const [jsonView, setJsonView] = React.useState(false);
    const [contentDocumentId, setContentDocumentId] = React.useState('');

    const dataPresent = !!documentsInfo && !!documentsInfo.documents;

    const handleInfoRequest = () => {
        submitRequest(infoRequestType, fields, newRequest());
    }

    const handleContentRequest = (documentId) => {
        if (requestInProgress || contentRequestInProgress) {
            return;
        }
        setContentDocumentId(documentId);
        submitRequest(contentRequestType, {id: documentId, open: true}, newContentRequest());
    }

    const handleEntityRequest = (entityId, documentId) => {
        if (requestInProgress || contentRequestInProgress) {
            return;
        }
        setContentDocumentId(documentId);
        submitRequest(contentRequestType, {entityId, open: true}, newContentRequest());
    }

    const handleAcceptRequest = (entityId, documentId) => {
        if (requestInProgress || contentRequestInProgress) {
            return;
        }
        setContentDocumentId(documentId);
        submitRequest(acceptRequestType, {documentId, acceptanceDate: fields['acceptanceDate']}, newContentRequest());
    }

    const ProgressContentRow = ({row}) => {
        if (row.id !== contentDocumentId) {
            return null;
        }
        if (contentRequestInProgress || contentRequestError || documentAcceptMessage) {
            return (
                <TableRow>
                    <TableCell colSpan={6}>
                        <Progress show={contentRequestInProgress} requestId={contentRequestId}/>
                        <ErrorMessage errorMessage={contentRequestError}/>
                        {!!documentAcceptMessage && <Alert severity="info">{documentAcceptMessage}</Alert>}
                    </TableCell>
                </TableRow>
            )
        }
        return null;
    }

    const TableView = () => {
        if (!documentsInfo.documents) {
            return <Alert severity="info">No documents found.</Alert>
        }
        return (
            <>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Document ID</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Entity Type</TableCell>
                            <TableCell>Entity ID</TableCell>
                            <TableCell>Accepted</TableCell>
                            <TableCell>Status</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {documentsInfo.documents.map((row) => (
                            <>
                                <TableRow key={row.id}>
                                    <TableCell>
                                        <Link href="#" onClick={(e) => {e.preventDefault(); handleContentRequest(row.id)}}>
                                            {row.id}
                                        </Link>
                                    </TableCell>
                                    <TableCell>{row.name}</TableCell>
                                    <TableCell>{row.entityType}</TableCell>
                                    <TableCell>
                                        <Link href="#" onClick={(e) => {e.preventDefault(); handleEntityRequest(row.entityId, row.id)}}>
                                            {row.entityId}
                                        </Link>
                                    </TableCell>
                                    <TableCell>
                                        <Link href="#" onClick={(e) => {e.preventDefault(); handleAcceptRequest(row.entityId, row.id)}}>
                                            {formatUT(row.accepted) || 'ACCEPT'}
                                        </Link>
                                    </TableCell>
                                    <TableCell>{row.renderStatus}</TableCell>
                                </TableRow>
                                <ProgressContentRow key={'p-'+row.id} row={row}/>
                            </>
                        ))}
                    </TableBody>
                </Table>
            </>
        )
    }

    const DocumentsInfo = () => {
        if (!documentsInfo || requestInProgress) {
            return null;
        }
        if (jsonView) {
            return <JsonView data={documentsInfo} shouldExpandNode={(keyPath, data, level) => level < 3}/>
        }
        return TableView();
    }

    return (
        <ExPanel title="Documents Info" hidden={isDisabled(infoRequestType)}>
            <Grid item xs={12}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <form>
                        {fieldList.map((name) => (
                            <FormInput
                                key={name}
                                name={name}
                                onChange={handleOnChange}
                                getEditValue={name => fields[name]}
                                formFields={formFields}
                            />
                        ))}
                        <div>Acceptance Date used for document ACCEPT operation stamp (not mandatory)</div>
                        <SubmitButton disabled={requestInProgress || contentRequestInProgress} onClick={handleInfoRequest}/>
                    </form>
                </MuiPickersUtilsProvider>
                <ErrorMessage errorMessage={requestError}/>
                <Progress show={requestInProgress} requestId={requestId}/>
                <JsonSwitch hide={!dataPresent} jsonView={jsonView} setJsonView={setJsonView}/>
                <DocumentsInfo/>
            </Grid>
        </ExPanel>
    )
}
