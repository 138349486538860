import React from 'react';
import {useSelector} from "react-redux";

import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import {Button, DialogActions} from "@material-ui/core";

import ExPanel from "./ExPanel";
import ErrorMessage from "./ErrorMessage";
import Progress from "./Progress";
import PartnerSelect from "./PartnerSelect";
import useApiRequest from "../hooks/useApiRequest";
import {createSignature, isDisabled} from "../store/actions/aws";

const bodyExample = `{"date":"2022-01-06 12:52:39+0000","merchantId":"ef4d837e-920d-4745-825e-1dbbabdb8d91","signupLink":"https://signup.wisetack-feature2.us/#/yynrnzi","status":"APPLICATION_APPROVED","eventType":"MERCHANT_SIGNUP","onboardingType":"standard","transactionsEnabled":true,"reasons":"TEST"}`
// const keyExample = `test_secret`
// const expectedSignature = `lRdjHSxW7Uj9gAbH3GlWVlzeC3ilHi5ku5LGplp+H7w=`

export default function AuthTokenPanel({getAuthToken, partner, submitPartnersInfoRequest}) {

    const [requestId, requestInProgress, requestError, newRequest] = useApiRequest();
    const authToken = useSelector(state => state.console.authToken);
    const authTokenRequestId = useSelector(state => state.console.authTokenRequestId);

    const [entityId, setEntityId] = React.useState('');
    const [body, setBody] = React.useState(bodyExample);
    const [key, setKey] = React.useState('');
    const [signature, setSignature] = React.useState('');

    React.useEffect(() => {
        if (authToken) {
            setKey(atob(authToken.replace('Basic ', '')).split(':')[1])
        }
    }, [authToken]);


    const handleSubmit = () => {
        if (entityId) {
            getAuthToken(entityId, newRequest());
        }
    }

    const handleSignature = () => {
        if (!!body && !!key) {
            setSignature(createSignature(body,key))
        } else {
            setSignature('')
        }
    }

    const Signature = () => {
        if (signature) {
            return (
                <TextField
                    label="Signature"
                    variant="outlined"
                    margin="normal"
                    size="small"
                    fullWidth
                    value={signature}
                />
            )
        }
        return null;
    }

    const AuthToken = () => {
        if (authToken && !requestInProgress && authTokenRequestId === requestId) {
            return (
                <TextField
                    label="Authorization token"
                    variant="outlined"
                    margin="normal"
                    size="small"
                    fullWidth
                    value={authToken}
                />
            )
        }
        return null;
    }

    const inputId = () => {
        if (partner) {
            return <PartnerSelect
                name="entityId"
                label="Partner"
                value={entityId}
                onChange={e => setEntityId(e.target.value)}
                submitPartnersInfoRequest={submitPartnersInfoRequest}
            />
        }
        return <TextField
            label="Partner or Merchant ID"
            variant="outlined"
            margin="normal"
            size="small"
            required
            fullWidth
            value={entityId}
            onChange={e => setEntityId(e.target.value)}
        />
    }

    return (
        <ExPanel title="Get Authorization Token" tooltip="Authorization token used by partners in API request http 'Authorization' header." hidden={!!partner ? isDisabled('get-partner-access-token') : isDisabled('get-merchant-access-token')}>
            <Grid item xs={12}>
                <form>
                    {inputId()}
                </form>
                <DialogActions>
                    <Button disabled={requestInProgress} variant="contained" color="primary" onClick={handleSubmit}>Submit</Button>
                </DialogActions>
                <ErrorMessage errorMessage={requestError}/>
                <Progress show={requestInProgress}/>
                <AuthToken />
                <form>
                    <TextField
                        label="Body"
                        variant="outlined"
                        margin="normal"
                        size="small"
                        required
                        fullWidth
                        value={body}
                        onChange={e => setBody(e.target.value)}
                    />
                    <TextField
                        label="Key"
                        variant="outlined"
                        margin="normal"
                        size="small"
                        required
                        fullWidth
                        value={key}
                        onChange={e => setKey(e.target.value)}
                    />
                </form>
                <DialogActions>
                    <Button disabled={requestInProgress} variant="contained" color="primary" onClick={handleSignature}>Signature</Button>
                </DialogActions>
                <Signature/>
            </Grid>
        </ExPanel>
    )
}
