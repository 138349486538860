import React from "react";
import {useSelector} from "react-redux";
import {CopyToClipboard} from "react-copy-to-clipboard/lib/Component";
import DateFnsUtils from "@date-io/date-fns";

import {
    Avatar,
    Button,
    Chip,
    Link,
    Dialog,
    DialogActions, DialogContent,
    DialogTitle
} from "@material-ui/core";
import {MuiPickersUtilsProvider} from "@material-ui/pickers";

import DraggableDialog from "./DraggableDialog";
import {prepareFormData} from "../utils/format";
import ErrorMessage from "./ErrorMessage";
import Progress from "./Progress";
import FormInput from "./FormInput";
import Alert from "./Alert";
import useApiRequest from "../hooks/useApiRequest";
import useFields from "../hooks/useFields";
import {transactionFieldList, transactionFormFields} from "../utils/constants";
import useLoanPayouts from "../hooks/useLoanPayouts";
import LoanPayoutList from "./LoanPayoutList";

const formFields = {
    ...transactionFormFields
};

const fieldList = transactionFieldList;

const submitRequestType = 'create-transaction';

export default function CreateTransactionDialog({data, onClose, submitRequest, parentRequestId, openPaymentLink}) {
    const createTransactionRequestId = useSelector(state => state.console.createTransactionRequestId);
    const transactionInfo = useSelector(state => state.console.transactionInfo);
    const settings = useSelector(state => state.console.settings);
    if (settings && settings.profile) {
        formFields.callbackURL.autoFill = `https://api.${settings.profile}.us/api/testwebhook`
    }
    const [requestId, requestInProgress, requestError, newRequest] = useApiRequest(parentRequestId);
    const [fields, handleOnChange, handleOnClean, handleOnAutoFill] = useFields(formFields, prepareFormData({id: data.id}, fieldList, 'id'), fieldList);

    const {
        payouts,
        mergePayouts,
        handlePayoutUndo,
        handlePayoutAdd,
        handlePayoutRemove,
        handlePayoutUpdate
    } = useLoanPayouts()

    const transactionCreated = !!createTransactionRequestId && !!requestId && createTransactionRequestId === requestId;

    const handleOnSubmit = () => {
        submitRequest(submitRequestType, mergePayouts(fields), newRequest());
    }

    const TransactionCreated = () => {
        if (transactionCreated) {
            return <Alert severity="success">New transaction created</Alert>
        }
        return null;
    }

    const handleOpenPaymentLink = (e) => {
        e.preventDefault();
        openPaymentLink({paymentLink: transactionInfo.paymentLink})
    }

    const TransactionInfo = () => {
        if (!transactionInfo || requestInProgress || transactionInfo.merchantId !== data.id) {
            return null;
        }
        return <div style={{paddingTop: "5px"}}>
            <CopyToClipboard text={transactionInfo.transactionId}>
                <Chip
                    size="small"
                    avatar={<Avatar>T</Avatar>}
                    label={transactionInfo.transactionId}
                    color="default"
                />
            </CopyToClipboard>
            <Link style={{paddingLeft: "10px"}} variant="subtitle1" color="primary" href="#" onClick={handleOpenPaymentLink}>
                {transactionInfo.paymentLink}
            </Link>
        </div>
    }

    return <Dialog
        PaperComponent={DraggableDialog}
        aria-labelledby="draggable-dialog-title"
        open={true}
        fullWidth={true}
    >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
            Create Transaction for <CopyToClipboard text={data.id}>
                <Chip label={data.id} size="small" color="primary"/>
            </CopyToClipboard>
        </DialogTitle>
        <DialogContent dividers={true}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                {fieldList.map((name) => (
                    <FormInput
                        disabled={requestInProgress}
                        key={name}
                        name={name}
                        onChange={handleOnChange}
                        getEditValue={name => fields[name]}
                        formFields={formFields}
                    />
                ))}
                <LoanPayoutList
                    loanPayouts={payouts}
                    onRemove={handlePayoutRemove}
                    onUpdate={handlePayoutUpdate}
                    onUndo={handlePayoutUndo}
                    onAdd={handlePayoutAdd}
                />
            </MuiPickersUtilsProvider>
        </DialogContent>
        <div style={{paddingTop: "10px", paddingLeft: "10px", paddingRight: "10px", paddingBottom: "5px"}}>
            <ErrorMessage errorMessage={requestError}/>
            <Progress show={requestInProgress}/>
            <TransactionCreated/>
            <TransactionInfo/>
        </div>
        <DialogActions>
            <Button disabled={requestInProgress} variant="contained" color="primary" onClick={handleOnClean}>Clean</Button>
            <Button disabled={requestInProgress} variant="contained" color="primary" onClick={handleOnAutoFill}>AutoFill</Button>
            <Button disabled={requestInProgress} variant="contained" color="primary" onClick={handleOnSubmit}>Submit</Button>
            <Button disabled={requestInProgress} variant="contained" onClick={onClose}>Close</Button>
        </DialogActions>
    </Dialog>
}
