import log from 'loglevel';
import crypto from "crypto";
import {get} from "lodash-es"
import {AuthenticationDetails, CognitoRefreshToken, CognitoUser, CognitoUserPool} from 'amazon-cognito-identity-js';
import Amplify from 'aws-amplify';
import {getIpcRenderer} from "../../utils/electron";
import {getProfileName} from "./api";
import {saveUser} from "../../utils/localStorage";
import {formatMobileNumberForApi, formatUT} from "../../utils/format";

const ipcRenderer = getIpcRenderer();
const AWS = require('aws-sdk');

const cognitoAppClientId = window._wtenv_.REACT_APP_COGNITO_CLIENT_ID;
const cognitoUserPoolId =  window._wtenv_.REACT_APP_COGNITO_USER_POOL_ID;
const cognitoIdentityPoolId = window._wtenv_.REACT_APP_COGNITO_IDENTITY_POOL_ID;

const region = 'us-east-1'
const appName = !!ipcRenderer ? 'WISETACK_CONSOLE' : 'WISETACK_CONSOLE_WEB'

let cognitoCredentials = null;

log.setDefaultLevel('DEBUG')

export const configureAmplify = () => {
    if (ipcRenderer) {
        return;
    }
    const profileName = process.env.REACT_APP_AWS_PROFILE;
    const redirectURL = window.location.hostname === 'localhost' ? 'http://localhost:3000' : `https://console.${profileName}.us`
    const cognitoDomain = process.env.REACT_APP_COGNITO_DOMAIN || `${profileName}-console.auth.us-east-1.amazoncognito.com`
    Amplify.configure({
        Auth: {
            region,
            identityPoolId: cognitoIdentityPoolId,
            userPoolId: cognitoUserPoolId,
            userPoolWebClientId: cognitoAppClientId,
            mandatorySignIn: true
        },
        oauth: {
            domain: cognitoDomain,
            redirectSignIn: redirectURL,
            redirectSignOut: redirectURL,
            scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
            responseType: 'code'
        }
    })
    log.debug('Amplify configured.')
}
configureAmplify();

export const setCognitoCredentials = (credentials) => {
    cognitoCredentials = credentials
}

export const getPermissionState = (action) => {
    if (!cognitoCredentials) {
        return null;
    }
    let permissionState = get(cognitoCredentials, ["permission", action])
    if (permissionState) {
        return permissionState
    }
    return get(cognitoCredentials, ["permission", "all"])
}

export const isDisabled = (action) => {
    return getPermissionState(action) === 'DISABLE'
}

export const isEnabled = (action) => {
    return !isDisabled(action)
}

function isString(val) {
    return typeof val === 'string' || ((!!val && typeof val === 'object') && Object.prototype.toString.call(val) === '[object String]');
}

const getAWSContext = async (production, region) => {
    if (ipcRenderer) {
        return await ipcRenderer.invoke('get-aws-context', {production, region});
    }
    if (cognitoCredentials) {
        const config = {
            ...cognitoCredentials
        }
        if (region) {
            config.region = region
        }
        return {
            config,
            clientContext: {
                accessKeyId: cognitoCredentials.accessKeyId,
                username: cognitoCredentials.username,
                os: navigator.userAgent,
                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
            }
        }
    }
    log.error('ipcRenderer or cognitoCredentials not defined to get AWS context.')
    return {}
}

async function getLambdaContext() {
    const {config, clientContext} = await getAWSContext();
    let response = {}
    if (config) {
        response.lambda = new AWS.Lambda(config);
        if (clientContext) {
            response.clientContext = {
                env: {
                    'AWS_ACCESS_KEY_ID': config.accessKeyId,
                    'TZ': clientContext.timezone
                },
                custom: {
                    'APPLICATION_NAME': appName,
                    'OS_NAME': clientContext.os,
                    'USER_NAME': clientContext.username
                }
            }
        }
    }
    return response;
}

function getCognitoIdentityCredentials(idToken, callback) {
    if (!cognitoIdentityPoolId) {
        callback({error: new Error('Cognito identity pool ID not specified to get AWS credentials.')})
        return;
    }
    log.debug('Get AWS credentials by Cognito idToken.')
    AWS.config.region = region;
    const loginMap = {};
    loginMap['cognito-idp.' + region + '.amazonaws.com/' + cognitoUserPoolId] = idToken;
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: cognitoIdentityPoolId,
        Logins: loginMap
    });
    AWS.config.credentials.clearCachedId()
    AWS.config.credentials.get(function(error) {
        if (error) {
            callback({error})
        }
        else {
            callback({
                accessKeyId: AWS.config.credentials.accessKeyId,
                secretAccessKey: AWS.config.credentials.secretAccessKey,
                sessionToken: AWS.config.credentials.sessionToken,
                expireTime: AWS.config.credentials.expireTime
            })
        }
    });
}

function composeCognitoUser(email) {
    if (!cognitoUserPoolId) {
        throw new Error('Cognito user pool ID not specified to sign in.')
    }
    if (!cognitoAppClientId) {
        throw new Error('Cognito app client ID not specified to sign in.')
    }
    const cognitoPull = new CognitoUserPool({
        UserPoolId: cognitoUserPoolId,
        ClientId: cognitoAppClientId
    })
    return new CognitoUser({
        Username: email,
        Pool: cognitoPull
    });
}

function composeSessionCredentials(session, credentials, email) {
    if (credentials.error) {
        throw credentials.error
    }
    const permission = {}
    const permissionString = get(session, ["idToken","payload","console:permission"])
    if (permissionString) {
        const items = permissionString.split(",")
        for (const item of items) {
            const elements = item.split(":")
            if (elements.length === 2) {
                permission[elements[0]] = elements[1]
            }
        }
    }
    setCognitoCredentials({
        ...credentials,
        username: email,
        permission
    })
    return {
        ...session,
        credentials,
        permission
    }
}

export async function refreshCognitoSession({email, refreshToken}) {
    return new Promise((resolve, reject) => {
        if (!refreshToken) {
            return reject(new Error('Cognito refresh token not defined to refresh session.'))
        }
        const getCognitoUser = (email) => {
            try {
                return composeCognitoUser(email)
            } catch (err) {
                return reject(err)
            }
        }
        const cognitoUser = getCognitoUser(email)
        log.debug(`Refreshing user [${email}] session.`)
        cognitoUser.refreshSession(new CognitoRefreshToken({RefreshToken: refreshToken}), (err, session) =>{
            if (err) {
                return reject(err)
            }
            if (!session || !session.idToken || !session.idToken.jwtToken) {
                return reject(new Error('Cognito idToken not defined to refresh session credentials.'))
            }
            getCognitoIdentityCredentials(session.idToken.jwtToken, (credentials) => {
                try {
                    return resolve(composeSessionCredentials(session, credentials, email))
                } catch (err) {
                    return reject(err)
                }
            })
        })
    })
}

function getSessionCredentials(session, rememberMe, resolve, reject) {
    if (!session || !session.idToken || !session.idToken.jwtToken) {
        return reject(new Error('Cognito idToken not found to sign in.'))
    }
    const email = get(session, 'idToken.payload.email');
    getCognitoIdentityCredentials(session.idToken.jwtToken, (credentials) => {
        try {
            if (rememberMe) {
                const refreshToken = get(session, 'refreshToken.token');
                if (refreshToken) {
                    saveUser(email, refreshToken)
                }
            }
            return resolve(composeSessionCredentials(session, credentials, email))
        } catch (err) {
            return reject(err)
        }
    })
}

export async function sessionSignIn({session, rememberMe}) {
    return new Promise((resolve, reject) => {
        getSessionCredentials(session, rememberMe, resolve, reject)
    })
}

export async function cognitoSignIn({email, password, newPassword, verificationCode, rememberMe}) {
    return new Promise((resolve, reject) => {
        const getCognitoUser = (email) => {
            try {
                return composeCognitoUser(email)
            } catch (err) {
                return reject(err)
            }
        }
        const getCredentials = (session) => {
            getSessionCredentials(session, rememberMe, resolve, reject)
        }
        const authenticateUser = (email, password) => {
            const authenticationData = {
                Username : email,
                Password : password,
            };
            const authenticationDetails = new AuthenticationDetails(authenticationData);
            const cognitoUser = getCognitoUser(email)
            log.debug(`Authenticate user [${email}].`)
            cognitoUser.authenticateUser(authenticationDetails, {
                onSuccess: getCredentials,
                onFailure: function(err) {
                    if (err.code === "PasswordResetRequiredException") {
                        if (verificationCode && newPassword) {
                            confirmPassword(cognitoUser)
                        } else {
                            log.debug("Password reset required.")
                            return resolve({passwordResetRequired: true})
                        }
                    } else {
                        return reject(err)
                    }
                },
                newPasswordRequired: function (userAttributes, requiredAttributes) {
                    if (newPassword) {
                        completeNewPasswordChallenge(cognitoUser)
                    } else {
                        log.debug("New password required.")
                        return resolve({userAttributes, requiredAttributes, newPasswordRequired: true})
                    }
                }
            })
        }
        const completeNewPasswordChallenge = (cognitoUser) => {
            log.debug("New password challenge.")
            cognitoUser.completeNewPasswordChallenge(newPassword, [], {
                onSuccess: () => authenticateUser(email, newPassword),
                onFailure: function (err) {
                    return reject(err)
                }
            })
        }
        const confirmPassword = (cognitoUser) => {
            log.debug("New password confirmation.")
            cognitoUser.confirmPassword(verificationCode, newPassword, {
                onSuccess: () => authenticateUser(email, newPassword),
                onFailure: function (err) {
                    log.debug("Error on new password confirmation.")
                    return reject(err)
                }
            })
        }
        authenticateUser(email, password)
    })
}

export async function getMetaData() {
    log.info('Loading metadata.')
    return await invokeLambda('MetaDataLambda', {})
}

export async function encryptWithVaultLambda(valueToEncrypt, token) {
    const command = {
        action: 'ENCRYPT',
        clearText: valueToEncrypt
    }
    if (token) {
        command.token = token
    }
    const data = await invokeLambda('VaultLambda', {
        commands: [command]
    });
    if (data.commands && data.commands.length === 1) {
        const token = data.commands[0].token;
        log.debug(`Data encrypted to token [${token}].`);
        return token;
    }
}

export async function decryptWithVaultLambda(tokenToDecrypt) {
    const data = await invokeLambda('VaultLambda', {
        commands: [
            {
                action: 'DECRYPT',
                token: tokenToDecrypt
            }
        ]
    });
    if (data.commands && data.commands.length === 1) {
        return data.commands[0].clearText;
    } else {
        throw new Error(`Unable to decrypt token [${tokenToDecrypt}]`);
    }
}

export async function getConfigWithVaultLambda(tokens) {
    const commands = tokens.map(token => {
        return {
            action: 'GET_CONFIG',
            token
        }
    })
    const data = await invokeLambda('VaultLambda', {commands});
    if (!data.commands) {
        throw new Error("Commands not found in VaultLambda response.")
    }
    return new Map(
        data.commands.map(command => {
            return [command.token, command.clearText];
        }),
    );
}

export async function getRecentPartnerMerchants({limit= 1}) {
    const payload = {action: 'GET', limit, recentMerchants: true}
    const response = await invokeLambda('AccountLambda', payload);
    if (!response.merchants) {
        throw new Error("Merchants not found, probably you use previous AccountLambda version.")
    }
    return response.merchants;
}

export async function getRecentLoans({status = 'PENDING',limit = 10}) {
    let loans = [];
    const result = await invokeLambda('LoanApplicationQueryLambda', {status, limit});
    if (result.applications) {
        for (const item of result.applications) {
            loans.push({id: item.id, createdAt: formatUT(item.createdAt, 'yyyy-MM-dd'), status: item.status})
        }
    }
    return loans
}

export async function getLoanInfo({loanApplicationId, dataTypes, decrypt=true}) {
    return await invokeLambda('LoanQueryDataLambda', {
        loanApplicationId,
        dataTypes,
        decrypt
    });
}

export async function getSignupInfo({signupId}) {
    if (!signupId) {
        throw new Error("Signup ID not specified to get signup data.");
    }
    const response = await invokeLambda('SignupGetToolLambda', {signupId});
    if (response.errorMessage) {
        throw new Error(response.errorMessage);
    }
    return response;
}

export async function getPartnersInfo({id, name, limit}) {
    const payload = {action: 'GET', limit}
    if (id || name) {
        const account = {}
        if(id) {
            account.id = id;
        }
        if(name) {
            account.name = name;
        }
        payload.accounts = [account]
    }
    return await invokeLambda('AccountLambda', payload);
}

export async function getIndustryList({name, vertical, limit}) {
    const payload = {
        action: 'GET',
        name,
        vertical,
        limit
    }
    return await invokeLambda('IndustryLambda', payload);
}

export async function updateIndustry({name, vertical}) {
    if (!name) {
        throw new Error("Industry name required!")
    }
    if (!vertical) {
        throw new Error("Vertical required!")
    }
    const payload = {
        action: 'UPDATE',
        industryList: [{
            name,
            vertical
        }]
    }
    return await invokeLambda('IndustryLambda', payload);
}

export async function removeIndustry({name}) {
    if (!name) {
        throw new Error("Industry name required!")
    }
    const payload = {
        action: 'REMOVE',
        name
    }
    return await invokeLambda('IndustryLambda', payload);
}

export async function deleteRefund(data) {
    const payload = {
        "lambdaAction": "REMOVE",
        ...data,
    }

    return await invokeLambda("RefundLambda", payload)
}

export async function getMerchantsInfo({id, name, accountId, federalEIN, limit}) {
    const payload = {action: 'GET', decrypt: true, limit}
    if (id || name || accountId || federalEIN) {
        const merchant = {}
        if(id) {
            merchant.id = id;
        }
        if(name) {
            merchant.businessLegalName = name;
        }
        if(accountId) {
            merchant.accountId = accountId;
        }
        if(federalEIN) {
            merchant.federalEINEncrypted = federalEIN;
        }
        payload.merchants = [merchant]
    }
    return await invokeLambda('MerchantOnboardingLambda', payload);
}

export async function updateMerchant(data) {
    const cleanData = transformMerchantData(data)
    const payload = {action: 'UPDATE', updateInsightly: true, merchants: [cleanData]}
    const response = await invokeLambda('MerchantOnboardingLambda', payload);
    if (response && response.errorMessage) {
        throw new Error(response.errorMessage);
    }
    if (response && response.merchants && response.merchants.length === 1) {
        const updatedData = await getMerchantsInfo({id: response.merchants[0].id})
        if (updatedData.merchants && updatedData.merchants.length === 1) {
            return updatedData.merchants[0];
        } else {
            throw new Error(`Unexpected response when getting updated merchant: ${JSON.stringify(updatedData, null, 2)}`);
        }
    }
    throw new Error(`Unexpected response when updating merchant: ${JSON.stringify(response, null, 2)}`);
}

function transformMerchantData(data) {
    let cleanData = {}
    Object.entries(data).forEach(([key, value]) => {
        if(value || value === false) {
            cleanData[key] = value
        }
    })
    if (cleanData.maxPayoutsNumber && !/^([1-9]\d*|0)$/.test(cleanData.maxPayoutsNumber)) {
        throw new Error("Invalid max payout number, should be positive integer or zero.")
    }
    return cleanData;
}

function transformPartnerData(data) {
    if (!data.name) {
        throw new Error("Name not specified.")
    }
    if (!data.alias) {
        throw new Error("Alias not specified.")
    }
    if (!data.financialKB) {
        throw new Error("Financial KB not specified.")
    }
    if (!data.defaultVertical) {
        throw new Error("Default vertical not specified.")
    }
    if (!data.supportedPlans) {
        throw new Error("Supported product plans not specified.")
    }
    const defaultPlan = data.supportedPlans[data.defaultVertical]
    if (!defaultPlan || defaultPlan.length === 0) {
        throw new Error("Product plan for default vertical not specified.")
    }
    if (!data.keyEncrypted) {
        delete data['keyEncrypted']
    }
    if (!data.maxPayoutsNumber) {
        delete data['maxPayoutsNumber']
    } else if (!/^([1-9]\d*|0)$/.test(data.maxPayoutsNumber)) {
        throw new Error("Invalid max payout number, should be positive integer or zero.")
    }
}

export async function createPartner(data) {
    transformPartnerData(data);
    const payload = {action: 'CREATE', accounts: [data]}
    const response = await invokeLambda('AccountLambda', payload);
    if (response && response.errorMessage) {
        throw new Error(response.errorMessage);
    }
    if (response && response.accounts && response.accounts.length === 1) {
        return response.accounts[0];
    }
    throw new Error(`Unexpected response when creating account: ${JSON.stringify(response, null, 2)}`);
}

export async function updatePartner(data) {
    if (!data.id) {
        throw new Error("ID to update account not specified.")
    }
    transformPartnerData(data);
    const payload = {action: 'UPDATE', accounts: [data]}
    const response = await invokeLambda('AccountLambda', payload);
    if (response && response.errorMessage) {
        throw new Error(response.errorMessage);
    }
    if (response && response.accounts && response.accounts.length === 1) {
        return response.accounts[0];
    }
    throw new Error(`Unexpected response when updating account: ${JSON.stringify(response, null, 2)}`);
}

export async function deletePartner(data) {
    if (!data.id) {
        throw new Error("ID to delete account not specified.")
    }
    const payload = {action: 'REMOVE', accounts: [data]}
    const response = await invokeLambda('AccountLambda', payload);
    if (response && response.errorMessage) {
        throw new Error(response.errorMessage);
    }
    return data;
}

export async function createAuditTrail(eventType, entityType, entityId, data) {
    const {clientContext} = await getAWSContext();
    if (clientContext) {
        const auditTrail = {
            userName: clientContext.username,
            osName: clientContext.os,
            tz: clientContext.timezone,
            applicationName: appName,
            eventType,
            entityType,
            entityId,
            data
        }
        return auditTrailLambda({auditTrail},'CREATE')
    } else {
        log.error('AWS client context not found to create audit trail record.')
    }
}

export async function getAuditTrail({userName, eventDate, eventType, entityType, entityId, from, to, limit}) {
    const auditTrail = {}
    if (userName) {
        auditTrail.userName = userName
    }
    if (eventType) {
        auditTrail.eventType = eventType
    }
    if (eventDate) {
        auditTrail.eventDate = eventDate
    }
    if (entityType) {
        auditTrail.entityType = entityType
    }
    if (entityId) {
        auditTrail.entityId = entityId
    }
    return auditTrailLambda({
        auditTrail,
        from,
        to,
        limit
    },'GET')
}

export async function metaAuditTrail() {
    return auditTrailLambda({},'METADATA')
}

async function auditTrailLambda({auditTrail, from, to, limit}, action) {
    return await invokeLambda('AuditTrailLambda', {
        action,
        auditTrail,
        from,
        to,
        limit
    });
}

const invokeLambda = async (functionName, payload) => {
    const {lambda, clientContext} = await getLambdaContext();
    return new Promise((resolve, reject) => {
        if (!lambda || !clientContext) {
            return reject(new Error(`AWS context not defined to invoke lambda [${functionName}].`))
        }
        const params = {
            FunctionName: functionName,
            Qualifier: 'LIVE',
            InvocationType: "RequestResponse",
            Payload: JSON.stringify(payload),
            ClientContext: Buffer.from(JSON.stringify(clientContext)).toString("base64")
        };
        log.debug(`-<L>- ${functionName}`)
        lambda.invoke(params, (err, data) => {
            if (err) {
                return reject(err)
            } else if (data.StatusCode !== 200) {
                return reject(new Error(`Invalid status code [${data.StatusCode}] received on lambda [${functionName}] invoke.`));
            } else {
                if (data.Payload) {
                    const payload = JSON.parse(data.Payload);
                    if (payload.errorMessage) {
                        log.error(JSON.stringify(payload, null, 2));
                        return reject(new Error(payload.errorMessage))
                    }
                    return resolve(payload);
                }
                return reject(new Error(`Invalid payload received on lambda [${functionName}] invoke.`))
            }
        });
    })
}

export async function putItemToDB(item) {
    const params = {
        TableName: 'wisetack',
        Item: item
    };
    const {config} = await getAWSContext();
    return new Promise((resolve, reject) => {
        if (!config) {
            return reject(new Error('AWS context not defined to save to DynamoDB.'))
        }
        const docClient = new AWS.DynamoDB.DocumentClient({
            ...config,
            dynamoDbCrc32: false
        });
        docClient.put(params, function(err, data) {
            if (err) {
                return reject(err)
            } else {
                log.debug(data);
                return resolve(data)
            }
        })
    })
}

async function getRecordFromDB(hashKey, rangeKey) {
    const params = {
        TableName: 'wisetack',
        Key: {hashKey, rangeKey}
    };
    await createAuditTrail('QUERY_DB', 'DATA', null, {params: JSON.stringify(params)})
    const {config} = await getAWSContext();
    return new Promise((resolve, reject) => {
        if (!config) {
            return reject(new Error('AWS context not defined to query DynamoDB.'))
        }
        const docClient = new AWS.DynamoDB.DocumentClient({
            ...config,
            dynamoDbCrc32: false
        });
        log.debug(`DynamoDB get [${JSON.stringify(params)}]`)
        docClient.get(params, function (err, data) {
            if (err) {
                return reject(err)
            } else {
                if (data.Item) {
                    return resolve(data.Item)
                }
                return reject(new Error(`Record [${hashKey}:${rangeKey}] not found in DB.`))
            }
        })
    })
}

async function getAccountFromDB(accountId) {
    const record = await getRecordFromDB(accountId, 'ACCOUNT');
    if (!record) {
        throw new Error(`Account [${accountId}] not found.`);
    }
    return JSON.parse(record.account);
}

async function getMerchantFromDB(merchantId) {
    const record = await getRecordFromDB(merchantId, 'MERCHANT');
    if (!record) {
        throw new Error(`Merchant [${merchantId}] not found.`);
    }
    return JSON.parse(record.merchant);
}

export async function getMerchantAccessToken(merchantId) {
    if (!merchantId) {
        throw new Error('Merchant ID not specified to get access token.');
    }
    const response = await invokeLambda('AuthTokenLambda', {merchantId});
    return response.token;
}

export async function getPartnerAccessToken(accountId) {
    if (!accountId) {
        throw new Error('Account ID not specified to get access token.');
    }
    const response = await invokeLambda('AuthTokenLambda', {accountId});
    return response.token;
}

export async function signupRepair(payload) {
    return await invokeLambda('SignupRepairLambda', payload);
}

export async function getAlloyData({alloyEntityToken, entityId, force}) {
    const payload = {force}
    if (alloyEntityToken) {
        payload.entityTokens = [alloyEntityToken]
    }
    if (entityId) {
        payload.ids = [entityId]
    }
    return await invokeLambda('MerchantAlloyLambda', payload);
}

export async function createPrequalLinkManual({merchantId, prequalId, localHost, decline}) {
    let url;
    if (localHost) {
        url = 'http://localhost:3000'
    } else {
        const profile = await getProfileName();
        const protocol = (profile === 'wisetack' || profile === 'wisetack_sec') ? 'https' : 'http';
        url = `${protocol}://${profile}.us`;
    }
    const merchant = await getMerchantFromDB(merchantId);
    if (!merchant) {
        throw new Error(`Merchant ${merchantId} not found.`)
    }
    const account = await getAccountFromDB(merchant.accountId);
    if (!account.keyEncrypted) {
        throw new Error(`Account [${merchant.accountId}] encrypted key not found.`);
    }
    const secretKey = await decryptWithVaultLambda(account.keyEncrypted);
    const signupId = merchant.signupId;
    const sha = crypto.createHash('sha1');
    sha.update(secretKey + signupId + prequalId);
    const checksum = sha.digest('hex').substr(-8);
    if (decline) {
        return `${url}/?decline=true#/${signupId}/${prequalId}/${checksum}`
    }
    const prequalLink = `${url}/#/${signupId}/${prequalId}/${checksum}`
    await createAuditTrail('CREATE_PREQUAL_LINK','PREQUAL_APPLICATION', null, {
        prequalLink
    })
    return prequalLink
}

export async function prequalsExpire(data) {
    return await invokeLambda('PrequalExpirationLambda', data)
}

export async function invokeDisbursement(dateTo) {
    const data = await invokeLambda('LoanDisbursementLambda', {
        dateTo
    });
    if (data) {
        // string should be returned
        return data;
    } else {
        throw Error('No disbursement result info returned.')
    }
}

export async function loanOriginationLambda(payload) {
    const data = await invokeLambda('LoanOriginationLambda', payload);
    if (data) {
        // string should be returned
        return data;
    } else {
        throw Error('No origination result info returned.')
    }
}

export async function getPrequalsList({applicationId, prequalId, phoneNumber, merchantId, date, status, limit}) {
    return await invokeLambda('PrequalListLambda', {
        applicationId,
        prequalId,
        phoneNumber: formatMobileNumberForApi(phoneNumber),
        merchantId,
        date,
        status,
        limit
    });
}

export async function getBorrowerCreditFileList(data) {
    return borrowerCreditFile(data, "GET");
}

export async function cancelBorrowerCreditFile(data) {
    return borrowerCreditFile(data, "CANCEL");
}

export async function restoreBorrowerCreditFile(data) {
    return borrowerCreditFile(data, "RESTORE");
}

export async function removeBorrowerCreditFile(data) {
    return borrowerCreditFile(data, "REMOVE");
}

async function borrowerCreditFile({applicationId, applicationType, mobileNumber, merchantId, actionDate, limit}, action) {
    return await invokeLambda('CreditReportLambda', {
        action,
        applicationId,
        applicationType,
        mobileNumber: formatMobileNumberForApi(mobileNumber),
        merchantId,
        actionDate,
        limit
    });
}

export async function getBorrowerProfileList(data) {
    return borrowerProfile(data, "GET");
}

export async function cancelBorrowerProfile(data) {
    return borrowerProfile(data, "CANCEL");
}

export async function restoreBorrowerProfile(data) {
    return borrowerProfile(data, "RESTORE");
}

export async function removeBorrowerProfile(data) {
    return borrowerProfile(data, "REMOVE");
}

async function borrowerProfile({mobileNumber, actionDate, limit, cancelPrequalApplication, cancelLoanApplication}, action) {
    return await invokeLambda('BorrowerProfileLambda', {
        action,
        mobileNumber: formatMobileNumberForApi(mobileNumber),
        actionDate,
        limit,
        cancelPrequalApplication,
        cancelLoanApplication,
        toDecrypt: true
    });
}

export async function getDocumentsInfo({id, entityId, limit}) {
    const payload = {action: 'GET', decrypt: true, contentless: true, limit}
    if (id) {
        payload.ids = [id]
    } else if (entityId) {
        payload.documents = [{entityId}]
    }
    return await invokeLambda('DocumentLambda', payload);
}

export async function getDocumentContent({id, entityId}) {
    if (!id && !entityId) {
        throw new Error("Document ID or entity ID not specified to get document content.");
    }
    const payload = {action: 'GET', documents: [{entityId, id}], decrypt: true, merge: !!entityId}
    return await invokeLambda('DocumentLambda', payload);
}

export async function createLegacySignup({merchantId}) {
    const payload = {action: 'LEGACY', ids: [merchantId]}
    const response = await invokeLambda('MerchantOnboardingLambda', payload);
    if (!response.ids || response.ids.length !== 1) {
        throw new Error("Signup record was not created. See logs for more details.")
    }
    return {
        merchantId,
        signupId: response.ids[0]
    }
}

export async function getCRMTaskId(loanApplicationId) {
    const params = {
        TableName: 'wisetack',
        IndexName: 'gsi6',
        KeyConditionExpression: 'gsi6HashKey = :hKey and gsi6RangeKey = :rKey',
        ExpressionAttributeValues: {
            ':hKey': 'CRM#REVIEW',
            ':rKey': loanApplicationId
        }
    };
    await createAuditTrail('QUERY_DB', 'DATA', null, {params: JSON.stringify(params)})
    const {config} = await getAWSContext();
    return new Promise((resolve, reject) => {
        if (!config) {
            return reject(new Error('AWS context not defined to query DynamoDB.'))
        }
        const docClient = new AWS.DynamoDB.DocumentClient({
            ...config,
            dynamoDbCrc32: false
        });
        log.debug(`DynamoDB query [${JSON.stringify(params)}]`)
        docClient.query(params, function (err, data) {
            if (err) {
                return reject(err)
            } else {
                if (data.Items && data.Items.length > 0) {
                    return resolve(data.Items[0].hashKey);
                }
                return reject(new Error(`CRM task ID for loan application [${loanApplicationId}] not found.`))
            }
        });
    })
}

export async function getUsersInfo({merchantId, phone, email, limit}) {
    const payload = {action: 'GET', limit}
    const user = {}
    if (merchantId) {
        user.merchantId = merchantId;
    }
    if (phone) {
        user.phoneNumberEncrypted = formatMobileNumberForApi(phone);
    }
    if (email) {
        user.emailEncrypted = email;
    }
    if (Object.keys(user).length === 0) {
        throw new Error("Parameters to find user not specified.")
    }
    if (Object.keys(user).length > 1) {
        throw new Error("Only one parameter to find user should be specified.")
    }
    payload.users = [user]
    const response = await invokeLambda('MerchantUserOnboardingLambda', payload);
    if (response && response.errorMessage) {
        throw new Error(response.errorMessage);
    }
    return response;
}

export async function updateUser(data) {
    checkUserData(data);
    const payload = {action: 'UPDATE', users: [data], ignoreDuplicates: false, updateInsightly: true}
    const response = await invokeLambda('MerchantUserOnboardingLambda', payload);
    if (response && response.errorMessage) {
        throw new Error(response.errorMessage);
    }
    if (response && response.ids && response.ids.length === 1 && response.ids[0] === data.userId) {
        return data;
    }
    throw new Error(`Unexpected response when updating user: ${response}`);
}

export async function getRefunds(data) {

    if (!data.loanApplicationId) {
        throw new Error("Loan application id is not provided.")
    }
    const payload = {lambdaAction: 'GET', ...data}

    const response = await invokeLambda('RefundLambda', payload);
    if (response && response.errorMessage) {
        throw new Error(response.errorMessage);
    }

    return response;
}

export async function deleteUser(data) {
    if (!data.merchantId || !data.userId) {
        throw new Error("ID to delete user not specified.")
    }
    const payload = {action: 'REMOVE', users: [data], updateInsightly: true}
    const response = await invokeLambda('MerchantUserOnboardingLambda', payload);
    if (response && response.errorMessage) {
        throw new Error(response.errorMessage);
    }
    return data;
}

export async function createUser(data) {
    checkUserData(data);
    const payload = {action: 'CREATE', users: [data], updateInsightly: true}
    const response = await invokeLambda('MerchantUserOnboardingLambda', payload);
    if (response && response.errorMessage) {
        throw new Error(response.errorMessage);
    }
    if (response && response.ids && response.ids.length === 1) {
        data.userId = response.ids[0];
        return data;
    }
    throw new Error(`Unexpected response when creating user: ${response}`);
}

export async function usersLockout(payload) {
    if (!payload.userNameHash && payload.userName && payload.userName[0] !== '+') {
        payload.userNameHash = payload.userName;
    }
    return await invokeLambda('MerchantUserLockoutLambda', payload);
}

export async function loanLockout(payload) {
    return await invokeLambda('LoanApplicationLockoutLambda', payload);
}

export async function reviewMerchantLambda(payload) {
    return await invokeLambda('SignupAlloyWebhookLambda', payload);
}

export async function ACHPaymentLambda(payload) {
    const params = {...payload}
    params.action = 'GET'
    if (!params.loanApplicationId) {
        delete params.loanApplicationId
    }
    if (!params.creationDate) {
        delete params.creationDate
    }
    if (!params.loanApplicationId && !params.creationDate) {
        throw new Error("Please specify ID or creation date.")
    }
    return await invokeLambda('ACHPaymentLambda', params);
}

export async function boardingFileLambda(payload) {
    const params = {...payload}
    params.action = 'GET'
    if (!params.originationTo) {
        delete params.originationTo
    }
    return await invokeLambda('BoardingFileLambda', params);
}

export async function reportGenerationLambda(payload) {
    const params = {...payload}
    params.action = 'GET'
    if (!params.dateOn) {
        delete params.dateOn
    }
    return await invokeLambda('ReportGenerationLambda', params);
}

export async function refundLoanApplicationLambda(payload) {
    const refundLoanApplicationCrmDetails = {...payload}
    delete refundLoanApplicationCrmDetails.loanApplicationId
    const params = {
        refundLoanApplicationCrmDetails,
        loanApplicationId: payload.loanApplicationId
    }
    const response = await invokeLambda('RefundLoanApplicationLambda', params);
    if (!response.success && response.message) {
        throw new Error(response.message)
    }
    return response;

}

export async function concurrentLoansLambda(payload) {
    payload.phoneNumber = formatMobileNumberForApi(payload.phoneNumber)
    return await invokeLambda('ConcurrentLoansLambda', payload);
}

export async function loanCancellationLambda(payload) {
    return await invokeLambda('LoanCancellationLambda', payload);
}

export async function holdLoanApplicationLambda(payload) {
    return await invokeLambda('HoldLoanApplicationLambda', payload);
}

export async function loanPayoutCancel(payload) {
    return loanPayoutUpdateLambda(payload, 'PAYOUT_CANCELLATION');
}

export async function loanPayoutHold(payload) {
    return loanPayoutUpdateLambda(payload, 'PAYOUT_HOLD');
}

async function loanPayoutUpdateLambda(payload, mode) {
    const params = {...payload}
    params.mode = mode
    return await invokeLambda('LoanPayoutUpdateLambda', params);
}

export async function loanPayoutQueryLambda(payload) {
    const params = {...payload}
    if (params.id) {
        if (params.mode === 'PAYOUT') {
            params.ids = [params.id]
        } else if (params.mode === 'LOAN') {
            params.loanApplicationId = params.id
        } else if (params.mode === 'MERCHANT') {
            params.merchantId = params.id
        }
        delete params.id;
    }
    if (!params.status) {
        delete params.status
    }
    if (!params.dateOn) {
        delete params.dateOn
    } else {
        params.dateOn = formatUT(params.dateOn, 'yyyy-MM-dd')
    }
    return await invokeLambda('LoanPayoutQueryLambda', params);
}

export async function loanApplicationQueryLambda(params) {
    const payload = {...params}
    if (params.active === 'ACTIVE') {
        payload.active = true
    } else if (params.active === 'IDLE') {
        payload.active = false
    } else {
        delete payload.active
    }
    payload.phoneNumber = formatMobileNumberForApi(payload.phoneNumber)
    return await invokeLambda('LoanApplicationQueryLambda', payload);
}

export async function getConsolePermissionLambda(params) {
    const payload = {...params}
    if (!payload.subjectId) {
        delete payload.subjectId
    }
    if (!payload.limit) {
        delete payload.limit
    }
    return await invokeLambda('GetConsolePermissionLambda', payload);
}

export async function setConsolePermissionLambda(params) {
    if (!params.subjectId) {
        throw new Error("Subject ID not specified.")
    }
    const payload = {}
    if (params.delete) {
        payload.delete = true
        payload.permission = {
            subjectId: params.subjectId
        }
    } else {
        const permission = {...params}
        if (!permission.subjectType) {
            throw new Error("Subject type not specified.")
        }
        if (!permission.parentSubjectId) {
            delete permission.parentSubjectId
        }
        if (!permission.iamRole) {
            delete permission.iamRole
        }
        payload.permission = permission
    }
    return await invokeLambda('SetConsolePermissionLambda', payload);
}

function checkUserData(data) {
    if (!data) {
        throw new Error("User data not specified.")
    }
    if (!data.merchantId) {
        throw new Error("Merchant ID not specified.")
    }
    if (!data.phoneNumberEncrypted) {
        throw new Error("Phone number not specified.")
    }
    if (!data.emailEncrypted) {
        throw new Error("Email not specified.")
    }
}

export async function documentAccept({documentId, acceptanceDate}) {
    if (!documentId) {
        throw new Error("Document ID not specified to accept.");
    }
    return await invokeLambda('DocumentAcceptanceLambda', {documentId, acceptanceDate});
}

export async function queryDynamoDB({hashKey, rangeKey, rangeKeyCondition, indexName, scanIndexForward, limit, full}) {
    if (!hashKey) {
        throw new Error('Hash key value not specified.')
    }
    if (!rangeKeyCondition) {
        rangeKeyCondition = '='
    }
    if (!indexName) {
        indexName = 'main'
    }
    let items = [];
    let hashKeyName = 'hashKey'
    let rangeKeyName = 'rangeKey'
    if (indexName === 'gsimirror') {
        hashKeyName = 'mirrorHashKey'
        rangeKeyName = 'mirrorRangeKey'
    } else if (indexName !== 'main') {
        hashKeyName = indexName + 'HashKey'
        rangeKeyName = indexName + 'RangeKey'
    }
    let keyCondition = `${hashKeyName} = :hKey`
    if (rangeKey) {
        if (rangeKeyCondition === 'begins_with') {
            keyCondition = keyCondition + ` and begins_with ( ${rangeKeyName}, :rKey )`;
        } else {
            keyCondition = keyCondition + ` and ${rangeKeyName} ${rangeKeyCondition} :rKey`;
        }
    }
    const params = {
        TableName: 'wisetack',
        IndexName: indexName === 'main' ? null : indexName,
        Limit: limit,
        ScanIndexForward: scanIndexForward,
        KeyConditionExpression: keyCondition,
        ExpressionAttributeValues: {
            ':hKey': hashKey
        }
    }
    if (rangeKey) {
        params.ExpressionAttributeValues[':rKey'] = rangeKey;
    }
    await createAuditTrail('QUERY_DB', 'DATA', null, {params: JSON.stringify(params)})
    const {config} = await getAWSContext();
    return new Promise((resolve, reject) => {
        if (!config) {
            return reject(new Error('AWS context not defined to query DynamoDB.'))
        }
        const docClient = new AWS.DynamoDB.DocumentClient({
            ...config,
            dynamoDbCrc32: false
        });
        log.debug(`DynamoDB query [${JSON.stringify(params)}]`)
        docClient.query(params, async function (err, data) {
            if (err) {
                return reject(err)
            } else if (data.Items) {
                try {
                    if (indexName === 'main' || !full) {
                        for (const item of data.Items) {
                            items.push(convertDBRecord(item))
                        }
                    } else {
                        for (const item of data.Items) {
                            items.push(convertDBRecord(await getRecordFromDB(item.hashKey, item.rangeKey)))
                        }
                    }
                } catch (err) {
                    reject(err)
                }
            }
            return resolve(items);
        });
    })
}

function convertDBRecord(record) {
    Object.entries(record).forEach(([key, value]) => {
        if(isString(value) && value.startsWith("{")) {
            record[key] = JSON.parse(value);
        }
    });
    return record
}

const sleep = (delay) => new Promise((resolve) => setTimeout(resolve, delay));

async function getCloudWatchClient(production, region) {
    const {config} = await getAWSContext(production, region);
    return new AWS.CloudWatchLogs(config);
}

async function getAthenaClient(production) {
    const {config} = await getAWSContext(production);
    return new AWS.Athena(config);
}

export async function cloudWatchInsightsQuery({queryId, logGroupName, queryString, limit, startTime, endTime, production, region}) {
    if (!queryString) {
        throw new Error("Insights query not specified.")
    }
    log.debug(`Submitting Insights query [${queryString}]\n[${startTime} - ${endTime}]`);
    await createAuditTrail('QUERY_CLOUDWATCH_LOGS', 'DATA', null, {queryId, logGroupName, queryString, limit, startTime, endTime, production, region})
    const now = Date.now()/1000
    const sT = !!startTime ? startTime.getTime()/1000 : now - 60 * 15;
    const eT = !!endTime ? endTime.getTime()/1000 : now;
    const client = await getCloudWatchClient(production, region);
    if (!queryId) {
        const params = {
            logGroupName,
            queryString,
            limit,
            startTime: sT,
            endTime: eT,
        };
        queryId = await startInsightsQuery(client, params);
    }
    let response = {status: 'Running'};
    while (response.status === 'Running') {
        await sleep(1000);
        response = await getInsightsQueryResults(client, queryId);
    }
    if (response.results && response.results.length > 0) {
        response.results = response.results.map((fields) => {
            const record = {}
            fields.forEach((item) => {
                record[item.field] = item.value;
            })
            return record;
        })
    }
    if (response.statistics) {
        response.statistics.startTime = new Date(sT * 1000);
        response.statistics.endTime = new Date(eT * 1000);
        response.statistics.region = client.config.region;
        response.statistics.profile = client.config.credentials.profile;
        response.statistics.production = production;
        log.debug(JSON.stringify(response.statistics, null, 2));
    }
    return response;
}

async function startInsightsQuery(client, params) {
    return new Promise((resolve, reject) => {
        client.startQuery(params, function(err, data) {
            if (err) {
                reject(err);
            }
            else {
                if(!data || !data.queryId) {
                    reject("Unexpected 'startQuery' response.");
                }
                resolve(data.queryId);
            }
        });
    })
}

async function getInsightsQueryResults(client, queryId) {
    return new Promise((resolve, reject) => {
        client.getQueryResults({queryId}, function(err, data) {
            if (err) {
                reject(err);
            } else {
                if (!data || !data.status) {
                    reject("Unexpected 'getQueryResults' response.");
                }
                resolve(data);
            }
        })
    })
}

export async function cloudWatchLogGroups({production, region, logGroupNamePrefix}) {
    const logGroups = []
    let nextToken = null;
    do {
        const response = await cloudWatchLogGroupsRaw({production, region, logGroupNamePrefix, nextToken, limit: 50})
        if (response.logGroups) {
            Array.prototype.push.apply(logGroups, response.logGroups)
        }
        nextToken = response.nextToken
    } while (nextToken)
    return {logGroups}
}

async function cloudWatchLogGroupsRaw({production, region, limit, logGroupNamePrefix, nextToken}) {
    const client = await getCloudWatchClient(production, region);
    return new Promise((resolve, reject) => {
        const params = {
            limit,
            logGroupNamePrefix,
            nextToken
        }
        client.describeLogGroups(params, function(err, data) {
            if (err) {
                reject(err);
            }
            else {
                resolve(data);
            }
        })
    });
}

export async function cloudWatchDescribeQueries({production, region, limit}) {
    const client = await getCloudWatchClient(production, region);
    return new Promise((resolve, reject) => {
        const params = {maxResults: limit}
        client.describeQueries(params, function(err, data) {
            if (err) {
                reject(err);
            }
            else {
                resolve(data);
            }
        })
    });
}

export async function dataLakeQuery({query, template, production}) {
    const rows = await executeDataLakeQuery(query, production);
    if (!rows || rows.length < 2) {
        throw new Error(`Data not found.`)
    }
    let result = [];
    const fieldNames = rows[0].Data.map(item => item.VarCharValue);
    for (const row of rows.slice(1)) {
        let item = {}
        row.Data.forEach((field, index) => {
            if (field.VarCharValue) {
                const fieldName = fieldNames[index];
                let value = field.VarCharValue;
                if (value.startsWith('{')) {
                    value = JSON.parse(value);
                }
                item[fieldName] = value;
            }
        })
        result.push(item)
    }
    return {result, query, template};
}

async function executeDataLakeQuery(QueryString, production) {
    log.info(`Starting query \n${QueryString}`)
    await createAuditTrail('QUERY_DATA_LAKE', 'DATA', null, {QueryString, production})
    const athena = await getAthenaClient(production);
    const {QueryExecutionId} = await startQueryExecution(QueryString, athena);
    if (!QueryExecutionId) {
        throw new Error('Data Lake query execution ID not returned.')
    }
    let state = 'RUNNING';
    let QueryExecution = null;
    while (state === 'RUNNING') {
        await sleep(2000);
        const result = await getQueryExecution(QueryExecutionId, athena);
        if (!result || !result.QueryExecution) {
            break;
        }
        QueryExecution = result.QueryExecution;
        if (!QueryExecution.Status || !QueryExecution.Status.State) {
            break;
        }
        state = QueryExecution.Status.State;
    }
    if (state !== 'SUCCEEDED') {
        log.info(QueryExecution);
        throw new Error(`Data Lake query failed with status ${JSON.stringify(QueryExecution.Status, null, 2)}`);
    }
    const {ResultSet} = await getQueryResults(QueryExecutionId, athena);
    return ResultSet.Rows;
}

async function startQueryExecution(QueryString, athena) {
    return new Promise((resolve, reject) => {
        const OutputLocation = 's3://wisetack-us-east-1-data-mart/tmp';
        athena.startQueryExecution({
            QueryString,
            QueryExecutionContext: {
                Catalog: 'AwsDataCatalog',
                Database: 'wisetack_database'
            },
            ResultConfiguration: {
                OutputLocation,
                EncryptionConfiguration: {
                    EncryptionOption: 'SSE_S3'
                }
            },
            WorkGroup: 'AmazonAthenaPreviewFunctionality'
        }, function (err, data) {
            if (err) {
                reject(err)
            } else {
                resolve(data);
            }
        });
    })
}

async function getQueryExecution(QueryExecutionId, athena) {
    return new Promise((resolve, reject) => {
        athena.getQueryExecution({
            QueryExecutionId
        }, function (err, data) {
            if (err) {
                reject(err)
            } else {
                resolve(data);
            }
        });
    })
}

async function getQueryResults(QueryExecutionId, athena) {
    return new Promise((resolve, reject) => {
        athena.getQueryResults({
            QueryExecutionId
        }, function (err, data) {
            if (err) {
                reject(err)
            } else {
                resolve(data);
            }
        });
    })
}

export async function getDataLakeTableMetadata({CatalogName,DatabaseName,TableName}) {
    const athena = await getAthenaClient(true);
    return new Promise((resolve, reject) => {
        athena.getTableMetadata({
            CatalogName,
            DatabaseName,
            TableName
        }, function(err, data) {
            if (err) {
                reject(err)
            }
            else {
                resolve(data);
            }
        });
    })
}

export function createSignature(message, key) {
    const sha = crypto.createHash('sha256');
    sha.update(message + key);
    const signature = sha.digest('base64');
    log.debug(`Signature: ${signature}`)
    return signature;
}
