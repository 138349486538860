import React from "react";
import {useSelector} from "react-redux";
import {useForm} from "react-hook-form";
import DateFnsUtils from "@date-io/date-fns";

import Grid from "@material-ui/core/Grid";
import {Button, DialogActions} from "@material-ui/core";
import {MuiPickersUtilsProvider} from "@material-ui/pickers";

import ExPanel from "./ExPanel";
import ErrorMessage from "./ErrorMessage";
import Progress from "./Progress";
import JsonSwitch from "./JsonSwitch";
import {FormInputCtr} from "./FormInputCtr";
import useApiRequest from "../hooks/useApiRequest";
import JsonView from "./JsonView";
import {DataGrid} from "@mui/x-data-grid";
import {CopyToClipboard} from "react-copy-to-clipboard/lib/Component";
import {getFieldList} from "../utils/format";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import ReportDialog from "./ReportDialog";
import {isDisabled} from "../store/actions/aws";

const formFields = {
    "dateOn": {
        label: 'Creation Date',
        type: 'date',
        initValue: null,
        order: 1
    },
    "limit": {
        label: 'Limit',
        initValue: "100",
        order: 2
    }
}

const fieldList = getFieldList(formFields)

const requestType = 'report-files-list';

const ReportFiles = ({columns, loading, jsonView, items}) => {
    if (!items) {
        return null;
    }
    if (jsonView) {
        return (
            <JsonView data={items} shouldExpandNode={(keyPath, data, level) => level < 1}/>
        )
    }
    return <div style={{height: 400, width: '100%'}}>
        <DataGrid
            getRowId={(row) => row.id}
            rows={items}
            hideFooterSelectedRowCount={true}
            autoPageSize={true}
            columns={columns}
            loading={loading}
            density='compact'
        />
    </div>
}

export default function ReportsPanel({submitRequest}) {
    const reportsInfo = useSelector(state => state.console.reportsInfo);
    const formsData = useSelector(state => state.console.formsData[requestType]);
    const [requestId, requestInProgress, requestError, newRequest] = useApiRequest();
    const [jsonView, setJsonView] = React.useState(false);
    const [selectedRow, setSelectedRow] = React.useState('');
    const [openReportInfo, setOpenReportInfo] = React.useState(false);

    const dataPresent = !!reportsInfo && reportsInfo.reports;

    const { handleSubmit, control, resetField } = useForm({
        defaultValues: {
            ...formsData
        }
    });

    const onSubmit = (fields) => {
        submitRequest(requestType, fields, newRequest());
    }

    const handleOnClean = () => {
        fieldList.forEach(name => resetField(name, {defaultValue: formFields[name].initValue}));
    }

    const handleOpenReportInfo = (row) => {
        setSelectedRow(row)
        setOpenReportInfo(true)
    }

    const handleCloseReportInfo = () => {
        setOpenReportInfo(false)
    }

    const copyCellRenderer = ({value}) => {
        return <CopyToClipboard text={value}>
            <div>{value}</div>
        </CopyToClipboard>
    }

    const fileCellRenderer = ({row, value}) => {
        return <>
            <InfoOutlinedIcon
                style={{marginRight: "5px", cursor: "pointer"}}
                color="action"
                onClick={(e) => handleOpenReportInfo(row)}
            />
            {value}
        </>
    }

    const columns = [
        {field: 'generationTS', headerName: 'Created', width: 180},
        {field: 'profileID', headerName: 'Profile', width: 150},
        {field: 'id', headerName: 'ID', width: 300, renderCell: copyCellRenderer},
        {field: 'reportName', headerName: 'File Name', width: 330, renderCell: fileCellRenderer},
    ]

    return (
        <ExPanel title="Reports" hidden={isDisabled(requestType)}>
            <Grid item xs={12}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <form>
                        {fieldList.map((name) => (
                            <FormInputCtr
                                key={name}
                                name={name}
                                formFields={formFields}
                                control={control}
                                disabled={requestInProgress}
                            />
                        ))}
                    </form>
                </MuiPickersUtilsProvider>
                <DialogActions>
                    <Button disabled={requestInProgress} variant="contained" color="primary" onClick={handleOnClean}>Clean</Button>
                    <Button disabled={requestInProgress} variant="contained" color="primary" onClick={handleSubmit(onSubmit)}>Submit</Button>
                </DialogActions>
                <ErrorMessage errorMessage={requestError}/>
                <Progress show={requestInProgress} requestId={requestId}/>
                <JsonSwitch hide={!dataPresent} jsonView={jsonView} setJsonView={setJsonView}/>
                {dataPresent && <ReportFiles
                    items={reportsInfo.reports}
                    jsonView={jsonView}
                    loading={requestInProgress}
                    columns={columns}
                />
                }
                {openReportInfo && <ReportDialog
                    data={selectedRow}
                    documents={selectedRow ? selectedRow.documentIds : null}
                    reportToken={selectedRow ? selectedRow.reportToken : null}
                    onClose={handleCloseReportInfo}
                    submitRequest={submitRequest}
                />}
            </Grid>
        </ExPanel>)
}
