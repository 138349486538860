import {useState} from "react";

export default function usePermissionItems(items) {

    const [permissionItems, setPermissionItems] = useState(items)

    const remove = (item) => {
        return permissionItems.filter((p) => p.action !== item.action)
    }

    const handleUndo = () => {
        setPermissionItems(items)
    }

    const handleRemove = (item) => {
        setPermissionItems(remove(item))
    }

    const handleUpdate = (item) => {
        if (!item || !item.action || !item.state) {
            return;
        }
        setPermissionItems([...remove(item), item])
    }

    return {permissionItems, handleAdd: handleUpdate, handleUpdate, handleRemove, handleUndo}
}