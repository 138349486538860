import React from "react";
import {useSelector} from "react-redux";
import {useForm} from "react-hook-form";
import DateFnsUtils from "@date-io/date-fns";

import Grid from "@material-ui/core/Grid";
import {Button, DialogActions} from "@material-ui/core";
import {MuiPickersUtilsProvider} from "@material-ui/pickers";

import ExPanel from "./ExPanel";
import ErrorMessage from "./ErrorMessage";
import Progress from "./Progress";
import JsonSwitch from "./JsonSwitch";
import {FormInputCtr} from "./FormInputCtr";
import useApiRequest from "../hooks/useApiRequest";
import JsonView from "./JsonView";
import {DataGrid} from "@mui/x-data-grid";
import {CopyToClipboard} from "react-copy-to-clipboard/lib/Component";
import {getFieldList} from "../utils/format";
import {isDisabled} from "../store/actions/aws";

const formFields = {
    "loanApplicationId": {
        label: 'Loan Application ID/Payout ID',
        initValue: '',
        order: 1
    },
    "creationDate": {
        label: 'Creation Date',
        type: 'date',
        initValue: new Date(),
        order: 2
    },
    "id": {
        label: 'Prefix (# - file not created, * - all)',
        initValue: "*",
        order: 3
    }
}

const fieldList = getFieldList(formFields)

const requestType = 'payments-list';

const Payments = ({columns, loading, jsonView, payments}) => {
    if (!payments) {
        return null;
    }
    if (jsonView) {
        return (
            <JsonView data={payments} shouldExpandNode={(keyPath, data, level) => level < 1}/>
        )
    }
    return <div style={{height: 400, width: '100%'}}>
        <DataGrid
            getRowId={(row) => row.id}
            rows={payments}
            hideFooterSelectedRowCount={true}
            autoPageSize={true}
            columns={columns}
            loading={loading}
            density='compact'
        />
    </div>
}

export default function PaymentsPanel({submitRequest}) {
    const paymentsInfo = useSelector(state => state.console.paymentsInfo);
    const formsData = useSelector(state => state.console.formsData[requestType]);
    const [requestId, requestInProgress, requestError, newRequest] = useApiRequest();
    const [jsonView, setJsonView] = React.useState(false);

    const dataPresent = !!paymentsInfo && paymentsInfo.payments;

    const { handleSubmit, control, resetField } = useForm({
        defaultValues: {
            ...formsData
        }
    });

    const onSubmit = (fields) => {
        submitRequest(requestType, fields, newRequest());
    }

    const handleOnClean = () => {
        fieldList.forEach(name => resetField(name, {defaultValue: formFields[name].initValue}));
    }

    const copyCellRenderer = ({value}) => {
        return <CopyToClipboard text={value}>
            <div>{value}</div>
        </CopyToClipboard>
    }

    const columns = [
        {field: 'creationDate', headerName: 'Created', width: 130},
        {field: 'paymentSourceType', headerName: 'Type', width: 120},
        {field: 'loanApplicationId', headerName: 'Source ID', width: 300, renderCell: copyCellRenderer},
        {field: 'amount', headerName: 'Amount', width: 140},
        {field: 'fileCreatedOn', headerName: 'File Created', width: 180},
    ]

    return (
        <ExPanel title="Payments" hidden={isDisabled(requestType)}>
            <Grid item xs={12}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <form>
                        {fieldList.map((name) => (
                            <FormInputCtr
                                key={name}
                                name={name}
                                formFields={formFields}
                                control={control}
                                disabled={requestInProgress}
                            />
                        ))}
                    </form>
                </MuiPickersUtilsProvider>
                <DialogActions>
                    <Button disabled={requestInProgress} variant="contained" color="primary" onClick={handleOnClean}>Clean</Button>
                    <Button disabled={requestInProgress} variant="contained" color="primary" onClick={handleSubmit(onSubmit)}>Submit</Button>
                </DialogActions>
                <ErrorMessage errorMessage={requestError}/>
                <Progress show={requestInProgress} requestId={requestId}/>
                <JsonSwitch hide={!dataPresent} jsonView={jsonView} setJsonView={setJsonView}/>
                {dataPresent &&
                <Payments
                    payments={paymentsInfo.payments}
                    jsonView={jsonView}
                    loading={requestInProgress}
                    columns={columns}
                />
                }
            </Grid>
        </ExPanel>)
}