import React from 'react';
import {useForm} from "react-hook-form";
import {useSelector} from "react-redux";
import 'date-fns';
import log from 'loglevel';

import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Chip from "@material-ui/core/Chip";
import Avatar from "@material-ui/core/Avatar";

import ExPanel from "./ExPanel";
import SubmitButton from "./SubmitButton";
import {FormInputCtr} from "./FormInputCtr";
import ErrorMessage from "./ErrorMessage";
import Progress from "./Progress";
import useApiRequest from "../hooks/useApiRequest";
import {getApiUrl} from "../store/actions/api";
import {isDisabled} from "../store/actions/aws";

const formFields = {
    "accountId": {
        label: 'Account',
        type: 'partner-select',
        initValue: ''
    },
    "externalId": {
        label: 'External ID',
        initValue: ''
    },
    "industry": {
        label: 'Industry',
        initValue: ''
    },
    "callbackURL": {
        label: 'Callback URL',
        initValue: ''
    }
}
const fieldList = ["accountId", "industry", "externalId", "callbackURL"];

(async () => {
    formFields.callbackURL.initValue = await getApiUrl('testwebhook');
})().catch(e => {
    log.error(e);
});

export default function CreateSignupPanel({submitSignup, openSignup, submitPartnersInfoRequest}) {
    const formsData = useSelector(state => state.console.formsData['create-signup']);
    const signupLink = useSelector(state => state.console.signupLink);
    const merchantId = useSelector(state => state.console.merchantId);
    formFields.accountId.dataRequest = submitPartnersInfoRequest;
    const [requestId, requestInProgress, requestError, newRequest] = useApiRequest();

    const { handleSubmit, control } = useForm({
        defaultValues: {
            ...formsData
        }
    });

    const onSubmit = (data) => {
        submitSignup(data, newRequest())
    }

    const SignupLink = () => {
        if (signupLink && !requestInProgress) {
            return <Link variant="subtitle1" color="primary" href="#" onClick={(e) => {
                e.preventDefault();
                openSignup(signupLink)
            }}>
                {signupLink}
            </Link>
        }
        return null;
    }

    const MerchantId = () => {
        if (merchantId && !requestInProgress) {
            return <div>
                <Chip
                    avatar={<Avatar>M</Avatar>}
                    label={merchantId}
                    color="default"
                />
            </div>
        }
        return null;
    }

    return (
        <ExPanel title="Create Signup" hidden={isDisabled('create-signup')}>
            <Grid item xs={12}>
                <form>
                    {fieldList.map((name) => (
                        <FormInputCtr
                            key={name}
                            name={name}
                            formFields={formFields}
                            control={control}
                            disabled={requestInProgress}
                        />
                    ))}
                    <SubmitButton onClick={handleSubmit(onSubmit)}/>
                </form>
                <ErrorMessage errorMessage={requestError}/>
                <Progress show={requestInProgress} requestId={requestId}/>
                <SignupLink/>
                <MerchantId/>
            </Grid>
        </ExPanel>
    )
}