import React from "react";
import {useSelector} from "react-redux";

import {Button, DialogActions} from "@material-ui/core";

import FormInput from "./FormInput";
import ErrorMessage from "./ErrorMessage";
import Progress from "./Progress";
import JsonView from "./JsonView";
import {loanInfoFieldList, loanInfoFields} from "../utils/constants";
import useApiRequest from "../hooks/useApiRequest";
import useFields from "../hooks/useFields";

const ReleaseLockout = ({onClick, loanInfo}) => {
    if (!loanInfo || !loanInfo.loanApplication || !loanInfo.loanApplication.authPinAttempts || loanInfo.loanApplication.authPinAttempts < 10) {
        return null;
    }
    return <Button variant="outlined" color="primary" style={{marginRight: "10px"}} size="small" onClick={onClick}>
        Release Lockout
    </Button>
}

export default function LoanInfoComponent({submitRequest, disableDataTypes, loanId, openPaymentLink}) {
    const formFields = {...loanInfoFields};
    const fieldList = [...loanInfoFieldList];
    const loanInfo = useSelector(state => state.console.loanInfo);

    if (disableDataTypes) {
        delete fieldList[1]
    }
    if (loanId) {
        formFields.loanApplicationId.initValue = loanId;
    }
    const [fields, handleOnChange] = useFields(formFields);
    const [requestId, requestInProgress, requestError, newRequest] = useApiRequest();
    const dataPresent = !!loanInfo && !!loanInfo.loanApplication
    const needReload = !requestInProgress && !!loanId && dataPresent && loanId !== loanInfo.loanApplication.id && loanId !== loanInfo.loanApplication.shortId;
    const cancelable = dataPresent && loanInfo.loanApplication.cancelable
    const onHold = dataPresent && loanInfo.loanApplication.onHold

    const handleLoanInfoRequest = () => {
        if (fields.loanApplicationId) {
            submitRequest('loan-info', fields, newRequest());
        }
    }

    const handleReleaseLockout = () => {
        submitRequest('loan-lockout-update', {loanApplicationId: loanInfo.loanApplication.id, release: true}, newRequest());
    }

    const handleCancel = () => {
        submitRequest('loan-cancel', {applicationID: loanInfo.loanApplication.id, triggerWebhook: true}, newRequest());
    }

    const handleHold = (hold) => {
        submitRequest('loan-hold', {loanApplicationId: loanInfo.loanApplication.id, hold}, newRequest());
    }

    React.useEffect(() => {
        if (needReload) {
            handleLoanInfoRequest()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleOpenPaymentLink = () => {
        if (openPaymentLink && loanInfo && loanInfo.loanApplication) {
            openPaymentLink({initToken: loanInfo.loanApplication.initToken})
        }
    }

    const LoanInfo = () => {
        if (!dataPresent) {
            return null;
        }
        return <>
            { openPaymentLink &&
                <Button variant="outlined" color="primary" style={{marginRight: "10px"}} size="small" onClick={handleOpenPaymentLink}>
                    Open Payment Link
                </Button>
            }
            { cancelable &&
                <Button variant="outlined" color="primary" style={{marginRight: "10px"}} size="small" onClick={handleCancel}>
                    Cancel Loan Application
                </Button>
            }
            { cancelable && !onHold &&
                <Button variant="outlined" color="primary" style={{marginRight: "10px"}} size="small" onClick={() => handleHold(true)}>
                    Put On Hold
                </Button>
            }
            { onHold &&
                <Button variant="outlined" color="primary" style={{marginRight: "10px"}} size="small" onClick={() => handleHold(false)}>
                    Release Hold
                </Button>
            }
            <ReleaseLockout loanInfo={loanInfo} onClick={handleReleaseLockout}/>
            <JsonView data={loanInfo} shouldExpandNode={(keyPath, data, level) => {
                if (level < 2) {return true;}
                if (keyPath[0] === 'ruleGroupSet' || keyPath[0] === 'requestedLoanAmount') {
                    return true;
                }
                return keyPath[1] === 'ruleGroupSet';
            }}/>
        </>
    }

    return (
        <>
            <form>
                {fieldList.map((name) => (
                    <FormInput
                        disabled={requestInProgress}
                        key={name}
                        name={name}
                        onChange={handleOnChange}
                        getEditValue={name => fields[name]}
                        formFields={formFields}
                    />
                ))}
                <DialogActions>
                    <Button disabled={requestInProgress} variant="contained" color="primary" onClick={handleLoanInfoRequest}>Submit</Button>
                </DialogActions>
            </form>
            <ErrorMessage errorMessage={requestError}/>
            <Progress show={requestInProgress} requestId={requestId}/>
            <LoanInfo/>
        </>
    )
}
