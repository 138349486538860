import React, {useEffect} from 'react';

import {Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import DraggableDialog from "./DraggableDialog";
import ErrorMessage from "./ErrorMessage";
import Progress from "./Progress";
import useApiRequest from "../hooks/useApiRequest";
import {FormInputCtr} from "./FormInputCtr";
import {useForm} from "react-hook-form";
import {useSelector} from "react-redux";
import PermissionItemList from "./PermissionItemList";
import usePermissionItems from "../hooks/usePermissionItems";

const formFields = {
    "subjectId": {
        hide: (val) => val,
        label: 'Subject',
        initValue: '',
        rules: {
            required: 'Subject ID required'
        }
    },
    "subjectType": {
        hide: () => false,
        label: 'Subject Type',
        type: 'select',
        data: [
            {
                id: 'USER'
            },
            {
                id: 'ROLE'
            }
        ],
        initValue: 'USER'
    },
    "parentSubjectId": {
        hide: () => false,
        label: 'Parent Subject',
        initValue: ''
    },
    "iamRole": {
        hide: () => false,
        label: 'IAM Role',
        initValue: ''
    },
    "description": {
        hide: () => false,
        label: 'Description',
        initValue: ''
    },
    "disabled": {
        hide: () => false,
        label: 'Disable',
        type: 'switch',
        initValue: false
    }

}

const fieldList = [
    "subjectId",
    "subjectType",
    "parentSubjectId",
    "iamRole",
    "description",
    "disabled"
]

const setRequestType = 'console-permission-set';

export default function ConsolePermissionDialog({data, onClose, submitRequest}) {
    const consolePermissionSetRequestId = useSelector(state => state.console.consolePermissionSetRequestId);
    const formsData = useSelector(state => state.console.formsData[setRequestType]);
    const [requestId, requestInProgress, requestError, newRequest] = useApiRequest();
    const editMode = !!data && !!data.subjectId;
    const requestFinished = !!requestId && requestId === consolePermissionSetRequestId

    const {permissionItems, handleAdd, handleUpdate, handleRemove, handleUndo} = usePermissionItems(!!data && !!data.items ? data.items : [])

    const { handleSubmit, control, getValues } = useForm({
        defaultValues: !!data ? {
            ...data
        } : {
            ...formsData
        }
    });

    useEffect(() => {
        if (requestFinished) {
            onClose()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [requestFinished]);

    const onSubmit = (values) => {
        submitRequest(setRequestType, {...values, items: permissionItems}, newRequest());
    }

    const handleDelete = () => {
        const values = {
            subjectId: getValues('subjectId'),
            delete: true
        }
        submitRequest(setRequestType, values, newRequest());
    }

    return (
        <Dialog
            PaperComponent={DraggableDialog}
            aria-labelledby="draggable-dialog-title"
            open={true}
            fullWidth={true}
        >
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                { editMode ?
                    <>
                        Edit Console Permission <Chip
                            label={data.subjectId}
                            size="small"
                        />
                    </> :
                    <>
                        New Console Permission
                    </>
                }
            </DialogTitle>
            <DialogContent dividers={true}>
                {fieldList.map((name) => (
                    <FormInputCtr
                        key={name}
                        name={name}
                        formFields={formFields}
                        hide={formFields[name].hide(editMode)}
                        control={control}
                        disabled={requestInProgress}
                    />
                ))}
                <PermissionItemList
                    items={permissionItems}
                    onAdd={handleAdd}
                    onUndo={handleUndo}
                    onUpdate={handleUpdate}
                    onRemove={handleRemove}
                />
            </DialogContent>
            <div style={{paddingTop: "10px", paddingLeft: "10px", paddingRight: "10px", paddingBottom: "5px"}}>
                <ErrorMessage errorMessage={requestError}/>
                <Progress show={requestInProgress} requestId={requestId}/>
            </div>
            <DialogActions>
                { editMode &&
                    <Button disabled={requestInProgress} variant="contained" color="secondary" onClick={handleDelete}>Delete</Button>
                }
                <Button disabled={requestInProgress} variant="contained" color="primary" onClick={handleSubmit(onSubmit)}>Submit</Button>
                <Button disabled={requestInProgress} variant="contained" onClick={onClose}>Close</Button>
            </DialogActions>
        </Dialog>
    )


}