import React from 'react';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {Tooltip, Zoom} from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

import Title from './Title';

export default function ExPanel({ title, children, tooltip, hidden }) {

    const Tip = () => {
        if (tooltip) {
            return <Tooltip TransitionComponent={Zoom} title={tooltip}>
                <InfoOutlinedIcon color="action" style={{marginRight: "5px"}}/>
            </Tooltip>
        }
        return null;
    }

    return (
        <Accordion hidden={hidden}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
            >
                <Tip/>
                <Title>{title}</Title>
            </AccordionSummary>
            <AccordionDetails>
                {children}
            </AccordionDetails>
        </Accordion>
    )
}