import React from "react";

import ExPanel from "./ExPanel";
import Grid from "@material-ui/core/Grid";
import {FormInputCtr} from "./FormInputCtr";
import {Button, DialogActions} from "@material-ui/core";
import ErrorMessage from "./ErrorMessage";
import Progress from "./Progress";
import {useSelector} from "react-redux";
import useApiRequest from "../hooks/useApiRequest";
import {useForm} from "react-hook-form";
import ConsolePermissionDialog from "./ConsolePermissionDialog";
import JsonView from "./JsonView";
import {DataGrid} from "@mui/x-data-grid";
import JsonSwitch from "./JsonSwitch";
import EditIcon from "@material-ui/icons/Edit";
import Chip from "@material-ui/core/Chip";
import ConsoleEffectivePermissionDialog from "./ConsoleEffectivePermissionDialog";
import {isDisabled} from "../store/actions/aws";


const PermissionList = ({columns, loading, jsonView, permissionList}) => {
    if (!permissionList) {
        return null;
    }
    if (jsonView) {
        return <JsonView data={permissionList} shouldExpandNode={(keyPath, data, level) => level < 3}/>
    }
    return <div style={{height: 400, width: '100%'}}>
        <DataGrid
            getRowId={(row) => row.subjectId}
            rows={permissionList}
            hideFooterSelectedRowCount={true}
            autoPageSize={true}
            columns={columns}
            loading={loading}
            density='compact'
        />
    </div>
}

const formFields = {
    "subjectId": {
        label: 'Subject',
        initValue: ''
    },
    "limit": {
        label: 'Limit',
        initValue: '100'
    }
}

const fieldList = [
    "subjectId",
    "limit"
]

const getRequestType = 'console-permission-list';

export default function ConsolePermissionPanel({submitRequest}) {
    const formsData = useSelector(state => state.console.formsData[getRequestType]);

    const consolePermissionList = useSelector(state => state.console.consolePermissionList);
    const [requestId, requestInProgress, requestError, newRequest] = useApiRequest();
    const [openDialog, setOpenDialog] = React.useState(false);
    const [openEffectiveDialog, setOpenEffectiveDialog] = React.useState(false);
    const [selectedRow, setSelectedRow] = React.useState('');
    const [jsonView, setJsonView] = React.useState(false);

    const permissionList = !!consolePermissionList && consolePermissionList.permissionList;

    const { handleSubmit, control } = useForm({
        defaultValues: {
            ...formsData
        }
    });

    const onSubmit = (values) => {
        submitRequest(getRequestType, values, newRequest());
    }

    const handleCreateOpen = () => {
        setSelectedRow({})
        setOpenDialog(true);
    }

    const handleEditOpen = (row) => {
        setSelectedRow(row);
        setOpenDialog(true);
    }

    const handleEditClose = () => {
        setOpenDialog(false);
    }

    const handleEffectiveOpen = (row) => {
        setSelectedRow(row);
        setOpenEffectiveDialog(true)
    }

    const handleEffectiveClose = () => {
        setOpenEffectiveDialog(false)
    }

    const idCellRenderer = ({row, value}) => {
        const editedRow = !!selectedRow && selectedRow.subjectId === row.subjectId
        const deleteIcon = editedRow ? <EditIcon /> : null;
        const onDelete = editedRow ? () => setSelectedRow(null) : null;
        return <Chip
            label={value}
            size="small"
            color={row.disabled ? "secondary" : "primary"}
            onClick={() => {handleEditOpen(row)}}
            deleteIcon={deleteIcon}
            onDelete={onDelete}
        />
    }

    const parentSubjectCellRenderer = ({row, value}) => {
        if (!value) {
            return null
        }
        return <Chip
            label={value}
            size="small"
            color="default"
            onClick={() => {handleEffectiveOpen(row)}}
        />
    }

    const columns = [
        {field: 'createdAtString', headerName: 'Created', width: 130},
        {field: 'subjectId', headerName: 'Subject', width: 190, renderCell: idCellRenderer},
        {field: 'subjectType', headerName: 'Type', width: 120},
        {field: 'parentSubjectId', headerName: 'Parent', width: 190, renderCell: parentSubjectCellRenderer},
        {field: 'iamRole', headerName: 'AWS IAM Role', width: 190},
        {field: 'description', headerName: 'Description', width: 290},
        {field: 'disabled', headerName: 'Disabled', width: 190},
    ]

    return (
        <ExPanel title="Console Permission List" hidden={isDisabled(getRequestType)}>
            <Grid item xs={12}>
                <form>
                    {fieldList.map((name) => (
                        <FormInputCtr
                            key={name}
                            name={name}
                            formFields={formFields}
                            control={control}
                            disabled={requestInProgress}
                        />
                    ))}
                </form>
                <DialogActions>
                    <Button disabled={requestInProgress} variant="contained" color="primary" onClick={handleCreateOpen}>Create New</Button>
                    <Button disabled={requestInProgress} variant="contained" color="primary" onClick={handleSubmit(onSubmit)}>Get List</Button>
                </DialogActions>
                <ErrorMessage errorMessage={requestError}/>
                <Progress show={requestInProgress} requestId={requestId}/>
                <JsonSwitch hide={!permissionList} jsonView={jsonView} setJsonView={setJsonView}/>
                <PermissionList
                    columns={columns}
                    jsonView={jsonView}
                    loading={requestInProgress}
                    permissionList={permissionList}
                />
                {openDialog && <ConsolePermissionDialog
                    data={selectedRow}
                    onClose={handleEditClose}
                    submitRequest={submitRequest}
                />}
                {openEffectiveDialog && <ConsoleEffectivePermissionDialog
                    subjectId={selectedRow.subjectId}
                    onClose={handleEffectiveClose}
                    submitRequest={submitRequest}
                />}
            </Grid>
        </ExPanel>
    )
}