import React from "react";
import {useSelector} from "react-redux";
import {useForm} from "react-hook-form";
import DateFnsUtils from "@date-io/date-fns";

import Grid from "@material-ui/core/Grid";
import {Button, DialogActions} from "@material-ui/core";
import {MuiPickersUtilsProvider} from "@material-ui/pickers";

import ExPanel from "./ExPanel";
import ErrorMessage from "./ErrorMessage";
import Progress from "./Progress";
import JsonSwitch from "./JsonSwitch";
import {FormInputCtr} from "./FormInputCtr";
import useSettings from "../hooks/useSettings";
import useApiRequest from "../hooks/useApiRequest";
import JsonView from "./JsonView";
import {DataGrid} from "@mui/x-data-grid";
import {CopyToClipboard} from "react-copy-to-clipboard/lib/Component";
import {getFieldList} from "../utils/format";
import Link from "@material-ui/core/Link";
import {isDisabled} from "../store/actions/aws";

const formFields = {
    "mode": {
        label: 'Mode',
        type: 'select',
        data: [],
        dataType: 'payoutQueryModes',
        initValue: 'PAYOUT',
        order: 1
    },
    "id": {
        label: 'Loan Application ID/Merchant ID/Payout ID',
        initValue: '',
        order: 2
    },
    "status": {
        label: 'Status',
        type: 'select',
        data: [],
        dataType: 'payoutStatuses',
        initValue: '',
        order: 3
    },
    "dateOn": {
        label: 'Date',
        type: 'date',
        initValue: null,
        order: 4
    },
    "limit": {
        label: 'Limit',
        initValue: 100,
        order: 5
    },
    "forward": {
        label: 'Forward Index',
        type: 'switch',
        initValue: true,
        order: 6
    }
}

const fieldList = getFieldList(formFields)

const requestType = 'payouts-list';
const cancelRequestType = 'payout-cancel';
const holdRequestType = 'payout-hold'

const Payouts = ({columns, loading, jsonView, payouts}) => {
    if (!payouts) {
        return null;
    }
    if (jsonView) {
        return (
            <JsonView data={payouts} shouldExpandNode={(keyPath, data, level) => level < 1}/>
        )
    }
    return <div style={{height: 400, width: '100%'}}>
        <DataGrid
            getRowId={(row) => row.id}
            rows={payouts}
            hideFooterSelectedRowCount={true}
            autoPageSize={true}
            columns={columns}
            loading={loading}
            density='compact'
        />
    </div>
}

export default function PayoutsPanel({submitRequest}) {
    const payoutsInfo = useSelector(state => state.console.payoutsInfo);
    const formsData = useSelector(state => state.console.formsData[requestType]);
    const [requestId, requestInProgress, requestError, newRequest] = useApiRequest();
    const [jsonView, setJsonView] = React.useState(false);

    useSettings(formFields)

    const dataPresent = !!payoutsInfo && payoutsInfo.payouts;

    const { handleSubmit, control, resetField } = useForm({
        defaultValues: {
            ...formsData
        }
    });

    const onSubmit = (fields) => {
        submitRequest(requestType, fields, newRequest());
    }

    const handleOnClean = () => {
        fieldList.forEach(name => resetField(name, {defaultValue: formFields[name].initValue}));
    }

    const copyCellRenderer = ({value}) => {
        return <CopyToClipboard text={value}>
            <div>{value}</div>
        </CopyToClipboard>
    }

    const statusCellRenderer = ({row, value}) => {
        return <>
            {value}
            <Link style={{marginLeft: '10px'}} href="#" hidden={!row.cancelable} color="secondary" onClick={(e) => {
                e.preventDefault();
                submitRequest(cancelRequestType, {payoutId: row.id, notifyStatus: true}, newRequest());
            }}>
                CANCEL
            </Link>
            <Link style={{marginLeft: '10px'}} href="#" hidden={!row.cancelable || row.onHold} color="primary" onClick={(e) => {
                e.preventDefault();
                submitRequest(holdRequestType, {payoutId: row.id, hold: true}, newRequest());
            }}>
                PUT ON HOLD
            </Link>
            <Link style={{marginLeft: '10px'}} href="#" hidden={!row.onHold} color="primary" onClick={(e) => {
                e.preventDefault();
                submitRequest(holdRequestType, {payoutId: row.id, hold: false}, newRequest());
            }}>
                RELEASE HOLD
            </Link>
        </>
    }

    const columns = [
        {field: 'createdAt', headerName: 'Created', width: 180},
        {field: 'id', headerName: 'Payout ID', width: 300, renderCell: copyCellRenderer},
        {field: 'loanApplicationId', headerName: 'Loan Application ID', width: 300, renderCell: copyCellRenderer},
        {field: 'merchantId', headerName: 'Merchant ID', width: 300, renderCell: copyCellRenderer},
        {field: 'status', headerName: 'Payout Status', width: 360, renderCell: statusCellRenderer},
    ]

    return (
        <ExPanel title="Payouts" hidden={isDisabled(requestType)}>
            <Grid item xs={12}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <form>
                        {fieldList.map((name) => (
                            <FormInputCtr
                                key={name}
                                name={name}
                                formFields={formFields}
                                control={control}
                                disabled={requestInProgress}
                            />
                        ))}
                    </form>
                </MuiPickersUtilsProvider>
                <DialogActions>
                    <Button disabled={requestInProgress} variant="contained" color="primary" onClick={handleOnClean}>Clean</Button>
                    <Button disabled={requestInProgress} variant="contained" color="primary" onClick={handleSubmit(onSubmit)}>Submit</Button>
                </DialogActions>
                <ErrorMessage errorMessage={requestError}/>
                <Progress show={requestInProgress} requestId={requestId}/>
                <JsonSwitch hide={!dataPresent} jsonView={jsonView} setJsonView={setJsonView}/>
                {dataPresent &&
                    <Payouts
                        payouts={payoutsInfo.payouts}
                        jsonView={jsonView}
                        loading={requestInProgress}
                        columns={columns}
                    />
                }
            </Grid>
        </ExPanel>)
}