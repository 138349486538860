import React, {useEffect} from "react";

import Grid from "@material-ui/core/Grid";
import {Button, Card, CardContent, CardHeader, DialogActions} from "@material-ui/core";

import ExPanel from "./ExPanel";
import ErrorMessage from "./ErrorMessage";
import Progress from "./Progress";
import useApiRequest from "../hooks/useApiRequest";
import FormInput from "./FormInput";
import useFields from "../hooks/useFields";
import {useDispatch, useSelector} from "react-redux";
import JsonSwitch from "./JsonSwitch";
import JsonView from "./JsonView";
import {DataGrid} from "@mui/x-data-grid";
import EditIcon from "@material-ui/icons/Edit";
import {CopyToClipboard} from "react-copy-to-clipboard/lib/Component";
import Chip from "@material-ui/core/Chip";
import {getIpcRenderer} from "../utils/electron";
import Link from "@material-ui/core/Link";
import {isDisabled} from "../store/actions/aws";

// pdf download from peach don't work in browser
const ipcRenderer = getIpcRenderer();

const formFields = {
    "hashKey":{
        label: 'Loan application/payout ID',
        initValue: ''
    },
    "limit": {
        label: 'Limit',
        type: 'number',
        initValue: 50
    }
};

const fieldList = [
    'hashKey',
    'limit'
];

const requestType = 'query-dynamodb'

const QueryResult = ({columns, peachList, jsonView, loading}) => {
    if(!peachList) {
        return null;
    }
    if (jsonView) {
        return <JsonView data={peachList} shouldExpandNode={(keyPath, data, level) => level < 2}/>
    }
    return <div style={{height: 450, width: '100%'}}>
        <DataGrid
            disableSelectionOnClick={true}
            rowHeight={46}
            pageSize={10}
            rowsPerPageOptions={[10]}
            getRowId={(row) => row.peachId}
            rows={peachList}
            hideFooterSelectedRowCount={true}
            columns={columns}
            loading={loading}
            density='compact'
        />
    </div>
}

const PeachData = ({item, title, openDocument}) => {
    if (!item) {
        return null;
    }
    const id = item.data ? item.data.id : item.id
    return <Card>
        <CardHeader title={`${title} ${id}`}/>
        <CardContent>
            {openDocument && ipcRenderer &&
                <Button variant="outlined" color="primary" style={{marginRight: "10px"}} size="small" onClick={openDocument}>
                    Download Document
                </Button>
            }
            <JsonView data={item} shouldExpandNode={(keyPath, data, level) => level < 2}/>
        </CardContent>
    </Card>
}

export default function PeachPanel({submitRequest, decryptWithVault}) {
    const dispatch = useDispatch();

    const formsData = useSelector(state => state.console.formsData[requestType]);
    const decryptedValue = useSelector(state => state.console.decryptedValue);
    const dynamoDBQueryResult = useSelector(state => state.console.dynamoDBQueryResult);
    const dynamoDBQueryRequestId = useSelector(state => state.console.dynamoDBQueryRequestId);
    const peachBorrower = useSelector(state => state.console.peachBorrower);
    const peachUser = useSelector(state => state.console.peachUser);
    const peachLoan = useSelector(state => state.console.peachLoan);
    const peachAutopayPlan = useSelector(state => state.console.peachAutopayPlan);
    const peachContact = useSelector(state => state.console.peachContact);
    const peachPaymentInstrument = useSelector(state => state.console.peachPaymentInstrument);
    const peachDocumentDescriptor = useSelector(state => state.console.peachDocumentDescriptor);

    const [requestId, requestInProgress, requestError, newRequest] = useApiRequest();
    const [fields, handleOnChange] = useFields(formFields, formsData, fieldList);
    const [peachList, setPeachList] = React.useState([]);
    const [jsonView, setJsonView] = React.useState(false);
    const [selectedRow, setSelectedRow] = React.useState('');

    const rawDataRequested = !!requestId && requestId === dynamoDBQueryRequestId && !!dynamoDBQueryResult

    useEffect(() => {
        if (rawDataRequested) {
            for (const item of dynamoDBQueryResult) {
                if (item.service === 'PeachCorrelationIds') {
                    decryptWithVault(item.json, newRequest())
                    break
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [requestId, rawDataRequested]);

    useEffect(() => {
        if (!!decryptedValue) {
            const peachItems = JSON.parse(decryptedValue);
            setPeachList(peachItems)
        }
    }, [decryptedValue, requestId])

    const handleSubmitRequest = () => {
        dispatch({type: "peach-cleanup"})
        setPeachList([])
        submitRequest(requestType, {
            ...fields,
            indexName: "main",
            rangeKeyCondition: "begins_with",
            rangeKey: "raw_data#",
            scanIndexForward: false
        }, newRequest());
    }

    const handleRowClicked = (row) => {
        setSelectedRow(row);
        if (row.entityType === 'PeachBorrower') {
            submitRequest('peach-get-borrower', {personId: row.peachId}, newRequest())
            return
        }
        if (row.entityType === 'User') {
            submitRequest('peach-get-user', {userId: row.peachId}, newRequest())
            return
        }
        if (row.entityType === 'PeachLoan') {
            for (const item of peachList) {
                if (item.entityType === 'PeachBorrower') {
                    submitRequest('peach-get-loan', {loanId: row.peachId, personId: item.peachId}, newRequest())
                    break
                }
            }
            return
        }
        if (row.entityType === 'PeachContactInformation') {
            for (const item of peachList) {
                if (item.entityType === 'PeachBorrower') {
                    submitRequest('peach-get-contact', {contactId: row.peachId, personId: item.peachId}, newRequest())
                    break
                }
            }
            return
        }
        if (row.entityType === 'PeachPaymentInstrument') {
            for (const item of peachList) {
                if (item.entityType === 'PeachBorrower') {
                    submitRequest('peach-get-payment-instrument', {paymentInstrumentId: row.peachId, personId: item.peachId}, newRequest())
                    break
                }
            }
            return
        }
        if (row.entityType === 'PeachDocument') {
            for (const item of peachList) {
                if (item.entityType === 'PeachBorrower') {
                    submitRequest('peach-get-document-descriptor', {documentDescriptorId: row.peachId, personId: item.peachId}, newRequest())
                    break
                }
            }
            return
        }
        if (row.entityType === 'PeachAutopayPlan') {
            let personId
            let loanId
            for (const item of peachList) {
                if (item.entityType === 'PeachBorrower') {
                    personId = item.peachId
                }
                if (item.entityType === 'PeachLoan') {
                    loanId = item.peachId
                }
                if (!!personId && !!loanId) {
                    submitRequest('peach-get-autopay-plan', {personId, loanId}, newRequest())
                    break
                }
            }
        }


    }

    const peachIdCellRenderer = ({row, value}) => {
        const editedRow = !!selectedRow && selectedRow.peachId === row.peachId
        const deleteIcon = editedRow ? <EditIcon/> : null;
        const onDelete = editedRow ? () => setSelectedRow(null) : null;
        return <CopyToClipboard text={row.peachId}><Chip
            label={value}
            size="small"
            color="default"
            onClick={() => {
                handleRowClicked(row)
            }}
            deleteIcon={deleteIcon}
            onDelete={onDelete}
        /></CopyToClipboard>
    }

    const opCellRenderer = ({row}) => {
        if (['PeachPaymentInstrument'].includes(row.entityType)) { // TODO other entity types
            return <Link style={{marginLeft: '10px'}} href="#" color="secondary" onClick={(e) => {
                e.preventDefault();
                if (row.entityType === 'PeachPaymentInstrument') {
                    for (const item of peachList) {
                        if (item.entityType === 'PeachBorrower') {
                            submitRequest('peach-delete-payment-instrument', {
                                paymentInstrumentId: row.peachId,
                                personId: item.peachId
                            }, newRequest())
                            break
                        }
                    }
                    return
                }
                submitRequest('NOT IMPLEMENTED', {}, newRequest())
            }}>
                DELETE
            </Link>
        }
        return null
    }

    const columns = [
        {field: 'peachId', headerName: 'Peach ID', width: 150, renderCell: peachIdCellRenderer},
        {field: 'entityType', headerName: 'Entity Type', width: 250},
        {field: 'wisetackId', headerName: 'Wisetack ID', width: 450},
        {field: '', headerName: 'Operation', width: 150, renderCell: opCellRenderer},
    ]

    return (
        <ExPanel title="Peach Data" hidden={isDisabled(requestType)}>
            <Grid item xs={12}>
                <form>
                    {fieldList.map((name) => (
                        <FormInput
                            key={name}
                            name={name}
                            onChange={handleOnChange}
                            getEditValue={name => fields[name]}
                            formFields={formFields}
                        />
                    ))}
                </form>
                <DialogActions>
                    <Button disabled={requestInProgress} variant="contained" color="primary" onClick={handleSubmitRequest}>Submit</Button>
                </DialogActions>
                <ErrorMessage errorMessage={requestError}/>
                <Progress show={requestInProgress} requestId={requestId}/>
                <JsonSwitch hide={!peachList} jsonView={jsonView} setJsonView={setJsonView}/>
                <QueryResult
                    loading={requestInProgress}
                    jsonView={jsonView}
                    peachList={peachList}
                    columns={columns}
                />
                <PeachData item={peachBorrower} title="Peach Borrower"/>
                <PeachData item={peachUser} title="Peach User"/>
                <PeachData item={peachContact} title="Peach Contact"/>
                <PeachData item={peachLoan} title="Peach Loan"/>
                <PeachData item={peachAutopayPlan} title="Peach Autopay Plan"/>
                <PeachData item={peachPaymentInstrument} title="Peach Payment Instrument"/>
                <PeachData item={peachDocumentDescriptor} title="Peach Document" openDocument={() => {
                    submitRequest('peach-get-document-content', {documentDescriptorId: peachDocumentDescriptor.id, personId: peachDocumentDescriptor.personId}, newRequest())
                }}/>
            </Grid>
        </ExPanel>
    )
}