import React from "react";

import Link from "@material-ui/core/Link";

import Panel from "./Panel";
import Title from "./Title";
import {isDisabled} from "../store/actions/aws";

export default function MerchantPortalPanel({submitRequest}) {
    if (isDisabled('open-merchant-portal')) {
        return null;
    }
    return (
        <Panel>
            <Link href="#" onClick={(e) => {e.preventDefault(); submitRequest('open-merchant-portal')}}>
                <Title>Merchant Portal</Title>
            </Link>
        </Panel>
    )
}
