import React, {useEffect} from "react";
import {useSelector} from "react-redux";

import {
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TableBody,
    TableCell,
    TableContainer,
    TableRow
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";

import DraggableDialog from "./DraggableDialog";
import ErrorMessage from "./ErrorMessage";
import Progress from "./Progress";
import useApiRequest from "../hooks/useApiRequest";

const InfoItem = ({name, value}) => {
    if (!value) {
        return null;
    }
    return <div>
        {name}: <b>{value}</b>
    </div>
}

const PermissionItem = ({item}) => {
    return <TableRow>
        <TableCell>
            {item.action}
        </TableCell>
        <TableCell>
            {item.state}
        </TableCell>
        <TableCell>
            {item.subjectId}
        </TableCell>
        <TableCell>
            {item.subjectType}
        </TableCell>
    </TableRow>
}

const getRequestType = 'console-permission-effective';

export default function ConsoleEffectivePermissionDialog({subjectId, onClose, submitRequest}) {
    const consoleEffectivePermission = useSelector(state => state.console.consoleEffectivePermission);
    const [requestId, requestInProgress, requestError, newRequest] = useApiRequest();
    const dataAvailable = !!consoleEffectivePermission && consoleEffectivePermission.subjectId === subjectId;

    useEffect(() => {
        submitRequest(getRequestType, {subjectId, effective: true}, newRequest());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [subjectId]);

    return (
        <Dialog
            PaperComponent={DraggableDialog}
            aria-labelledby="draggable-dialog-title"
            open={true}
            fullWidth={true}
        >
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                Console Effective Permission <Chip label={subjectId} size="small"/>
            </DialogTitle>
            <DialogContent dividers={true}>
                { dataAvailable && !requestInProgress &&
                    <>
                        <InfoItem name={"Created"} value={consoleEffectivePermission.createdAtString}/>
                        <InfoItem name={"Subject Type"} value={consoleEffectivePermission.subjectType}/>
                        <InfoItem name={"Parent Subject"} value={consoleEffectivePermission.parentSubjectId}/>
                        <InfoItem name={"IAM Role"} value={consoleEffectivePermission.iamRole}/>
                        <InfoItem name={"Description"} value={consoleEffectivePermission.description}/>
                        <InfoItem name={"Disabled"} value={consoleEffectivePermission.disabled}/>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Action</TableCell>
                                        <TableCell>State</TableCell>
                                        <TableCell>Subject</TableCell>
                                        <TableCell>Subject Type</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    { consoleEffectivePermission.items &&
                                        consoleEffectivePermission.items.map((item) => (<PermissionItem key={item.action}
                                                                         item={item}
                                    />))}
                                </TableBody>
                            </Table>

                        </TableContainer>
                    </>
                }
            </DialogContent>
            <div style={{paddingTop: "10px", paddingLeft: "10px", paddingRight: "10px", paddingBottom: "5px"}}>
                <ErrorMessage errorMessage={requestError}/>
                <Progress show={requestInProgress} requestId={requestId}/>
            </div>
            <DialogActions>
                <Button disabled={requestInProgress} variant="contained" onClick={onClose}>Close</Button>
            </DialogActions>
        </Dialog>
    )
}
