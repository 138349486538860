import React from "react";
import {useSelector} from "react-redux";
import DateFnsUtils from "@date-io/date-fns";

import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import {Button, DialogActions, Grid} from "@material-ui/core";

import ExPanel from "./ExPanel";
import {FormInputCtr} from "./FormInputCtr";
import ErrorMessage from "./ErrorMessage";
import Progress from "./Progress";
import {getFieldList} from "../utils/format";
import useApiRequest from "../hooks/useApiRequest";
import JsonView from "./JsonView";
import {DataGrid} from "@mui/x-data-grid";
import JsonSwitch from "./JsonSwitch";
import {CopyToClipboard} from "react-copy-to-clipboard/lib/Component";
import {useForm} from "react-hook-form";
import Chip from "@material-ui/core/Chip";
import DeleteRefundDialog from "./DeleteRefundDialog";
import {isDisabled} from "../store/actions/aws";

const formFields = {
    "loanApplicationId": {
        label: 'Application ID',
        initValue: '',
        order: 1
    }
}

const fieldList = getFieldList(formFields)
const requestType = 'refunds-list';

const Refunds = ({columns, loading, jsonView, refunds}) => {
    if (!refunds) {
        return null;
    }
    if (jsonView) {
        return (
            <JsonView data={refunds} shouldExpandNode={(keyPath, data, level) => level < 1}/>
        )
    }
    return <div style={{height: 400, width: '100%'}}>
        <DataGrid
            getRowId={(row) => row.id}
            rows={refunds}
            hideFooterSelectedRowCount={true}
            autoPageSize={true}
            columns={columns}
            loading={loading}
            density='compact'
        />
    </div>
}

export default function RefundsPanel({submitRequest}) {
    const refunds = useSelector(state => state.console.refunds);
    const formsData = useSelector(state => state.console.formsData[requestType]);
    const [requestId, requestInProgress, requestError, newRequest] = useApiRequest();
    const [jsonView, setJsonView] = React.useState(false);
    const [selectedRow, setSelectedRow] = React.useState('');
    const [openDialog, setOpenDialog] = React.useState(false);

    const dataPresent = !!refunds;

    const {handleSubmit, control, resetField} = useForm({
        defaultValues: {
            ...formsData
        }
    });

    const onSubmit = (fields) => {
        submitRequest(requestType, fields, newRequest());
    }

    const handleOnClean = () => {
        fieldList.forEach(name => resetField(name, {defaultValue: formFields[name].initValue}));
    }

    const handleIdCellClick = (row) => {
        setSelectedRow(row);
        setOpenDialog(true);
    }

    const handleDialogClose = () => {
        setOpenDialog(false);
    }

    const idCellRenderer = ({row, value}) => {
        return <CopyToClipboard text={row.id}>
            <Chip
                label={value}
                size="small"
                color={row.blocked ? "secondary" : "default"}
                onClick={() => {
                    handleIdCellClick(row)
                }}
            />
        </CopyToClipboard>
    }

    const columns = [
        {field: 'id', headerName: 'ID', width: 290, renderCell: idCellRenderer, description: "Delete refund"},
        {field: 'loanApplicationId', headerName: 'Loan ID', width: 290},
        {field: 'refundDate', headerName: 'Refund date', width: 160},
        {field: 'refundAmount', headerName: 'Refund amount', width: 160},
        {field: 'source', headerName: 'Refund source', width: 160},
        {field: 'refundReason', headerName: 'Reason', width: 160},
        {field: 'refundNotes', headerName: 'Notes', width: 160},
        {field: 'created', headerName: 'Created at', width: 160},
        {field: 'fullReversal', headerName: 'Full reversal', width: 110},
        {field: 'merchantDebitAmount', headerName: 'Merchant debit amount', width: 160},
    ]

    return (
        <ExPanel title="Refunds" hidden={isDisabled(requestType)}>
            <Grid item xs={12}>
                <div>
                    Possible search parameters combinations:
                    <ul>
                        <li>Application ID</li>
                    </ul>
                </div>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <form>
                        {fieldList.map((name) => (
                            <FormInputCtr
                                key={name}
                                name={name}
                                formFields={formFields}
                                control={control}
                                disabled={requestInProgress}
                            />
                        ))}
                    </form>
                </MuiPickersUtilsProvider>
                <DialogActions>
                    <Button disabled={requestInProgress} variant="contained" color="primary" onClick={handleOnClean}>Clean</Button>
                    <Button disabled={requestInProgress} variant="contained" color="primary" onClick={handleSubmit(onSubmit)}>Submit</Button>
                </DialogActions>
                <ErrorMessage errorMessage={requestError}/>
                <Progress show={requestInProgress} requestId={requestId}/>
                <JsonSwitch hide={!dataPresent} jsonView={jsonView} setJsonView={setJsonView}/>
                {dataPresent
                &&
                <Refunds
                    refunds={refunds}
                    jsonView={jsonView}
                    loading={requestInProgress}
                    columns={columns}
                />
                }
                {openDialog &&
                <DeleteRefundDialog
                    data={selectedRow}
                    onClose={handleDialogClose}
                    submitRequest={submitRequest}
                />
                }
            </Grid>
        </ExPanel>
    )
}
