import React from 'react';
import {CopyToClipboard} from "react-copy-to-clipboard/lib/Component";

import {
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    CardHeader,
    Card,
    CardContent
} from "@material-ui/core";

import DraggableDialog from "./DraggableDialog";
import ErrorMessage from "./ErrorMessage";
import Progress from "./Progress";
import JsonView from "./JsonView";
import useApiRequest from "../hooks/useApiRequest";
import {sortObjectKeys} from "../utils/format";
import {useSelector} from "react-redux";

const requestType = 'query-dynamodb-record'

export default function EditDynamoDBRecordDialog({data, onClose, submitRequest, parentRequestId, toDecrypt, decryptWithVault}) {

    const decryptedValue = useSelector(state => state.console.decryptedValue);

    const [requestId, requestInProgress, requestError, newRequest] = useApiRequest(parentRequestId);

    React.useEffect(() => {
        if (!!toDecrypt && !!decryptWithVault && !!data[toDecrypt]) {
            decryptWithVault(data[toDecrypt], newRequest())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleOnReload = () => {
        submitRequest(requestType, {hashKey: data.hashKey, rangeKey: data.rangeKey}, newRequest());
    }

    return (
        <Dialog
            PaperComponent={DraggableDialog}
            aria-labelledby="draggable-dialog-title"
            open={true}
            maxWidth="md"
            fullWidth={true}
        >
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                DynamoDB Record <CopyToClipboard text={data.hashKey}>
                <Chip
                    label={`${data.hashKey} | ${data.rangeKey}`}
                    size="small"
                    color="default"
                    onClick={handleOnReload}
                />
            </CopyToClipboard>
            </DialogTitle>
            <DialogContent dividers={true}>
                <Card>
                    <CardHeader title="Record"/>
                    <CardContent>
                        <JsonView
                            colored={true}
                            data={sortObjectKeys(data)}
                            shouldExpandNode={(keyPath, data, level) => level < 2}
                        />
                    </CardContent>
                </Card>
                {!!toDecrypt && !!decryptedValue &&
                    <Card>
                        <CardHeader title={`Decrypted ${toDecrypt}`} />
                        <CardContent>
                            <JsonView
                                colored={true}
                                data={JSON.parse(decryptedValue)}
                                shouldExpandNode={(keyPath, data, level) => level < 2}
                            />
                        </CardContent>
                    </Card>
                }
            </DialogContent>
            <div style={{paddingTop: "10px", paddingLeft: "10px", paddingRight: "10px", paddingBottom: "5px"}}>
                <ErrorMessage errorMessage={requestError}/>
                <Progress show={requestInProgress} requestId={requestId}/>
            </div>
            <DialogActions>
                <Button disabled={requestInProgress} variant="contained" onClick={onClose}>Close</Button>
            </DialogActions>
        </Dialog>
    )
}