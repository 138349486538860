import React from "react";
import {useSelector} from "react-redux";
import {useForm} from "react-hook-form";

import {Button, DialogActions, Grid} from "@material-ui/core";

import ExPanel from "./ExPanel";
import {FormInputCtr} from "./FormInputCtr";
import ErrorMessage from "./ErrorMessage";
import Progress from "./Progress";
import JsonView from "./JsonView";

import {
    amountPattern,
    dobPattern,
    phoneNumberPattern,
    ssnPattern
} from "../utils/validators";
import {getFieldList} from "../utils/format";
import useApiRequest from "../hooks/useApiRequest";
import {isDisabled} from "../store/actions/aws";

const formFields = {
    "phoneNumber": {
        label: 'Phone',
        initValue: '',
        type: 'phone',
        rules: {
            pattern: phoneNumberPattern
        },
        order: 1
    },
    "dob": {
        label: 'DOB',
        initValue: '',
        rules: {
            pattern: dobPattern
        },
        order: 2
    },
    "ssn": {
        label: 'SSN',
        initValue: '',
        rules: {
            pattern: ssnPattern
        },
        order: 3
    },
    "amount": {
        label: 'Amount',
        initValue: '',
        rules: {
            pattern: amountPattern
        },
        order: 4
    }
}

const fieldList = getFieldList(formFields)

const requestType = 'concurrent-loans';

export default function ConcurrentLoansPanel({submitRequest}) {
    const concurrentLoansInfo = useSelector(state => state.console.concurrentLoansInfo);
    const formsData = useSelector(state => state.console.formsData[requestType]);
    const [requestId, requestInProgress, requestError, newRequest] = useApiRequest();

    const { handleSubmit, control, resetField } = useForm({
        defaultValues: {
            ...formsData
        }
    });

    const onSubmit = (fields) => {
        submitRequest(requestType, fields, newRequest());
    }

    const handleOnClean = () => {
        fieldList.forEach(name => resetField(name, {defaultValue: formFields[name].initValue}));
    }

    return (
        <ExPanel title="Concurrent Loans" hidden={isDisabled(requestType)}>
            <Grid item xs={12}>
                <div>
                    Possible search parameters combinations:
                    <ul>
                        <li>Phone</li>
                        <li>DOB, SSN</li>
                    </ul>
                </div>
                <form>
                    {fieldList.map((name) => (
                        <FormInputCtr
                            key={name}
                            name={name}
                            formFields={formFields}
                            control={control}
                            disabled={requestInProgress}
                        />
                    ))}
                </form>
                <DialogActions>
                    <Button disabled={requestInProgress} variant="contained" color="primary" onClick={handleOnClean}>Clean</Button>
                    <Button disabled={requestInProgress} variant="contained" color="primary" onClick={handleSubmit(onSubmit)}>Submit</Button>
                </DialogActions>
                <ErrorMessage errorMessage={requestError}/>
                <Progress show={requestInProgress} requestId={requestId}/>
                {concurrentLoansInfo &&
                    <JsonView data={concurrentLoansInfo} shouldExpandNode={(keyPath, data, level) => level < 3}/>
                }
            </Grid>
        </ExPanel>
    )
}
